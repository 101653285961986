import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";

export default class CDKManager {
    public static CurCDKClassifyItemData: CDKClassifyItemData;

    //获取CDK分类
    public static GetCategorylis(success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "cdkey/categorylist",
            {
                token: StorageValue.Token,
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }
                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }
    //添加Cdk分类
    public static AddCategory(Identity: string, Name: String, Status: String, Desc: String, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "cdkey/addcategory",
            {
                token: StorageValue.Token,
                Identity: Identity,
                Name: Name,
                Status: Status,
                Desc: Desc,
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success()
                }
            },
            (err: any) => {
                return;
            })
    }

    //修改CDKey分类
    public static updateCategory(Identity: string, Name: String, Status: String, Desc: String, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "cdkey/updatecategory",
            {
                token: StorageValue.Token,
                Name: Name,
                Identity: Identity,
                Status: Status,
                Desc: Desc
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }

    //删除CDK分类
    public static DelCategory(Identity: string, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "cdkey/delcategory",
            {
                token: StorageValue.Token,
                Identity: Identity
            },
            (msg: any) => {
                let result = msg.result;

                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }

    //获取批量生成CDKey信息
    public static GetCreateinfo(success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "cdkey/getcreateinfo",
            {
                token: StorageValue.Token,
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }

    //获取CDKey信息
    public static getKeylist(Identity: string, Status: string, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "cdkey/keylist",
            {
                Identity: Identity,
                Status: Status,
                token: StorageValue.Token
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }
    //批量生成cDK
    public static CreateKey(Num: string, CategoryId: string, MailId: string, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "cdkey/createkey",
            {
                Num: Num,
                CategoryId: CategoryId,
                MailId: MailId,
                token: StorageValue.Token
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }
                if (success) {
                    success()
                }
            },
            (err: any) => {
                return;
            })
    }
}

export class CDKClassifyItemData {
    constructor(data?: any) {
        if (data == null) {
            return;
        }
        this.identity = data.identity;
        this.name = data.name;
        this.status = data.status;
        this.desc = data.desc;
    }
    public identity: String = ''; // ID
    public name: string = "";  //分类名称
    public status: string = "";  //分类描述
    public desc: string = "";  //使用状态

}