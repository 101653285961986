import React from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Table, message, Button, Layout, Popconfirm, Divider } from 'antd';

import { ColumnProps } from 'antd/lib/table';
import EditorForm from './EditorForm'
import SetPermissionsModal from './SetPermissionsModal';
import AddPermissionsModal from './AddPermissionsModal';
import NetUtil from '../../../Utils/NetUtil';
import TimeUtil from '../../../Utils/TimeUtil';
import StorageValue from '../../../Utils/StorageValue';

const AdminRoleId = 1;

interface IProps {

}

interface IState {
    usersList: Array<any>,
    editorFormVisible: boolean,
    addFormVisible: boolean,
    setFormVisible: boolean,
    editData: { name: string,PP: Array<number>, desc: string }
}

/**角色权限页面
 *Created by liangzai on 19/09/01.
 */
export default class PermissionsView extends React.Component<IProps, IState> {

    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            editorFormVisible: false,
            addFormVisible: false,
            setFormVisible: false,
            usersList: [],
            editData: { name: '', PP: [],  desc: '' }
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/role/all", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.setState({
                    usersList: msg.data
                })
            },
            () => {

            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel()
    }
    //删除账号
    private deleteAccount(name: any) {
        let postData = {
            token: StorageValue.Token,
            role: name
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/role/remove", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.info("删除成功！");
                this.NetRefreshPanel()
            },
            (err: any) => {
                message.error(err);
            })
    }

    private OnEditClick() {

    }

    private closeModal() {
        this.setState({
            editorFormVisible: false,
            addFormVisible: false,
            setFormVisible: false
        });
    }
    //通过RoleId找到需要编辑的数据
    private GetEditData(RoleId: number) {
        return this.state.usersList.find((item) => {
            return item.RoleId == RoleId
        })
    }

    private handleIdit() {
        this.setState({ editorFormVisible: false });
    }

    render() {
        this.columns = [

            // {
            //     align: "center", title: "角色Id", dataIndex: "RoleId",width: 200, key: "RoleId",
            //     sorter: (a: any, b: any) => a.RoleId - b.RoleId,
            //     defaultSortOrder: 'ascend',
            // },
            { align: "center", title: "角色名称", dataIndex: "name", width: 200, key: "name" },
            { align: "center", title: "描述", dataIndex: "desc", width: 300, key: "desc" },
            {
                align: "center", title: "最后修改日期", dataIndex: "lastDate", width: 200, key: "lastDate",
                render: (text: any) => {
                    return (<div>{TimeUtil.TimestampToTime(text)}</div>)
                }
            },
            { align: "center", title: "最后修改用户", dataIndex: "lastAccount", width: 200, key: "lastAccount" },
            {
                align: "center", title: "操作", dataIndex: "opinion", key: "opinion", width: 300, render:
                    (text: any, record: any) => {
                        return (
                            (<div>
                                <a onClick={() => {
                                    this.setState({
                                        editData: record,
                                        setFormVisible: true
                                    })
                                }}>修改</a>
                                <Divider type="vertical" />
                                < Popconfirm
                                    title="确定删除?"
                                    onConfirm={() => this.deleteAccount(record.name)
                                    }>
                                    <a>删除</a>
                                </Popconfirm >
                            </div>)
                        )
                    }
            }
        ]
        return (
            <Layout>
                <Layout.Header style={{ background: '#fff', paddingTop: "20px", paddingLeft: "20px", }}>
                    <Form layout="inline">
                        <Form.Item style={{ float: "right" }}>
                            <Button
                                icon={<PlusOutlined />}
                                type="primary"
                                onClick={() => { this.setState({ addFormVisible: true }) }}>
                                添加角色
                            </Button>
                        </Form.Item>
                    </Form>
                </Layout.Header>
                <div style={{ background: "#F0F2F5", minHeight: 1 }}> </div>
                <Layout.Content style={{ background: '#fff', border: '24px 0', padding: 20, minHeight: 700 }}>
                    <AddPermissionsModal
                        visible={this.state.addFormVisible}
                        onCancel={() => { this.closeModal() }}
                        onSendApplySuccess={() => {
                            this.NetRefreshPanel();
                            this.closeModal();
                        }}
                    />
                    <EditorForm
                        editData={this.state.editData}
                        visible={this.state.editorFormVisible}
                        onCancel={() => { this.closeModal() }}
                        onSendApplySuccess={() => {
                            this.NetRefreshPanel();
                            this.closeModal();
                        }}
                    />
                    <SetPermissionsModal
                        editData={this.state.editData}
                        visible={this.state.setFormVisible}
                        onCancel={() => { this.closeModal() }}
                        onSendApplySuccess={() => {
                            this.NetRefreshPanel();
                            this.closeModal();
                        }}
                    />
                    <Table
                        title={() => {
                            return (<div style={{ textAlign: "center", fontSize: 20 }}>
                                <b>角色列表</b>
                            </div>)
                        }}
                        columns={this.columns}
                        bordered={true}
                        dataSource={this.state.usersList}
                        rowKey={(record: any, index: any) => { return index.toString() }}>
                    </Table>
                </Layout.Content>
            </Layout>
        );
    }
}