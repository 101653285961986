import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Button, DatePicker, Select, Checkbox } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import StorageValue from '../../Utils/StorageValue';
import NetUtil from '../../Utils/NetUtil';
import { Util } from 'bizcharts';
import ExportExcel from '../../Utils/ExcelExport';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    begin: any
    end: any
    channel: string
    split: boolean
    platformId: string

}

/**
 * cpl消耗
 *Created by liangzai on 19/09/12.
 */
export default class CplView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private now = new Date();
    private yesterday = subDays(this.now, 6)
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
            end: TimeUtil.Format(this.now, 'yyyy-MM-dd'),
            channel: '',
            split: false,
            platformId: ""

        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
            channel: this.state.channel,
            split: this.state.split,
            platformId: this.state.platformId
        }
        NetUtil.Post(NetUtil.BaseUrl + "analysis/cpl", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                let data: Array<any> = [];
                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index];

                    data.push(element)
                }

                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.date > b.date ? -1 : 1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }

    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
                end: TimeUtil.Format(this.now, 'yyyy-MM-dd')
            })
            return;
        }
        let begindata = TimeUtil.MomentToTimeString(date[0]);
        let enddata = TimeUtil.MomentToTimeString(date[1]);

        this.setState({
            begin: begindata,
            end: enddata
        })
    }

    //导出excel
    private export() {
        if (this.state.LogsList == null || this.state.LogsList.length < 1) {
            message.error('请先查询数据或导数据为空！')
            return;
        }

        ExportExcel.ExportCsv(this.columns, this.state.LogsList, '充值数据')

    }
    //是否记住密码
    private setIssplit(value: boolean) {
        this.setState({
            split: value
        })
    }

    //设置搜索渠道
    private SetPlatformId(platformId: string) {
        this.setState({
            platformId: platformId
        })
    }

    //设置搜索渠道
    private SetChannel(channel: string) {
        this.setState({
            channel: channel
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "日期", dataIndex: "date", key: "date", width: "120px", fixed: 'left' },
            { align: "center", title: "人数", dataIndex: "num", key: "num" },
            { align: "center", title: "总金额", dataIndex: "amount", key: "amount" },
            //{ align: "center", title: "任务", dataIndex: "task", key: "task" },
            { align: "center", title: "1", dataIndex: "t1", key: "t1" },
            { align: "center", title: "2", dataIndex: "t2", key: "t2" },
            { align: "center", title: "3", dataIndex: "t3", key: "t3" },
            { align: "center", title: "4", dataIndex: "t4", key: "t4" },
            { align: "center", title: "5", dataIndex: "t5", key: "t5" },

            { align: "center", title: "6", dataIndex: "t6", key: "t6" },
            { align: "center", title: "7", dataIndex: "t7", key: "t7" },
            { align: "center", title: "8", dataIndex: "t8", key: "t8" },
            { align: "center", title: "9", dataIndex: "t9", key: "t9" },

            { align: "center", title: "10", dataIndex: "t10", key: "t10" },

            { align: "center", title: "11", dataIndex: "t11", key: "t11" },
            { align: "center", title: "12", dataIndex: "t12", key: "t12" },
            { align: "center", title: "13", dataIndex: "t13", key: "t13" },
            { align: "center", title: "14", dataIndex: "t14", key: "t14" },

            { align: "center", title: "15", dataIndex: "t15", key: "t15" },
            { align: "center", title: "16", dataIndex: "t16", key: "t16" },
            { align: "center", title: "17", dataIndex: "t17", key: "t17" },
            { align: "center", title: "18", dataIndex: "t18", key: "t18" },

            { align: "center", title: "19", dataIndex: "t19", key: "t19" },
            { align: "center", title: "20", dataIndex: "t20", key: "t20" },
            { align: "center", title: "21", dataIndex: "t21", key: "t21" },
            { align: "center", title: "22", dataIndex: "t22", key: "t22" },

            { align: "center", title: "23", dataIndex: "t23", key: "t23" },
            { align: "center", title: "24", dataIndex: "t24", key: "t24" },
            { align: "center", title: "25", dataIndex: "t25", key: "t25" },
            { align: "center", title: "26", dataIndex: "t26", key: "t26" },

            { align: "center", title: "27", dataIndex: "t27", key: "t27" },
            { align: "center", title: "28", dataIndex: "t28", key: "t28" },
            { align: "center", title: "29", dataIndex: "t29", key: "t29" },
            { align: "center", title: "30", dataIndex: "t30", key: "t30" },

            { align: "center", title: "31", dataIndex: "t31", key: "t31" },
            { align: "center", title: "32", dataIndex: "t32", key: "t32" },
            { align: "center", title: "33", dataIndex: "t33", key: "t33" },
            { align: "center", title: "34", dataIndex: "t34", key: "t34" },
            { align: "center", title: "35", dataIndex: "t35", key: "t35" },

            { align: "center", title: "36", dataIndex: "t36", key: "t36" },
            { align: "center", title: "37", dataIndex: "t37", key: "t37" },
            { align: "center", title: "38", dataIndex: "t38", key: "t38" },
            { align: "center", title: "39", dataIndex: "t39", key: "t39" },
            { align: "center", title: "40", dataIndex: "t40", key: "t40" },
            { align: "center", title: "41", dataIndex: "t41", key: "t41" },
            { align: "center", title: "42", dataIndex: "t42", key: "t42" },
            { align: "center", title: "43", dataIndex: "t43", key: "t43" },
            { align: "center", title: "44", dataIndex: "t44", key: "t44" },
            { align: "center", title: "45", dataIndex: "t45", key: "t45" },

        ]
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 6))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='来源' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetPlatformId(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            <Option key={"Android"} value={'Android'}>{"android"}</Option>
                            <Option key={"iOS"} value={'iOS'}>{"ios"}</Option>
                            <Option key={"Web"} value={'Web'}>{"web"}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label='渠道' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={''}
                            onChange={(value: string) => { this.SetChannel(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.NetRefreshPanel() }}
                        >
                            查询
                        </Button>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.export() }}
                        >
                            导出
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Checkbox
                            onChange={(e) => {
                                console.log(e)
                                this.setIssplit(e.target.checked)
                            }}
                            checked={
                                this.state.split
                            }
                        >过滤
                        </Checkbox>
                    </Form.Item>


                </Form>
                <Divider type='horizontal' />

                <Table
                    pagination={false}
                    scroll={{ y: 700 }}

                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}