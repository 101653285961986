import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Button, DatePicker, Select } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import StorageValue from '../../../Utils/StorageValue';
import TimeUtil from '../../../Utils/TimeUtil';
import NetUtil from '../../../Utils/NetUtil';
import AddAactivityView from './AddAactivityView';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    formVisible: boolean
}

/**
 * 活动管理
 *Created by liangzai on 19/09/12.
 */
export default class ActivityManagerView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private now = new Date();
    private yesterday = subDays(this.now, 6)
    constructor(props: IProps) {
        super(props);
        this.state = {
            formVisible: false,
            LogsList: [],
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,

        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/aliRedList", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                let data: Array<any> = [];
                // for (let index = 0; index < msg.data.length; index++) {
                //     const element = msg.data[index].hour;
                //     element.date = msg.data[index].date;
                //     element.total = msg.data[index].total;
                //     data.push(element)
                // }

                // console.log(Util.Sort(msg.data, "dateTime"))
                this.setState({
                    LogsList: (msg.data).sort(
                        (a: any, b: any) => {
                            return a.date > b.date ? 1 : -1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }

    //开始和结束时间
    private OnTimeChange(date: any) {
        // if (date.length < 1) {
        //     this.setState({
        //         begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
        //         end: TimeUtil.Format(this.now, 'yyyy-MM-dd')
        //     })
        //     return;
        // }
        // let begindata = TimeUtil.MomentToTimeString(date[0]);
        // let enddata = TimeUtil.MomentToTimeString(date[1]);

        // this.setState({
        //     begin: begindata,
        //     end: enddata
        // })
    }
    //设置搜索渠道
    private AddClick() {
        this.setState({
            formVisible: true
        })
    }

    private OnBtnUrlClick(status:string,url:string){
        if(status != 'Wait'){
            message.error('gun')
            return
        }
        console.log("aaa")
        const w=window.open('about:blank');
        if(w){
        w.location.href=url
        }
    }
    

    render() {

        this.columns = [
            { align: "center", title: "活动号", dataIndex: "crowd_no", key: "crowd_no", render: (text:string, record) => {
                return <div>{text.substring(0,12)+'...'}</div>
            } },
            { align: "center", title: "商户充值", dataIndex: "pay_url", key: "pay_url",render:
            (text, record) =>
                <div>
                    <a href="javascript:;" onClick={() => this.OnBtnUrlClick(record.status,decodeURIComponent(text))} >去充值</a>
                   
                </div> },
            { align: "center", title: "活动名", dataIndex: "name", key: "name" },
            { align: "center", title: "总金额", dataIndex: "total", key: "total" },
            { align: "center", title: "总福利数", dataIndex: "num", key: "num" },
            { align: "center", title: "已发金额", dataIndex: "use", key: "use" },
            { align: "center", title: "已发数量", dataIndex: "count", key: "count" },

            { align: "center", title: "玩家显示描述", dataIndex: "desc", key: "desc" },
            {
                align: "center", title: "结束日期", dataIndex: "end", key: "dateTime", render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text, 'yyyy-MM-dd')}</div>
                }
            },

            { align: "center", title: "每天限领个数", dataIndex: "day", key: "day" },
            { align: "center", title: "终生限领个数", dataIndex: "left", key: "left" },
            { align: "center", title: "状态", dataIndex: "status", key: "status" },
        ]
        return (
            <div>
                <Form layout='inline'>
                    {/* <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 6))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item> */}

                    {/* <Form.Item label='渠道' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={0}
                            onChange={(value: number) => { this.SetChannel(value) }}
                        >
                            <Option key={"0"} value={0}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item> */}

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.AddClick() }}
                            style={{
                                float: "right"
                            }}>
                        添加
                            
                        </Button>
                    </Form.Item>
                </Form>
                <Divider type='horizontal' />

                <Table
                    pagination={false}
                    scroll={{ y: 700 }}

                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />

                 <AddAactivityView

                    onCancel={() => {

                        this.setState({
                            formVisible: false
                        })
                        
                    }}
                    onOk={() => {
                        this.setState({
                            formVisible: false
                        })
                       
                    }}
                    formVisible={this.state.formVisible}
                   
                />
            </div>
        );
    }
}