
import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Button, Divider, Typography, Descriptions } from 'antd';
import NetUtil from '../../Utils/NetUtil';
import StorageValue from '../../Utils/StorageValue';
import { format } from 'date-fns';
import FormItem from 'antd/lib/form/FormItem';
const { Title } = Typography;


export default class PlayerInfoView extends React.Component {
    constructor() {
        super();
        this.state = {
            playInfo: null,
            userId: null
        }
    }

    onBtnSearchPlayInfoClick() {
        NetUtil.Post(NetUtil.BaseUrl + "api/userinfo",
            {
                token: StorageValue.Token,
                userId: this.state.userId
            },
            (msg) => {
                let result = msg.result
                if (result != 'OK') {
                    message.error(result);
                    return;
                }
                this.setState({
                    playInfo: msg.data,
                })
            },
            (err) => {
                message.error(err);
                return;
            })
    }

    setUserId(value) {
        this.setState({
            userId: value
        })
    }

    render() {
        if (this.state.playInfo == null) {
            this.PlayerInfoView = (
                < div >
                    <Title level={4}>请输入玩家id查询玩家信息</Title>
                </div >
            )
        }
        else {
            this.PlayerInfoView = (
                <div>
                    <Title level={4}>当前玩家信息：</Title>
                    < Descriptions bordered size='small'>
                        <Descriptions.Item label='用户姓名:'>
                            {this.state.playInfo.nickName}
                        </Descriptions.Item>
                        <Descriptions.Item label='注册时间:'>
                            {format(this.state.playInfo.createTime, "YYYY-MM-DD")}
                        </Descriptions.Item>

                        <Descriptions.Item label='最近登录:'>
                            {format(this.state.playInfo.loginTime, "YYYY-MM-DD")}
                        </Descriptions.Item>
                        <Descriptions.Item label='vip等级:'>
                            {this.state.playInfo.vipLevel}
                        </Descriptions.Item>

                        <Descriptions.Item label='平台:'>
                            {this.state.playInfo.platformId}
                        </Descriptions.Item>
                        <Descriptions.Item label='邀请码:'>
                            {this.state.playInfo.inviteId ? this.state.playInfo.inviteId : "无"}
                        </Descriptions.Item>


                        <Descriptions.Item label='手机号:'>
                            {this.state.playInfo.idPhone ? this.state.playInfo.idPhone : "无"}
                        </Descriptions.Item>
                        <Descriptions.Item label='总充值:'>
                            {this.state.playInfo.totalAmount}
                        </Descriptions.Item>

                        <Descriptions.Item label='当前金币:'>
                            {this.state.playInfo.gold}
                        </Descriptions.Item>
                        <Descriptions.Item label='钻石:'>
                            {this.state.playInfo.diamond}
                        </Descriptions.Item>

                        <Descriptions.Item label='魔能:'>
                            {this.state.playInfo.magicka}
                        </Descriptions.Item>
                        <Descriptions.Item label='兑换券:'>
                            {this.state.playInfo.lottery}
                        </Descriptions.Item>

                        <Descriptions.Item label='渠道ID:'>
                            {this.state.playInfo.randGroup}
                        </Descriptions.Item>
                        <Descriptions.Item label='黄金鱼雷:'>
                            {this.state.playInfo.torpedo}
                        </Descriptions.Item>

                        <Descriptions.Item label='金币场输赢:'>
                            {this.state.playInfo.goldWinLose}
                        </Descriptions.Item>
                        <Descriptions.Item label='金币场输出:'>
                            {this.state.playInfo.goldTotalLose}
                        </Descriptions.Item>

                        <Descriptions.Item label='魔能场输赢:'>
                            {this.state.playInfo.magickaWinLose}
                        </Descriptions.Item>
                        <Descriptions.Item label='魔能场输出:'>
                            {this.state.playInfo.magickaTotalLose}
                        </Descriptions.Item>

                        <Descriptions.Item label='BOSS场输赢:'>
                            {this.state.playInfo.bossWinLose}
                        </Descriptions.Item>
                        <Descriptions.Item label='BOSS场输出:'>
                            {this.state.playInfo.bossTotalLose}
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            )
        }

        return (
            <div>
                <Input
                    style={{ width: '55%', marginRight: 20 }}
                    placeholder="请输入玩家ID..."
                    value={this.state.userId}
                    onChange={(e) => this.setUserId(e.target.value)}
                />
                <Button type="primary" onClick={() => { this.onBtnSearchPlayInfoClick() }} >
                    查询
                </Button>
                <Divider type="horizontal " />
                {this.PlayerInfoView}
            </div >
        )
    }
}