import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";
import Util from "../../Utils/Util";

export default class MailManager {

    public static CurSysMailData: SystemMailData;
    public static allSystemMailData: Array<SystemMailData> = new Array<SystemMailData>();
    //添加系统邮件
    public static AddsystemMail(Identity: string, Title: String, Content: String, From: String, Attachment: any, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "mail/addsys",
            {
                token: StorageValue.Token,
                identity: Identity,
                title: Title,
                content: Content,
                from: From,
                attachment: Attachment
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success()
                }
            },
            (err: any) => {
                return;
            })
    }

    //获取系统邮件列表
    public static GetSystemMailList(success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "mail/syslist",
            {
                token: StorageValue.Token,
            },
            (msg: any) => {
                let result = msg.result;

                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                this.allSystemMailData = new Array<SystemMailData>();
                for (let i = 0; i < msg.data.length; i++) {
                    let item = new SystemMailData(msg.data[i])
                    this.allSystemMailData.push(item);
                }

                if (success) {
                    success(Util.Sort(this.allSystemMailData, 'Identity'))
                }
            },
            (err: any) => {
                return;
            })
    }

    //修改系统邮件
    public static updateSysMail(Identity: string, Title: String, Content: String, From: String, Attachment: any, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "mail/updatesys",
            {
                token: StorageValue.Token,
                identity: Identity,
                title: Title,
                content: Content,
                from: From,
                attachment: Attachment
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }

    //删除系统邮件
    public static DeleteSysMailList(Identity: string, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "mail/delsys",
            {
                token: StorageValue.Token,
                identity: Identity
            },
            (msg: any) => {
                let result = msg.result;

                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }

}

export class SystemMailData {
    constructor(data?: any) {
        if (data == null) {
            return;
        }
        this.Identity = data.identity;
        this.Title = data.title;
        this.Content = data.content;
        this.From = data.from;
        this.Attachment = JSON.stringify(data.attachment);
    }
    public Identity: String = ''; // 默认库存
    public Title: string = "";  //邮件标题
    public Content: string = "";  //邮件内容
    public From: string = "";  //发件人
    public Attachment: string = "";  //附件
}