import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Button, DatePicker, Select } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import StorageValue from '../../Utils/StorageValue';
import NetUtil from '../../Utils/NetUtil';
const { Option } = Select;
const { RangePicker } = DatePicker;

interface IProps {
}

interface IState {
    LogsList: Array<any>
    begin: string
    end: string
}

/**
 * 流水统计
 *Created by liangzai on 21/04/02.
 */
export default class WaterView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private now = new Date();
    private yesterday = subDays(this.now, 6)
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
            end: TimeUtil.Format(this.now, 'yyyy-MM-dd'),
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
        }
        NetUtil.Post(NetUtil.BaseUrl + "analysis/water", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                let data: Array<any> = [];
                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index];
                    element.v0pl = element.v0profit.lose
                    element.v0pr = (element.v0profit.rate * 100).toFixed(2) + "%";
                    element.v1pl = element.v1profit.lose
                    element.v1pr = (element.v1profit.rate * 100).toFixed(2) + "%";
                    element.v5pl = element.v5profit.lose
                    element.v5pr = (element.v5profit.rate * 100).toFixed(2) + "%";

                    element.ncv0sr = (element.ncv0stock.rate * 100).toFixed(2) + "%";
                    element.ncv1sr = (element.ncv1stock.rate * 100).toFixed(2) + "%";
                    element.ncv5sr = (element.ncv5stock.rate * 100).toFixed(2) + "%";

                    element.bwv0sr = (element.bwv0stock.rate * 100).toFixed(2) + "%";
                    element.bwv1sr = (element.bwv1stock.rate * 100).toFixed(2) + "%";
                    element.bwv5sr = (element.bwv5stock.rate * 100).toFixed(2) + "%";
                    data.push(element)
                }

                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.date > b.date ? -1 : 1
                        })
                })
            },
            () => {

            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }

    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
                end: TimeUtil.Format(this.now, 'yyyy-MM-dd')
            })
            return;
        }
        let begindata = TimeUtil.MomentToTimeString(date[0]);
        let enddata = TimeUtil.MomentToTimeString(date[1]);

        this.setState({
            begin: begindata,
            end: enddata
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "日期", dataIndex: "date", key: "date", width: "120px", fixed: 'left' },
            { align: "center", title: "零充充值", dataIndex: "v0pl", key: "v0pl" },
            { align: "center", title: "零充充值", dataIndex: "v0pr", key: "v0pr" },
            { align: "center", title: "低v充值", dataIndex: "v1pl", key: "v1pl" },
            { align: "center", title: "低v充值", dataIndex: "v1pr", key: "v1pr" },
            { align: "center", title: "高v充值", dataIndex: "v5pl", key: "v5pl" },
            { align: "center", title: "高v充值", dataIndex: "v5pr", key: "v5pr" },
            { align: "center", title: "农场零充", dataIndex: "ncv0sr", key: "ncv0sr" },
            { align: "center", title: "农场低v", dataIndex: "ncv1sr", key: "ncv1sr" },
            { align: "center", title: "农场高v", dataIndex: "ncv5sr", key: "ncv5sr" },
            { align: "center", title: "保卫零充", dataIndex: "bwv0sr", key: "bwv0sr" },
            { align: "center", title: "保卫低v", dataIndex: "bwv1sr", key: "bwv1sr" },
            { align: "center", title: "保卫高v", dataIndex: "bwv5sr", key: "bwv5sr" },
        ]
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 6))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>
                <Divider />
                <Table
                    pagination={false}
                    scroll={{ y: 700 }}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}