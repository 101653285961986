import { Modal } from 'antd';

const { confirm } = Modal;

export default class CommDialog {
  public static showConfirm(title: string, content: string, cb?: Function) {
    confirm({
      title: title,
      content: content,
      okText: '确定',
      centered: true,
      cancelText: '取消',
      onOk() {
        if (cb) {
          cb()
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  public static Modle(title: string, content: string) {
    Modal.info({
      content: content,
      title: title,
      centered: true,
      // onOk:{console.log("aaa")},
      maskClosable: true,
    })
  }

  public static ModleInfo(title: React.ReactNode, content: React.ReactNode, cb: Function) {
    Modal.info({
      width: "1000px",
      content: content,
      title: title,
      centered: true,
      onOk: () => { cb() },
      // maskClosable: true,
    })
  }


  // public static showDeleteConfirm() {
  //   confirm({
  //     title: 'Are you sure delete this task?',
  //     content: 'Some descriptions',
  //     okText: 'Yes',
  //     okType: 'danger',
  //     cancelText: 'No',
  //     onOk() {
  //       console.log('OK');
  //     },
  //     onCancel() {
  //       console.log('Cancel');
  //     },
  //   });
  // }

  // public static showPropsConfirm() {
  //   confirm({
  //     title: 'Are you sure delete this task?',
  //     content: 'Some descriptions',
  //     okText: 'Yes',
  //     okType: 'danger',
  //     okButtonProps: {
  //       disabled: true,
  //     },
  //     cancelText: 'No',
  //     onOk() {
  //       console.log('OK');
  //     },
  //     onCancel() {
  //       console.log('Cancel');
  //     },
  //   });
  // }
}
