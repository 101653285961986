import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Input, Select, Button, Popconfirm, message, Divider } from 'antd';
import CDKManager from './CDKManager';
import { format } from 'date-fns';
import ExportExcel from '../../Utils/ExcelExport'
import BatchCreateCDkeyView from './BatchCreateCDkeyView';
const { Option } = Select;

export default class CDKeyListView extends React.Component {
    constructor() {
        super();
        this.columns = [
            { align: "center", title: "ID", dataIndex: "identity", key: "identity" },
            { align: "center", title: "CDKey分类ID", dataIndex: "cdKeyCategoryId", key: "cdKeyCategoryId" },
            { align: "center", title: "CDKey", dataIndex: "cdKey", key: "cdKey" },
            {
                align: "center", title: "使用状态", dataIndex: "status", key: "status",
                render: (text, record) => {
                    if (text == 'Y') {
                        return <div>{'使用'}</div>
                    }
                    return <div>{'未使用'}</div>
                }
            },
            {
                align: "center", title: "创建时间", dataIndex: "createdTime", key: "createdTime",
                render: (text, record) => {
                    return <div>{format(text, "YYYY-MM-DD HH:ss")}</div>
                }
            },
            {
                align: "center", title: "使用时间", dataIndex: "useedTime", key: "useedTime",
                render: (text, record) => {
                    if (text == 0) {
                        return <div>{'无'}</div>
                    }
                    return <div>{format((text * 1000), "YYYY-MM-DD HH:ss")}</div>
                }
            },
            { align: "center", title: "使用者ID", dataIndex: "userId", key: "userId" },
            { align: "center", title: "邮件ID", dataIndex: "emailSysTemDataId", key: "emailSysTemDataId" },
        ]


        this.state = {
            Identity: null,
            Status: 'All',
            isHasSelect: false,
            tableData: null,
            cresteFormVisible: false
        }
    }

    componentWillMount() {
        this.NetRefresh()
    }
    //刷新页面
    NetRefresh() {
        CDKManager.getKeylist(this.state.Identity, this.state.Status, (data) => {
            console.log("data" + JSON.stringify(data))
            this.setState({
                tableData: data,
                isHasSelect: true
            })
        },
            () => {

            })
    }


    //Id
    setId(value) {
        this.setState({
            Identity: value
        })
    }
    //状态
    setStatus(value) {
        this.setState({
            Status: value
        })
    }

    //导出excel
    ExportUserInfoToExcel() {
        ExportExcel.exportExcel([{ headers: [{ title: "CDK", dataIndex: "cdKey", key: "cdKey" }], data: this.state.tableData, sheetName: "CDK列表" }], "CDK列表")
        
    }
    //生成cdk
    CreateCdk() {
        this.setState({
            cresteFormVisible: true
        })
    }


    render() {
        const formItemStle = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        }

        return (
            <div>
                <Form layout='inline' onSubmit={() => { this.NetRefresh() }}>
                    <Form.Item label='ID:' {...formItemStle}>
                        <Input
                            placeholder="请输入ID..."
                            value={this.state.Identity}
                            onChange={(e) => this.setId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label='状态' {...formItemStle}>
                        <Select
                            style={{ width: "200px" }}

                            defaultValue='All'
                            onSelect={(key) => { this.setStatus(key) }}
                        >
                            <Option value="All">所有状态</Option>
                            <Option value="Y">启用</Option>
                            <Option value="N">禁用</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            获取信息
                        </Button>


                    </Form.Item>

                    <Button
                        onClick={() => { this.ExportUserInfoToExcel() }}
                        style={{ float: "right" }}
                        type="primary"
                    >
                        导出
                    </Button>

                    <Button

                        onClick={() => { this.CreateCdk() }}
                        style={{ float: "right" }}
                        type="primary"


                    >
                        生成
                    </Button>

                </Form>

                <Divider />
                <Table
                    pagination = {false}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.tableData}
                    rowKey="cdKey">
                </Table>

                {/* 生成cdk*/}
                <BatchCreateCDkeyView
                    onCancel={() => {
                        this.NetRefresh()
                        this.setState({ cresteFormVisible: false })
                    }}
                    onOk={() => {
                        this.NetRefresh()
                        this.setState({ cresteFormVisible: false })
                    }}
                    formVisible={this.state.cresteFormVisible}
                />

            </div>
        )
    }
}