import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Modal, message, Checkbox, Select, Input } from 'antd';
import { isNullOrUndefined } from 'util';
import NetUtil from '../../../Utils/NetUtil';
import { EnumPageOption, EnumPage } from '../../../UserInfo/EnumManager';
import { PagePermissionData } from '../../../UserInfo/PagePermissionData';
import StorageValue from '../../../Utils/StorageValue';
import EnumUtil from '../../../Utils/EnumUtil';
const { Option } = Select;


interface IProps {
    editData: { name: string, PP: Array<any>, desc: string }
    visible: boolean
    onCancel: Function              //取消
    onSendApplySuccess: Function    ////向服务器发送消息成功
}

interface IState {
    name: string,
    pp: Array<any>,    //审批权限
    // RoleName: string,  
    desc: string,
    Data: Array<any>,
}

/**
 * 设置角色权限
 *Created by liangzai on 19/09/02.
 */
export default class SetPermissionsModal extends React.Component<IProps, IState> {
    //旧的roleid
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    constructor(props: IProps) {
        super(props);
        this.state = {
            name: '',
            pp: [],
            desc: '',
            Data: PagePermissionData,
        }
    }

    componentWillReceiveProps(nextProps: any) {
        console.log('>>>>>>>', nextProps.editData)
        let newData: any[] = []
        if (isNullOrUndefined(nextProps.editData.pp)) {
            newData = PagePermissionData
        } else {
            PagePermissionData.forEach((item: any) => {
                let value = nextProps.editData.pp[item.Page.toString()]
                if (value) {
                    newData.push({
                        Page: item.Page,
                        find: (value & (1 << EnumPageOption.find)) != 0,
                        updata: (value & (1 << EnumPageOption.updata)) != 0,
                        // Del: (value & (1 << EnumPageOption.Del)) != 0
                    })
                } else {
                    newData.push(item)
                }
            })
        }

        this.setState({
            name: nextProps.editData.name,
            desc: nextProps.editData.desc,
            Data: newData,
        })
    }

    //审批权限

    //设置校角色权限
    private SetRolePermiss() {
        let pp = Object.create(null);
        this.state.Data.forEach((item: any) => {
            let value = 0
            if (item.find)
                value += 1 << EnumPageOption.find;
            if (item.updata)
                value += 1 << EnumPageOption.updata

            pp[item.Page.toString()] = value
        })
        let postData = {
            token: StorageValue.Token,
            name: this.state.name,
            pp: JSON.parse(JSON.stringify(pp)),
            desc: this.state.desc
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/role/update", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.props.onSendApplySuccess();
                message.info("修改角色成功！");
            },
            (err: any) => {
                message.error(err);
            })
    }
    //查看权限
    private setFind(index: EnumPage, value: boolean) {
        let tempData = this.state.Data;
        tempData.forEach(item => {
            if (item.Page == index) {
                item.find = value;
            }
        })

        this.setState({
            Data: tempData
        })
    }
    //修改权限
    private setUpdata(index: EnumPage, value: boolean) {
        let tempData = this.state.Data;
        tempData.forEach(item => {
            if (item.Page == index) {

                if (item.Page == index) {
                    item.updata = value;
                }
            }
        })

        this.setState({
            Data: tempData
        })
    }

    //角色名称
    private setRole(value: string) {
        this.setState({
            name: value
        })
    }
    //角色描述
    private setDesc(value: string) {
        this.setState({
            desc: value
        })
    }


    render() {
        this.columns = [
            {
                align: "center", title: "页面名称", dataIndex: "Page", key: "Page", width: '400px', render:
                    (text: any, record: any) => {
                        return <div>{EnumUtil.GetPageType(text)}</div>
                    }
            },
            {
                align: "center", title: "查看", dataIndex: "find", key: "find", render:
                    (text: any, record: any, index: number) => {
                        return <Checkbox
                            onChange={(value: any) => { this.setFind(record.Page, value.target.checked) }}
                            checked={text}
                        />
                    }
            },
            {
                align: "center", title: "修改", dataIndex: "updata", key: "updata", render:
                    (text: any, record: any, index: number) => {
                        return <Checkbox
                            onChange={(value: any) => { this.setUpdata(record.Page, value.target.checked) }}
                            checked={text}
                        />
                    }
            },
        ]

        const formItemLayout = {
            labelCol: {
                xs: { span: 10 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            }
        }

        return (
            <Modal
                centered={true}
                visible={this.props.visible}
                title="编辑角色"
                okText="确定"
                cancelText='取消'
                width={"60%"}
                onCancel={() => { this.props.onCancel() }}
                onOk={() => { this.SetRolePermiss() }}
            >

                <Form layout='horizontal'>
                    <Form.Item label="角色名称" {...formItemLayout}>
                        <Input
                            value={this.state.name}
                        // onChange={(e) => { this.setRole(e.target.value) }}
                        />
                    </Form.Item>

                    <Form.Item label="角色描述" {...formItemLayout}>
                        <Input
                            value={this.state.desc}
                            onChange={(e) => { this.setDesc(e.target.value) }}
                        />
                    </Form.Item>
                </Form>
                <Table
                    scroll={{ y: 600 }}
                    pagination={false}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.Data}
                    rowKey={record => record.Page}
                />
            </Modal>
        )
    }
}