import { isNullOrUndefined } from "util";

export default class StorageValue {

    public static _userNmae: string = '';
    public static get userName(): string {
        if (this._userNmae == '')
            this._userNmae = LocalStorage.get('userName')
        return this._userNmae;
    }
    public static set userName(value) {
        this._userNmae = value
        LocalStorage.put('userName', this._userNmae)
    }

    public static _passWord: string = '';
    public static get passWord() {
        if (this._passWord == '')
            this._passWord = LocalStorage.get('passWord')
        return this._passWord;
    }
    public static set passWord(value) {
        this._passWord = value
        LocalStorage.put('passWord', this._passWord)
    }

    public static _isRemberPass: boolean;
    public static get isRemberPass(): boolean {
        if (!this._isRemberPass)
            this._isRemberPass = LocalStorage.get('isRemberPass')
        return this._isRemberPass;
    }

    public static set isRemberPass(value) {
        this._isRemberPass = value
        LocalStorage.put('isRemberPass', this._isRemberPass)
    }


    public static _token: string = '';
    public static get Token(): string {
        if (this._token == '')
            this._token = LocalStorage.get('token')
        return this._token;
    }
    public static set Token(value) {
        this._token = value
        LocalStorage.put('token', this._token)
    }

    public static _groupIds: any;
    public static get GroupIds() {
        if (this._groupIds == null) {
            this._groupIds = LocalStorage.get('groupIds');
        }
        return JSON.parse(this._groupIds);
    }

    //value is string of json 
    public static set GroupIds(value) {
        this._groupIds = value;
        LocalStorage.put('groupIds', this._groupIds);
        console.log(value)
    }

    public static _level: string = '';
    public static get level(): string {
        if (this._level == '')
            this._level = LocalStorage.get('level')
        return this._level;
    }
    public static set level(value) {
        this._level = value
        LocalStorage.put('level', this._level)
    }

    //页面权限
    public static _permission: any
    public static get PagePermission() {
        if (isNullOrUndefined(this._permission)) {
            if (LocalStorage.get('permission') == null) {
                return null;
            }
            this._permission = JSON.parse(LocalStorage.get('permission'));
        }
        return this._permission;
    }
    //value is Array<number>
    public static set PagePermission(value: any) {
        if (value == null || value == undefined) {
            LocalStorage.put('permission', null);
            return;
        }
        this._permission = value;
        LocalStorage.put('permission', JSON.stringify(this._permission));
    }


    //会长id
    public static _channel: Array<string>
    public static get Channel() {
        if (this._channel == null) {
            this._channel = JSON.parse(LocalStorage.get('channel'));
        }
        return this._channel;
    }
    //value is Array<string>
    public static set Channel(value: Array<string>) {
        this._channel = value;
        LocalStorage.put('channel', JSON.stringify(this._channel));
    }
}


class LocalStorage {
    public static put(key: string, data: any) {
        if (window.localStorage == undefined) {
            return;
        }

        try {
            window.localStorage[key] = data;
        }
        catch (err) {
            console.error("LocalStorage put error,key:" + key)
        }
    };

    public static get(key: string) {
        if (window.localStorage == undefined) {
            return undefined;
        }
        try {
            return window.localStorage[key];
        }
        catch (err) {
            console.error("LocalStorage get error,key:" + key)
            return undefined;
        }
    };

    public static remove(key: string) {
        if (window.localStorage == undefined) {
            return;
        }
        try {
            window.localStorage.removeItem(key);
        }
        catch (err) {
            console.error("LocalStorage Remove error,key:" + key)
        }
    };

    public static clear() {
        if (window.localStorage == undefined) {
            return;
        }
        window.localStorage.clear();
    };
}