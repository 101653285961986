import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";

export default class CareQueryManager {

    //查询照顾玩家
    public static GetPlayerData(userId: string, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "api/getpk",
            {
                token: StorageValue.Token,
                userId: userId,
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }
                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            }
        )
    }

    //设置照顾
    public static SetPlayerCare(userId: string,count:number,success: Function, fail: Function) {

        console.log(count + "count")
        NetUtil.Post(NetUtil.BaseUrl + "api/setpk",
            {
                token: StorageValue.Token,
                userId: userId,
                count:count
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }
                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            }
        )
    }
}
