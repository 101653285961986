import MagicRetainedDataManager from "./MagicRetainedDataManager";
import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, message, Button, DatePicker, Alert, Menu, Dropdown } from 'antd';
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import { format, subDays } from 'date-fns';


export default class MagicRetainedView extends React.Component {
    constructor() {
        super();
        this.state = {
            startValue: null,
            endValue: null,
            endOpen: false,
            tableData: null,
            allGroupIdKeys: null,
            allDateKeys: null,
            filteredInfo: null,
            sortedInfo: null,
        }
    }

    OnButtonSerchClick() {
        if (this.state.startValue == null) {
            message.error("请先输入开始日期")
            return
        }

        if (this.state.endValue == null) {
            message.error("请先输入结束日期")
            return
        }

        MagicRetainedDataManager.GetMagicRetentionData(TimeUtil.MomentToTimeString(this.state.startValue), TimeUtil.MomentToTimeString(this.state.endValue),
            () => {
                this.setState({
                    tableData: MagicRetainedDataManager.allRetentionData,
                    allGroupIdKeys: MagicRetainedDataManager.allGroupIdKeys,
                    allDateKeys: MagicRetainedDataManager.allDataKeys
                })
            },
            (err) => {
                message.error(err)
            });
    }

    OnButtonTodayClick() {
        let now = new Date()
        let today = format(subDays(now, 1), "YYYY-MM-DD")
        let yesterday = format(subDays(now, 7), "YYYY-MM-DD")

        this.setState({
            startValue: moment(yesterday),
            endValue: moment(today)
        })

        MagicRetainedDataManager.GetMagicRetentionData(yesterday, today,
            () => {
                this.setState({
                    tableData: MagicRetainedDataManager.allRetentionData,
                    allGroupIdKeys: MagicRetainedDataManager.allGroupIdKeys,
                    allDateKeys: MagicRetainedDataManager.allDataKeys
                })
            },
            (err) => {
                message.error(err)
            });
    }



    disabledStartDate(startValue) {
        if (startValue == null) {
            return false;
        }
        if (startValue.valueOf() >= moment.now()) {
            return true;
        }
        if (this.state.endValue == null) {
            return false;
        }
        return startValue.valueOf() > this.state.endValue.valueOf();
    };

    disabledEndDate(endValue) {
        if (endValue == null) {
            return false;
        }
        if (endValue.valueOf() >= moment.now()) {
            return true;
        }
        if (this.state.startValue == null) {
            return false;
        }
        return endValue.valueOf() <= this.state.startValue.valueOf();
    };

    onChange(field, value) {
        this.setState({
            [field]: value,
        });
    };

    onStartChange(value) {
        this.onChange('startValue', value);
    };

    onEndChange(value) {
        this.onChange('endValue', value);
    };

    handleStartOpenChange(open) {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange(open) {
        this.setState({ endOpen: open });
    };

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    render() {
        // 日期选择框
        let dateSelectView = (
            <Form layout="inline">
                <Form.Item>
                    {"开始日期："}
                    <DatePicker
                        disabledDate={(current) => (this.disabledStartDate(current))}
                        showTime
                        format="YYYY-MM-DD"
                        value={this.state.startValue}
                        placeholder="Start"
                        onChange={(value) => this.onStartChange(value)}
                        onOpenChange={(value) => this.handleStartOpenChange(value)}
                    />
                </Form.Item>
                <Form.Item>
                    {"结束日期："}
                    <DatePicker

                        disabledDate={(current) => this.disabledEndDate(current)}
                        showTime
                        format="YYYY-MM-DD"
                        value={this.state.endValue}
                        placeholder="End"
                        onChange={(value) => this.onEndChange(value)}
                        open={this.state.endOpen}
                        onOpenChange={(value) => this.handleEndOpenChange(value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        onClick={() => { this.OnButtonSerchClick() }} >
                        查询
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        onClick={() => { this.OnButtonTodayClick() }} >
                        默认查询
                    </Button>
                </Form.Item>
            </Form >
        )

        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};


        this.columns = [
            {
                align: "center",
                dataIndex: "date",
                key: "date",
                title: "日期",
                filters: this.state.allDateKeys,
                filteredValue: filteredInfo.date || null,
                onFilter: (value, record) => record.date == value,
                sorter: (a, b) => {
                    if (a.date == b.date) {
                        return 0
                    }
                    if (a.date < b.date) {
                        return 1
                    }
                    return -1
                },
                render: (date) => {
                    if (date) {
                        date = date.replace("2019-", "")
                    }
                    return (
                        <div>{date}</div>
                    )
                },
                sortOrder: sortedInfo.columnKey == 'date' && sortedInfo.order,
                defaultSortOrder: 'descend',
            },
            {
                align: "center",
                title: " 渠道",
                dataIndex: "groupid",
                key: "groupid",
                filters: this.state.allGroupIdKeys,
                filteredValue: filteredInfo.groupid || null,
                onFilter: (value, record) => record.groupid == value,
                sorter: (a, b) => a.groupid - b.groupid,
                sortOrder: sortedInfo.columnKey === 'groupid' && sortedInfo.order,
            },
            {
                align: "center",
                title: "日活",
                dataIndex: "activeUser",
                key: "activeUser",
                sorter: (a, b) => a.activeUser - b.activeUser,
                sortOrder: sortedInfo.columnKey === 'activeUser' && sortedInfo.order,
            },
            {
                align: "center",
                title: "在线",
                dataIndex: "averageOnlineTime",
                key: "averageOnlineTime",
                sorter: (a, b) => a.averageOnlineTime - b.averageOnlineTime,
                sortOrder: sortedInfo.columnKey === 'averageOnlineTime' && sortedInfo.order,
            },
            {
                align: "center",
                title: "新增",
                dataIndex: "todayNewAdd",
                key: "todayNewAdd",
                sorter: (a, b) => a.todayNewAdd - b.todayNewAdd,
                sortOrder: sortedInfo.columnKey === 'todayNewAdd' && sortedInfo.order,
            }
        ]

        //次留数据
        for (let index = 1; index <= 15; index++) {
            let i = index;
            const element = {
                align: "center",
                title: (index).toString() + "留",
                dataIndex: "newAddList",
                key: index.toString(),
                render: (text, record, index) => {
                    let rate = 0;
                    if (record.newAddList && record.newAddList.length > 0) {
                        let todayData = record.newAddList[0]
                        let curData = record.newAddList[i]
                        if (todayData > 0) {
                            rate = curData / todayData * 100
                        }
                    }

                    return (
                        <div>{rate.toFixed(2) + "%"}</div>
                    )
                }
            }
            this.columns.push(element)
        }

        let tableView = (
            <div>
                <Alert
                    message="【魔能留存】查询，请先输入日期！"
                    description="选择您要查询的起始日期，建议不要大于30天！默认查询为7天前至1天前数据"
                />
            </div>)
        if (this.state.tableData != null) {
            tableView = (
                <div>
                    <Table
                        rowKey={record => record.id}
                        size="small"  //default | middle | small
                        pagination={true}
                        columns={this.columns}
                        bordered={true}
                        dataSource={this.state.tableData}
                        onChange={this.handleChange}
                        title={() => {
                            return "*【魔能留存】查询结果："
                        }}
                        footer={() => {
                            return '* product by zhjd '
                        }}
                        scroll={{ x: 1000 }}
                    ></Table>
                </div>
            )
        }

        return (
            <div>
                {dateSelectView}
                <br />
                {tableView}
            </div>
        )
    }
}