import axios from "axios"

export default class NetUtil {
    public static IsTestServer = false;

    public static get BaseUrl() {
        if (this.IsTestServer) {
            return 'http://127.0.0.1:5605/console/'
        }

        // return '/rest/'
        if (window.location.host.indexOf('localhost') >= 0) {
            return 'http://dwyht.xmthl.xyz/console/'
        }
        else {
            return '/console/'
        }
    }

    public static Get(url: string, success: Function, fail: Function) {
        console.log("【send】【get 】\n  >>> url:" + url)
        axios.get(url)
            .then((resp: any) => {
                console.log("【recive】 【get】\n  >>> data:" + JSON.stringify(resp.data));
                if (success) {
                    success(resp.data)
                }
            })
            .catch((error: any) => {
                console.error(error);
                if (fail) {
                    fail(error)
                }
            })
    }

    public static Post(url: string, postInfo: any, success: Function, fail: Function) {
        console.log("【send】【post】\n  >>> url:" + url + "\n  >>> postInfo:" + JSON.stringify(postInfo))
        axios.post(url, postInfo, { timeout: 300000 })
            .then((resp: any) => {
                console.log("【recive】 【post】\n  >>> data:" + JSON.stringify(resp.data))
                if (success) {
                    success(resp.data)
                }
            })
            .catch((error: any) => {
                console.error(error);
                if (fail) {
                    fail(error)
                }
            })
    }
}
// 10010101
// 1+4+16+128  = 149