import React from 'react';
import md5 from "md5"
import { Form } from '@ant-design/compatible';
import { Input, Button, Descriptions, message, Divider } from 'antd';
import StorageValue from '../../../Utils/StorageValue';
import NetUtil from '../../../Utils/NetUtil';
import { Label } from 'bizcharts';


interface IProps {

}

interface IState {
    playerId: string
    deviceId: string
    // playerId: string
    // deviceId: string

    userData: any
}

/**
 * 设备号管理
 *Created by liangzai on 19/12/19.
 */
export default class DeviceManagerView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {

            playerId: '',
            deviceId: '',
            // playerId: '',
            // deviceId: '',
            userData: null
        }
    }
    //设置玩家搜索ID
    private setUserId(value: string) {
        this.setState({
            playerId: value
        })
    }

    //设置玩家昵称
    // private setDeviceId(value: string) {
    //     this.setState({
    //         deviceId: value,
    //     })
    // }

    //设置玩家id
    // private setPwd(value: string) {
    //     this.setState({
    //         playerId: value,
    //     })
    // }

    //设置设备码
    private setDeciveId(value: string) {
        this.setState({
            deviceId: value,
        })
    }


    //网络请求刷新页面
    private GetFindId() {
        let postData = {
            token: StorageValue.Token,
            playerId: this.state.playerId,
            deviceId: this.state.deviceId
        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/findDevice", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.setState({
                    userData: msg.data
                })
            },
            (err: any) => {
                throw (err)
            })
    }


    //修改玩家信息
    private changePwd() {
        if (this.state.playerId == '') {
            message.error('id不能为空')
            return;
        }
        if (this.state.deviceId == '') {
            message.error('设备码不能为空')
            return
        }

        let postData = {
            playerId: this.state.playerId,
            token: StorageValue.Token,
            deviceId: this.state.deviceId

        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/bindDevice", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.info('设备修改成功！');
            },
            (err: any) => {
                throw (err)
            })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                xs: { span: 3 },
                sm: { span: 1 },
            },
            wrapperCol: {
                xs: { span: 20 },
                sm: { span: 8 },
            }
        }


        return (
            <div >

                <Form layout='inline'>
                    <Form.Item label='玩家Id' >
                        <Input
                            placeholder="请输入玩家ID..."
                            value={this.state.playerId}
                            onChange={(e) => this.setUserId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label='设备码'>
                        <Input
                            placeholder="请输入玩家设备码..."
                            value={this.state.deviceId}
                            onChange={(e) => this.setDeciveId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button type="primary" onClick={() => { this.GetFindId() }} >
                            查询
                        </Button>
                    </Form.Item>
                </Form>

                <Divider />

                <Descriptions column={4} size='small'>
                    <Descriptions.Item label='Id'>
                        {/* <Label */}
                        {this.state.userData == null ? '' : this.state.userData.playerId}
                        {/* ></Label> */}
                    </Descriptions.Item>
                    <Descriptions.Item label='设备码'>
                        {/* <Label */}
                        {this.state.userData == null ? '' : this.state.userData.deviceId}
                        {/* ></Label> */}
                    </Descriptions.Item>

                    <Descriptions.Item label='平台'>
                        {/* <Label */}
                        {this.state.userData == null ? '' : this.state.userData.platformId}
                        {/* ></Label> */}
                    </Descriptions.Item>

                    <Descriptions.Item label='渠道'>
                        {/* <Label */}
                        {this.state.userData == null ? '' : this.state.userData.channel}
                        {/* ></Label> */}
                    </Descriptions.Item>
                </Descriptions>

                <Divider />

                <Form layout='horizontal'>
                    <Form.Item label='玩家id' {...formItemLayout}>
                        <Input
                            placeholder="请输入玩家id..."
                            value={this.state.playerId}
                            onChange={(e) => this.setUserId(e.target.value)}
                        />

                    </Form.Item>
                    <Form.Item label='设备码' {...formItemLayout}>
                        <Input
                            placeholder="请确认输入设备码..."
                            value={this.state.deviceId}
                            onChange={(e) => this.setDeciveId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button type="primary" onClick={() => { this.changePwd() }} >
                            绑定
                        </Button>
                    </Form.Item>
                </Form>
            </div >
        )
    }
}