import NetUtil from "../Utils/NetUtil";
import UserInfo from "../UserInfo/UserInfo";
import StorageValue from "../Utils/StorageValue";

export default class LoginHandle {

    public static OnError: Function;
    public static history: any;


    public static LoginStep1() {
        NetUtil.Post(NetUtil.BaseUrl + "setting/user/randsalt",
            {
                account: UserInfo.Account
            },
            (msg: any) => {
                let result = msg.result
                if (result != 'OK') {
                    if (this.OnError != null) {
                        this.OnError(result)
                    }
                    return
                }
                UserInfo.RandSalt = msg.data.randSalt;
                UserInfo.StaticSalt = msg.data.staticSalt;
                this.LoginStep2()
            },
            () => {

            })
    }

    public static LoginStep2() {
        NetUtil.Post(NetUtil.BaseUrl + "setting/user/login",
            {
                account: UserInfo.Account,
                pwd: UserInfo.md5Password,
                googleCode: UserInfo.googleCode
            },
            (msg: any) => {
                let result = msg.result
                if (result != 'OK') {
                    if (this.OnError != null) {
                        this.OnError(result)
                    }
                    return
                }
                StorageValue.Token = msg.data.token;
                StorageValue.PagePermission = msg.data.pp;
                StorageValue.Channel = msg.data.channel;
                console.log(StorageValue.Channel)

                console.log(StorageValue.PagePermission)
                this.history.push('/home/operation');
            },
            () => {

            })
    }
}