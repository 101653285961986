import React from 'react';
import { Route } from 'react-router-dom';
import { view as Loading } from './components/loading';
// import LoadingView from './components/loading/LoadingView'
import Login from './Login/Login'
import Home from './pages/Home/Home.jsx';
export default class App extends React.Component {
  render() {
    return (<>
      <Loading />
      <Route path="/" component={Login} exact />
      <Route path="/login" component={Login} />
      <Route path="/home" component={Home} />
    </>);
  }
}                          