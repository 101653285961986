import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";
import { isToday, format, subDays } from 'date-fns';

export class RechargeData {
    constructor(data: any) {
        this.date = format(data.date, "YYYY-MM-DD");
        this.cpOrderId = data.cpOrderId;
        this.platformId = data.platformId;
        this.userId = data.userId;
        this.rechargeType = data.rechargeType;
        this.rechargeId = data.rechargeId;
        this.amount = data.amount;
    }

    public date: string = "";//日期
    public cpOrderId: string = "";//订单号
    public platformId: string = "";    //平台
    public userId: string = "";         //玩家ID
    public rechargeType: string = "";    //充值类型
    public rechargeId: number = 0;     //商品ID
    public amount: number = 0;     //支付价格
}

export default class RechargeManager {

    private static allRechargeData: Array<RechargeData>
    private static allDateKey: Array<string>   //所有的日期的数组
    private static allUserIdKey: Array<string>

    public static allDataKeys: Array<{ text: string, value: string }>
    public static allUserIdKeys: Array<{ text: string, value: string }>

    public static UserId: null;

    public static GetRechargeData(startTime: string, endTime: string, success: Function, fail: Function) {

        NetUtil.Post(NetUtil.BaseUrl + "api/recharge",
            {
                token: StorageValue.Token,
                beginDate: startTime,
                endDate: endTime
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (msg.data.length <= 0) {
                    if (fail) {
                        fail("暂无用户数据")
                    }
                    return;
                }

                console.log("GetRechargeData msg:" + JSON.stringify(msg))

                this.allDateKey = new Array<string>();
                this.allUserIdKey = new Array<string>();
                this.allRechargeData = new Array<RechargeData>();
                for (let i = 0; i < msg.data.length; i++) {
                    let item = new RechargeData(msg.data[i])
                    this.allRechargeData.push(item);

                    if (this.allDateKey.indexOf(item.date) < 0) {
                        this.allDateKey.push(item.date)
                    }

                    if (this.allUserIdKey.indexOf(item.userId) < 0) {
                        this.allUserIdKey.push(item.userId)
                    }
                }


                this.allDataKeys = new Array<{ text: string, value: string }>()
                this.allUserIdKeys = new Array<{ text: string, value: string }>()
                for (let index = 0; index < this.allDateKey.length; index++) {
                    const element = this.allDateKey[index];
                    this.allDataKeys.push({ text: element, value: element })
                }

                for (let index = 0; index < this.allUserIdKey.length; index++) {
                    const element = this.allUserIdKey[index];
                    this.allUserIdKeys.push({ text: element, value: element })
                }

                if (success) {
                    success(this.allRechargeData)
                }
            },
            (err: any) => {
                return;
            })
    }

    public static get TotalPay() {
        let totalMoney = 0
        for (let index = 0; index < this.allRechargeData.length; index++) {
            const element = this.allRechargeData[index];
            if(element.userId==this.UserId){
                totalMoney+=element.amount;
            }
        }
        return totalMoney
    }
}