import StorageValue from "./StorageValue";
import { isNullOrUndefined } from "util";

var base64js = require('base64-js');

export default class Util {
    //排序
    public static Sort(data: Array<any>, key: string) {
        if(key[0]=='-'){
            key = key.substr(1);
            return data.sort((a: any, b: any) => {
                return a[key] > b[key] ? -1 : 1
            })
        }else{
            return data.sort((a: any, b: any) => {
                return a[key] > b[key] ? 1 : -1
            })
        }
    }

    public static LackPP(pp: number, po: number) {
        if (StorageValue.PagePermission == null) {
            return false;
        }
        let p = StorageValue.PagePermission[pp.toString()]
        if (isNullOrUndefined(p)) {
            return true
        }

        return (p & (1 << po)) == 0
    }

    public static ArrayBufferToBase64(ab:any) {
        return base64js.fromByteArray(new Uint8Array(ab));
    } 
}