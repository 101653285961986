import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";


export default class GroupOnlingDataManager {

    public static allgroupOnlineData: Array<groupOnlineData>;
    public static allGroupIdKey: Array<string>;
    public static allGroupIdKeys: Array<{ text: string, value: string }>
    public static InitOnlineData(success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "api/groupOnline",
            {
                token: StorageValue.Token
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if(fail){
                        fail(result);
                    }
                    return;
                }
                this.allgroupOnlineData = new Array<groupOnlineData>();
                this.allGroupIdKey = new Array<string>();
                for (let i = 0; i < msg.data.length; i++) {
                    let item = new groupOnlineData(msg.data[i])
                    this.allgroupOnlineData.push(item);
                    if (this.allGroupIdKey.indexOf(item.groupid) < 0) {
                        this.allGroupIdKey.push(item.groupid)
                    }
                }
                this.allGroupIdKeys = new Array<{ text: string, value: string }>()
                for (let index = 0; index < this.allGroupIdKey.length; index++) {
                    const element = this.allGroupIdKey[index];
                    this.allGroupIdKeys.push({ text: element, value: element })
                }

                if (success) {
                    success(this.allgroupOnlineData)
                }
            },
            (err: any) => {
                return;
            })
    }
}

export class groupOnlineData {
    constructor(data?: any) {
        if (data == null) {
            return;
        }
        this.groupid = data.groupid;
        this.type = data.type;
        this.onlineNnum = data.num;
    }

    public type:string = ''; // 场景类型
    public groupid: string = "";  //渠道
    public onlineNnum: number = 0;  //在线人数
}