import { EnumPage } from "../UserInfo/EnumManager";
import { ShopItem } from "./TableShopItem";

export default class EnumUtil {
    //页面
    public static GetPageType(value: EnumPage) {
        switch (value) {
            case EnumPage.None:
                return '无 '
            case EnumPage.monitoring:
                return '实时监控'
            case EnumPage.onlineNum:
                return '在线人数'

            case EnumPage.onlinePlayer:
                return '在线玩家'

            case EnumPage.loginNum:
                return '登录人数 '
            case EnumPage.realTimeRecharge:
                return '实时充值 '
            case EnumPage.gameJoin:
                return '游戏参与  '
            case EnumPage.newRegistration:
                return '新增注册 '
            case EnumPage.registrationRetained:
                return '注册留存  '
            case EnumPage.payRetained:
                return '付费留存 '

            case EnumPage.statisticsAnalysis:
                return '统计分析  '
            case EnumPage.playerData:
                return '玩家数据 '
            case EnumPage.rechargeAnalysis:
                return '充值数据'

            case EnumPage.groupCost:
                return '福豆变动'

            case EnumPage.operational:
                return '运营统计'
            case EnumPage.channelInfo:
                return '渠道统计'
            case EnumPage.userltv:
                return '用户价值'
            case EnumPage.cplCost:
                return 'cpl消耗'
            case EnumPage.userTotal:
                return '当前统计'
            case EnumPage.water:
                return '流水统计'

            case EnumPage.gameData:
                return '游戏数据 '
            case EnumPage.logQuery:
                return '日志查询 '
            case EnumPage.loginLog:
                return '登录日志 '
            case EnumPage.offlineLog:
                return '下线日志 '
            case EnumPage.rechargeLog:
                return '充值日志 '
            case EnumPage.propertyStatistics:
                return '财产统计 '
            case EnumPage.propertyLog:
                return '财产日志 '
            case EnumPage.exchangeLog:
                return '兑换日志 '
            case EnumPage.hbLog:
                return '福利日志 '
            case EnumPage.exchangeStock:
                return '兑换库存'


            case EnumPage.bulletBehavior:
                return '子弹行为 '
            case EnumPage.gameManage:
                return '游戏管理 '
            case EnumPage.playerManage:
                return '玩家管理 '
            case EnumPage.pwdManage:
                return '密码管理 '
            case EnumPage.rootManager:
                return '掌控雷电 '
            case EnumPage.deviceManager:
                return '设备码管理'
            case EnumPage.feedBackManager:
                return '客服反馈'
            case EnumPage.activityManager:
                return '活动管理'
            case EnumPage.accountBind:
                return '账号换绑'


            case EnumPage.mailManage:
                return '邮件管理 '
            case EnumPage.systemMail:
                return '系统邮件 '
            case EnumPage.playerMail:
                return '玩家邮件  '
            case EnumPage.exchangeCodeManage:
                return '兑换码管理 '
            case EnumPage.exchangeCodeClassify:
                return '兑换码分类 '
            case EnumPage.exchangeCodeList:
                return '兑换码列表 '

            case EnumPage.configManager:
                return '配置管理   '
            case EnumPage.shopConfig:
                return '商城配置 '
            case EnumPage.gameConfig:
                return '游戏配置 '

            case EnumPage.hotManager:
                return '热更管理 '

            case EnumPage.systemManage:
                return '系统管理 '
            case EnumPage.authorityManage:
                return '权限管理 '
            case EnumPage.userManage:
                return '用户管理 '
            case EnumPage.systemLog:
                return '系统日志 '
            default:
                return '其他'
        }
    }

    public static GetPayNameByPayId(id: number) {
        let shopItem = ShopItem.find(item => item.identity == id)
        if (shopItem) {
            return shopItem.name
        }

        return id;
    }

}