import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Input, message } from 'antd';
import NetUtil from '../../../Utils/NetUtil';
import StorageValue from '../../../Utils/StorageValue';
const { Option } = Select;

interface IProps {
    visible: boolean
    onCancel: Function
    onSendApplySuccess: Function
    editData: { Account: string, Name: string, Department: string, State: number, RoleId: string, Desc: string, },
    roleList: Array<string>
}

interface IState {
    account: string,
    role: string,
    // state: number,
    desc: string
}

/**修改账号信息
 *Created by liangzai on 19/09/02.
 */
export default class AccountInfoModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            account: '',
            role: '',
            desc: ''
        }
    }

    componentWillReceiveProps(nextProps: any) {
        this.setState({
            account: nextProps.editData.account,
            role: nextProps.editData.role,
            desc: nextProps.editData.desc
        })
    }

    //通过RoleId找到需要编辑的数据
    private GetEditData() {
        this.props.roleList.find((item: any) => {
            if (item.RoleId == this.state.role) {
                return item.RoleName;
            }
        })
    }

    // //账号状态
    // private setAccState(value: string) {
    //     let data = parseInt(value)
    //     this.setState({
    //         state: data
    //     })
    // }

    //权限->对应角色ID
    private setRoleId(value: string) {
        console.log(value)
        this.setState({
            role: value
        })
    }
    //修改账号
    private OnBtnEditClick() {
        let postData = {
            token: StorageValue.Token,
            account: this.state.account,
            // State: this.state.state,
            role: this.state.role,
            desc: this.state.desc
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/user/changeinfo", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.info('修改用户信息成功！')
                this.props.onSendApplySuccess();
            },
            (err: any) => {
                throw(err);
            })
    }

    //描述
    private setDesc(value: string) {
        this.setState({
            desc: value
        })
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 10 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            }
        }

        return (
            <Modal
                centered={true}
                visible={this.props.visible}
                title="修改用户信息"
                okText="确定"
                cancelText='取消'
                onCancel={() => { this.props.onCancel() }}
                onOk={() => { this.OnBtnEditClick() }}
            >
                <Form layout='horizontal'>
                    <Form.Item label="用户名" {...formItemLayout}>
                        <Input
                            value={this.state.account}
                        />
                    </Form.Item>

                    {/* <Form.Item label="姓名" {...formItemLayout}>
                        <Input
                            value={this.state.name}
                            onChange={(e:any) => { this.setName(e.target.value) }}
                        />
                    </Form.Item>

                    <Form.Item label="部门" {...formItemLayout}>
                        <Input
                            value={this.state.deaprtment}
                            onChange={(e:any) => { this.setDeaprtment(e.target.value) }}
                        />
                    </Form.Item> */}

                    {/* <Form.Item label="账号状态" {...formItemLayout}>
                        <Select
                            value = {this.state.state.toString()}
                            size={"large"}
                            onSelect={(key: any) => { this.setAccState(key) }}
                        >
                            <Option key="0" value="0">{"无状态"}</Option>
                            <Option key="1" value="1">{"打开"}</Option>
                            <Option key="2" value="2">{"关闭"}</Option>

                        </Select>
                    </Form.Item> */}
                    <Form.Item label="角色权限" {...formItemLayout}>
                        <Select
                            defaultValue={this.state.role}
                            size={"large"}
                            onChange={(value: string) => { this.setRoleId(value) }}
                        >
                            {this.props.roleList.map((item) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label="描述" {...formItemLayout}>
                        <Input
                            value={this.state.desc}
                            onChange={(e: any) => { this.setDesc(e.target.value) }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}