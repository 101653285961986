import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";
import { format } from 'date-fns';

export class SendRecordData {
    constructor(data: any) {
        this.time = format(data.time, "YYYY-MM-DD  hh:mm:ss");
        this.orderId = data.orderId;
        this.send = data.send;
        this.recv = data.recv;
        this.count = data.count;
        this.finish = data.finish;
    }

    public time: string = "";//日期
    public orderId: string = "";//订单号
    public send: string = "";    //赠送者ID
    public recv: string = "";         //接收者ID
    public count: number = 0;     //数量
    public finish: string = "";     //是否完成
}

export default class SendRecordsManager {

    private static allSendrecordsData: Array<SendRecordData>;
    public static allCount: number = 0;
    public static GetSendRecordsData(startTime: string, endTime: string, send: string, recv: string, success: Function, fail: Function) {

        NetUtil.Post(NetUtil.BaseUrl + "api/send",
            {
                token: StorageValue.Token,
                beginDate: startTime,
                endDate: endTime,
                send: send,
                recv: recv
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (msg.data.length <= 0) {
                    if (fail) {
                        fail("暂无数据")
                    }
                    return;
                }
               
                console.log("GetSendRecordsData msg:" + JSON.stringify(msg))
                this.allSendrecordsData = new Array<SendRecordData>();
                for (let i = 0; i < msg.data.length; i++) {
                    let item = new SendRecordData(msg.data[i])
                    this.allSendrecordsData.push(item);
                    this.allCount += item.count;
                }

                if (success) {
                    success(this.allSendrecordsData)
                }
            },
            (err: any) => {
                return;
            })
    }
}