import React from 'react';


export default class LackAuthView extends React.Component {

    render() {
        return (
        <div>
            你没有权限查看此页面
        </div>
        )
    }
}