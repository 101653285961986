import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Divider, Descriptions, message, Select, InputNumber } from 'antd';
import StorageValue from '../../../Utils/StorageValue';
import NetUtil from '../../../Utils/NetUtil';
import TimeUtil from '../../../Utils/TimeUtil';
const { Option } = Select;

interface IProps {

}

interface IState {
    playerId: string
    userData: any,

    oldValue: number,
    changeName: string,
    changeType: string,
    changeValue: number,
    changeWay: string,
    changeReason: string
}

/**
 * 玩家管理
 *Created by liangzai on 19/12/19.
 */
export default class PlayerManageView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            oldValue: 0,
            changeName: '',
            playerId: '',
            userData: null,
            changeType: '',
            changeValue: 0,
            changeWay: 'add',
            changeReason: ''
        }
    }
    //设置玩家搜索ID
    private setUserId(value: string) {
        this.setState({
            playerId: value
        })
    }
    //设置会长id
    private setInviteId(value: string) {
        let data = this.state.userData;
        data.inviteId = value;
        this.setState({
            userData: data,
        })
    }
    //设置玩家真实
    private setRealName(value: string) {
        let data = this.state.userData;
        data.realName = value;
        this.setState({
            userData: data,
        })
    }

    //设置玩家昵称
    private setNickName(value: string) {
        let data = this.state.userData;
        data.nickName = value;
        this.setState({
            userData: data,
        })
    }

    //设置玩家idPhone
    private setIdPhone(value: string) {
        let data = this.state.userData;
        data.idPhone = value;
        this.setState({
            userData: data,
        })
    }

    //设置玩家idCard
    private setIdCard(value: string) {
        let data = this.state.userData;
        data.idCard = value;
        this.setState({
            userData: data,
        })
    }

    //设置是否测试玩家
    private setIsTest(value: boolean) {
        let data = this.state.userData;
        data.test = value;
        this.setState({
            userData: data,
        })
    }

    //将玩家踢下线
    private Kick() {
        let postData = {
            token: StorageValue.Token,
            playerId: this.state.playerId
        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/kick", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.info('操作成功！')
            },
            (err: any) => {
                throw (err)
            })
    }

    //网络请求刷新页面
    private NetRefresh() {
        let postData = {
            token: StorageValue.Token,
            playerId: this.state.playerId
        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/playerinfo", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.setState({
                    userData: msg.data
                })
            },
            (err: any) => {
                throw (err)
            })
    }

    //封禁 /解封
    private baned(type: string, value: string, baned: boolean) {
        let postData = {
            token: StorageValue.Token,
            type: type,
            value: value,
            baned: baned
        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/baned", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.error('封禁成功！');
                this.NetRefresh();
            },
            (err: any) => {
                throw (err)
            })
    }

    //修改玩家信息
    private changeinfo(type: string, value: any) {
        let postData = {
            playerId: this.state.userData.playerId,
            token: StorageValue.Token,
            type: type,
            value: value
        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/changeinfo", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.error('修改玩家信息成功！');
                this.NetRefresh();
            },
            (err: any) => {
                throw (err)
            })
    }

    //修改玩家数值
    private changevalue() {
        if (this.state.changeReason == '') {
            message.error('请输入修改原因！')
            return;
        }
        let add = 0
        if (this.state.changeWay == "dec") {
            add = -(this.state.changeValue);
        }
        else {
            add = this.state.changeValue
        }

        let postData = {
            playerId: this.state.userData.playerId,
            token: StorageValue.Token,
            type: this.state.changeType,
            add: add,
            reason: this.state.changeReason,
        }

        NetUtil.Post(NetUtil.BaseUrl + "manage/changevalue", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.error('修改玩家数值成功！');
                this.NetRefresh();
            },
            (err: any) => {
                throw (err)
            })
    }

    //修改类型
    private ChangeWay(value: any) {
        this.setState({
            changeWay: value
        })
    }

    // //修改类型
    // private ChangeType(value: any) {
    //     this.setState({
    //         changeType: value
    //     })
    // }

    //修改数值
    private setChangeValue(value: number) {
        this.setState({
            changeValue: value
        })
    }
    //修改原因
    private setChangeReason(value: string) {
        this.setState({
            changeReason: value
        })
    }

    render() {
        if (this.state.userData == null) {
            return (
                <div style={{ textAlign: "center" }}>
                    <Form layout='inline'>
                        <Form.Item >
                            <Input
                                placeholder="请输入玩家ID..."
                                value={this.state.playerId}
                                onChange={(e) => this.setUserId(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={() => { this.NetRefresh() }} >
                                查询
                            </Button>
                        </Form.Item>

                    </Form>
                </div>
            )
        }
        return (
            <div>
                <div style={{ textAlign: "center" }}>
                    <Form layout='inline'>
                        <Form.Item >
                            <Input
                                placeholder="请输入玩家ID..."
                                value={this.state.playerId}
                                onChange={(e) => this.setUserId(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" onClick={() => { this.NetRefresh() }} >
                                查询
                            </Button>
                        </Form.Item>

                    </Form>
                </div>

                <Divider />

                {/* 左边 */}
                <div style={{ width: '60%', float: "left" }}>
                    < Descriptions title={'玩家信息'} bordered size='small' column={1}>
                        <Descriptions.Item label='玩家ID:'>
                            <Input
                                value={this.state.userData.playerId}
                                style={{ width: '70%' }}
                            />

                            {/* <Button type="link" style={{ float: 'right' }} onClick={() => { this.NetRefresh() }} >
                                保存
                            </Button> */}

                            <Button type="link" style={{ float: 'right' }}
                                onClick={() => { this.baned('id', this.state.userData.playerId, !this.state.userData.banedId) }}
                            >
                                {this.state.userData.banedId ? '解封' : '封禁'}
                            </Button>

                        </Descriptions.Item>
                        <Descriptions.Item label='玩家昵称:'>
                            <Input
                                onChange={(e: any) => { this.setNickName(e.target.value) }}
                                value={this.state.userData.nickName}
                                style={{ width: '70%' }}
                            />

                            <Button type="link"
                                onClick={() => { this.changeinfo('nickName', this.state.userData.nickName) }} >
                                保存
                            </Button>

                            {/* <Button type="link" style={{ float: 'right' }} onClick={() => { this.NetRefresh() }} >
                                解封
                            </Button> */}
                        </Descriptions.Item>

                        <Descriptions.Item label='真实姓名:'>
                            <Input
                                onChange={(e: any) => { this.setRealName(e.target.value) }}
                                value={this.state.userData.realName}
                                style={{ width: '70%' }}
                            />

                            <Button type="link"
                                onClick={() => { this.changeinfo('realName', this.state.userData.realName) }} >
                                保存
                            </Button>

                            <Button type="link" style={{ float: 'right' }}
                                onClick={() => { this.baned('name', this.state.userData.realName, !this.state.userData.banedName) }} >
                                {this.state.userData.banedName ? '解封' : '封禁'}
                            </Button>
                        </Descriptions.Item>

                        <Descriptions.Item label='绑定手机:'>
                            <Input

                                onChange={(e: any) => { this.setIdPhone(e.target.value) }}
                                value={this.state.userData.idPhone}
                                style={{ width: '70%' }}
                            />

                            <Button type="link"
                                onClick={() => { this.changeinfo('idPhone', this.state.userData.idPhone) }} >
                                保存
                            </Button>

                            <Button type="link" style={{ float: 'right' }}
                                onClick={() => { this.baned('phone', this.state.userData.banedPhone, !this.state.userData.banedPhone) }} >
                                {this.state.userData.banedPhone ? '解封' : '封禁'}
                            </Button>
                        </Descriptions.Item>

                        <Descriptions.Item label='是否测试玩家:'>
                            <Select
                                style={{ width: "200px" }}
                                value={this.state.userData.test}
                                onChange={(value: any) => { this.setIsTest(value) }}
                            >
                                <Option key={'true'} value={'true'}>{"是"}</Option>
                                <Option key={'false'} value={'false'}>{"否"}</Option>

                            </Select>

                            <Button type="link"
                                onClick={() => { this.changeinfo('test', this.state.userData.test == 'true') }} >
                                保存
                            </Button>
                        </Descriptions.Item>


                        <Descriptions.Item label='注册登录:'>
                            {this.state.userData.createTime}
                        </Descriptions.Item>

                        <Descriptions.Item label='VIP:'>
                            {this.state.userData.vipLevel}
                        </Descriptions.Item>
                        <Descriptions.Item label='渠道|系统:'>
                            {/* {TimeUtil.TimestampToTime(this.state.userData.handselTime, "yyyy-MM-dd hh:mm:ss")} */}
                            {this.state.userData.packageId + ' | ' + this.state.userData.platformId}
                        </Descriptions.Item>

                        <Descriptions.Item label='设备信息:'>
                            {/* {TimeUtil.TimestampToTime(this.state.userData.handselTime, "yyyy-MM-dd hh:mm:ss")} */}
                            {this.state.userData.infoStr}
                        </Descriptions.Item>

                        <Descriptions.Item label='命中率:'>
                            {/* {TimeUtil.TimestampToTime(this.state.userData.handselTime, "yyyy-MM-dd hh:mm:ss")} */}
                            {this.state.userData.rate}
                        </Descriptions.Item>


                        <Descriptions.Item label='首充|尾充:'>
                            {this.state.userData.rechargeTime}
                        </Descriptions.Item>

                        <Descriptions.Item label='福豆变动:'>
                            {this.state.userData.clottery}
                        </Descriptions.Item>

                        {/* <Descriptions.Item label='VIP等级:'>
                            {this.state.userData.vipLevel}
                        </Descriptions.Item> */}
                        <Descriptions.Item label='玩家盈利:'>
                            {this.state.userData.playerWin}
                        </Descriptions.Item>
                        <Descriptions.Item label='累计充值:'>
                            {this.state.userData.totalAmount}
                        </Descriptions.Item>
                        <Descriptions.Item label='今日充值:'>
                            {this.state.userData.dailyAmount}
                        </Descriptions.Item>                        
                        <Descriptions.Item label='房间号:'>
                            {this.state.userData.room}
                        </Descriptions.Item>

                        {/* <Descriptions.Item label='今日购买弹头:'>
                            {this.state.userData.dailyBuy}
                        </Descriptions.Item>
                        <Descriptions.Item label='累计回收弹头:'>
                            {this.state.userData.totalSell}
                        </Descriptions.Item> */}

                        {/* <Descriptions.Item label='今日回收弹头:'>
                            {this.state.userData.dailySell}
                        </Descriptions.Item> */}

                        <Descriptions.Item>
                            <Form layout='inline'>
                                <Form.Item >
                                    <Button type="primary" onClick={() => { this.NetRefresh() }} >
                                        一键封禁/解封
                                    </Button>
                                </Form.Item>

                                <Form.Item >
                                    <Button type="primary" onClick={() => { this.NetRefresh() }} >
                                        全部保存
                                    </Button>
                                </Form.Item>
                                <Form.Item >
                                    <Button type="primary" onClick={() => { this.Kick() }} >
                                        踢下线
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Descriptions.Item>

                    </Descriptions>
                </div>

                <Divider type='vertical' />

                {/* 右边 */}
                <div style={{ width: '35%', float: "right" }}>
                    < Descriptions title={'玩家信息'} bordered size='small' column={1}>
                        <Descriptions.Item label='金币:'>
                            <Input
                                value={this.state.userData.gold}
                                style={{ width: '80%' }}
                            />
                            <Button
                                style={{ float: 'right' }}
                                type='link'
                                onClick={() => {
                                    this.setState({
                                        changeName: '金币',
                                        changeType: 'gold',
                                        oldValue: this.state.userData.gold
                                    })
                                }}
                            >
                                修改
                            </Button>
                        </Descriptions.Item>
                        <Descriptions.Item label='砖石:'>
                            <Input
                                value={this.state.userData.diamond}
                                style={{ width: '80%' }}
                            />
                            <Button
                                style={{ float: 'right' }}
                                type='link'
                                onClick={() => {
                                    this.setState({
                                        changeName: '砖石',
                                        changeType: 'diamond',
                                        oldValue: this.state.userData.diamond
                                    })
                                }}
                            >
                                修改
                            </Button>
                        </Descriptions.Item>
                        <Descriptions.Item label='魔能:'>
                            <Input
                                value={this.state.userData.magicka}
                                style={{ width: '80%' }}
                            />
                            <Button
                                style={{ float: 'right' }}
                                type='link'
                                onClick={() => {
                                    this.setState({
                                        changeName: '魔能',
                                        changeType: 'magicka',
                                        oldValue: this.state.userData.magicka
                                    })
                                }}
                            >
                                修改
                            </Button>
                        </Descriptions.Item>
                        <Descriptions.Item label='冰封:'>
                            <Input
                                value={this.state.userData.binfeng}
                                style={{ width: '80%' }}
                            />
                            <Button
                                style={{ float: 'right' }}
                                type='link'
                                onClick={() => {
                                    this.setState({
                                        changeName: '冰封',
                                        changeType: 'binfeng',
                                        oldValue: this.state.userData.binfeng
                                    })
                                }}
                            >
                                修改
                            </Button>
                        </Descriptions.Item>
                        <Descriptions.Item label='号角:'>
                            <Input
                                value={this.state.userData.haojiao}
                                style={{ width: '80%' }}
                            />
                            <Button
                                style={{ float: 'right' }}
                                type='link'
                                onClick={() => {
                                    this.setState({
                                        changeName: '号角',
                                        changeType: 'haojiao',
                                        oldValue: this.state.userData.haojiao
                                    })
                                }}
                            >
                                修改
                            </Button>
                        </Descriptions.Item>
                        <Descriptions.Item label='青铜鱼雷:'>
                            <Input
                                value={this.state.userData.bronzeTorpedo}
                                style={{ width: '80%' }}
                            />
                            <Button
                                style={{ float: 'right' }}
                                type='link'
                                onClick={() => {
                                    this.setState({
                                        changeName: '青铜鱼雷',
                                        changeType: 'bronzeTorpedo',
                                        oldValue: this.state.userData.bronzeTorpedo
                                    })
                                }}
                            >
                                修改
                            </Button>
                        </Descriptions.Item>
                        <Descriptions.Item label='白银鱼雷:'>
                            <Input
                                value={this.state.userData.silverTorpedo}
                                style={{ width: '80%' }}
                            />
                            <Button
                                style={{ float: 'right' }}
                                type='link'
                                onClick={() => {
                                    this.setState({
                                        changeName: '白银鱼雷',
                                        changeType: 'silverTorpedo',
                                        oldValue: this.state.userData.silverTorpedo
                                    })
                                }}
                            >
                                修改
                            </Button>
                        </Descriptions.Item>
                        <Descriptions.Item label='黄金鱼雷:'>
                            <Input
                                value={this.state.userData.goldTorpedo}
                                style={{ width: '80%' }}
                            />
                            <Button
                                style={{ float: 'right' }}
                                type='link'
                                onClick={() => {
                                    this.setState({
                                        changeName: '黄金鱼雷',
                                        changeType: 'goldTorpedo',
                                        oldValue: this.state.userData.goldTorpedo
                                    })
                                }}
                            >
                                修改
                            </Button>
                        </Descriptions.Item>

                        <Descriptions.Item label='人鱼之心:'>
                            <Input
                                value={this.state.userData.yuren}
                                style={{ width: '80%' }}
                            />
                            <Button
                                style={{ float: 'right' }}
                                type='link'
                                onClick={() => {
                                    this.setState({
                                        changeName: '人鱼之心',
                                        changeType: 'yuren',
                                        oldValue: this.state.userData.yuren
                                    })
                                }}
                            >
                                修改
                            </Button>
                        </Descriptions.Item>

                        <Descriptions.Item label='福豆:'>
                            <Input
                                value={this.state.userData.lottery}
                                style={{ width: '80%' }}
                            />
                            <Button

                                style={{ float: 'right' }}
                                type='link'
                                onClick={() => {
                                    this.setState({
                                        changeName: '福豆',
                                        changeType: 'lottery',
                                        oldValue: this.state.userData.lottery
                                    })
                                }}
                            >
                                修改
                            </Button>
                        </Descriptions.Item>
                        
                        <Descriptions.Item label='修改条目:'>
                            <Input
                                value={this.state.changeName}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label='原有数额:'>
                            <Input
                                value={this.state.oldValue}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label='修改方式:'>
                            <Select
                                value={this.state.changeWay}
                                onChange={(value: string) => { this.ChangeWay(value) }}
                            >
                                <Option key={'add'} value={'add'}>{"增加"}</Option>
                                <Option key={'dec'} value={'dec'}>{"减少"}</Option>

                            </Select>
                        </Descriptions.Item>
                        <Descriptions.Item label='修改数额:'>
                            <InputNumber
                                value={this.state.changeValue}
                                onChange={(value: any) => { this.setChangeValue(value) }}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item span={2} label='修改原因:'>
                            <Input
                                value={this.state.changeReason}
                                onChange={(e: any) => { this.setChangeReason(e.target.value) }}
                            />
                        </Descriptions.Item>
                    </Descriptions>

                    <div style={{}}>
                        <Button type="primary" onClick={() => { this.changevalue() }} >
                            确定修改
                        </Button>
                    </div>
                </div>
            </div >
        )
    }
}