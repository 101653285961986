import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, message } from 'antd';
import NetUtil from '../../../Utils/NetUtil';
import StorageValue from '../../../Utils/StorageValue';

interface IProps {
    visible: boolean
    onCancel: Function
    onSendApplySuccess: Function
    ReastEditData: { Account: string, Pwd: string }
}

interface IState {
    Account: string,
    Pwd: string,

}

/**
 *Created by liangzai on 19/09/02.
 */
export default class ResetAccountModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            Account: '',
            Pwd: '',
        }
    }

    componentWillReceiveProps(nextProps: any) {
        this.setState({
            Account: nextProps.ReastEditData.Account,
            Pwd: '',
        })
    }

    //账号
    private setAccount(value: string) {
        this.setState({
            Account: value
        })
    }

    //密码
    private setPwd(value: string) {
        this.setState({
            Pwd: value
        })
    }

    //重置密码
    private OnBtnReastClick() {
        
        let postData = {
            token: StorageValue.Token,
            Account: this.state.Account,
            Pwd: this.state.Pwd,
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/auth/reset", postData,
            (msg: any) => {
                let Result = msg.Result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.info('重置成功！')
                this.props.onSendApplySuccess();
            },
            (err: any) => {
                message.error(err);
            })
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 10 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            }
        }

        return (
            <Modal
                centered={true}
                visible={this.props.visible}
                title="重置密码"
                okText="重置"
                cancelText='取消'
                onCancel={() => { this.props.onCancel() }}
                onOk={() => {
                    this.OnBtnReastClick()
                }}
            >
                <Form layout='horizontal'>
                    <Form.Item label="账号" {...formItemLayout}>
                        <Input
                            disabled = {true}
                            value={this.state.Account}
                        />
                    </Form.Item>

                    <Form.Item label="密码" {...formItemLayout}>
                        <Input
                            value={this.state.Pwd}
                            onChange={(e: any) => { this.setPwd(e.target.value) }}
                        />
                    </Form.Item>

                </Form>
            </Modal>
        )
    }
}