import React from 'react';
import { Route } from 'react-router-dom';
import UserSettingView from '../../components/UserSetting/UserSettingView';
import SliderBarView from '../../components/SliderBar/SliderBarView';
import styles from './Home.module.css';
import GeneralView from '../General/GeneralView';
import AccountManger from '../AccountManger/AccountListView';
import AddAccountView from '../AccountManger/AddAccountView';
import AccountListView from '../AccountManger/AccountListView';
import PlatformInventoryView from '../PlatformInventory/PlatformInventoryView';
import RetentionView from '../Retention/RetentionView';
import MagicRetainedView from '../MagicRetained/MagicRetainedView';
import RechargeView from '../Recharge/RechargeView';
import TorpedoView from '../Torpedo/TorpedoView';
import GroupOnlineDataView from '../OnlineData/GroupOnlineDataView';
import SendRecordsView from '../SendRecord/SendRecordsView';
import CareQueryView from '../CareQuery/CareQueryView';
import PlayerInfoView from '../PlayerInfo/PlayerInfoView';
import ChangePassView from '../AccountManger/ChangePassView';
import LackAuthView from '../LackAuth/LackAuthView';
import SysMailListView from '../MailManager/SystemMail/SysMailListView';
import CostomMailListView from '../MailManager/CostomMail/CostomMailListView';
import ChangeCostomMailData from '../MailManager/CostomMail/ChangeCostomMailData';
import ChangeSysMailData from '../MailManager/SystemMail/ChangeSysMailData';
import CDKeyListView from '../CDKManager/CDKeyListView';
import CDKClassifyListView from '../CDKManager/CDKClassifyListView';
import LogView from '../SystemManage/SystemLog/LogView';
import AccountView from '../SystemManage/AccountManage/AccountView';

import PermissionsView from '../SystemManage/AuthorityManage/PermissionsView';
import RealTimeRechargeView from '../Monitoring/RealTimeRecharge/RealTimeRechargeView';
import PlayerManageView from '../GameManage/PlayerManage/PlayerManageView';
import PwdManagerView from '../GameManage/PwdManager/PwdManagerView';


import NewRegistrationView from '../Monitoring/NewRegistration/NewRegistrationView';
import RegistrationRetained from '../Monitoring/RegistrationRetained/RegistrationRetained';
import PayRetainedView from '../Monitoring/PayRetained/PayRetainedView';
import ShopConfigView from '../ConfigManager/ShopConfig/ShopConfigView';
import ServerConfigView from '../ConfigManager/ServerConfig/ServerConfigView';
import LoginLogView from '../LogManager/LoginLogView';
import loginNumView from '../Monitoring/LoginNum/loginNumView';
import OnlineNumView from '../Monitoring/OnlineNum/OnlineNumView';
import GameJoinView from '../Monitoring/GameJoin/GameJoinView';
import OfflineLogView from '../LogManager/OfflineLogView';
import RechargeLogView from '../LogManager/RechargeLogView';
import BulletBehaviorLogView from '../LogManager/BulletBehaviorLogView';
import PlayerDataView from '../StatisticsAnalysis/PlayerData/PlayerDataView';
import GroupDataView from '../StatisticsAnalysis/GroupData/GroupDataView';
import ItemChangeLogView from '../LogManager/ItemChangeLogView';
import PropetyLogView from '../LogManager/PropetyLogView';
import GroupCostView from '../StatisticsAnalysis/GroupCost/GroupCostView';
import RootManagerView from '../GameManage/RootMnaager/RootManagerView';
import OperationView from '../OperationManager/OperationView';
import WaterView from '../Water/WaterView';
import ExchangeLogView from '../LogManager/ExchangeLogView';
import ExchangeStockView from '../LogManager/ExchangeStockView';
import DeviceManagerView from '../GameManage/DeviceManager/DeviceManagerView';
import FeedBackView from '../GameManage/FeedBackView';
import ChannelInfoView from '../StatisticsAnalysis/ChannelInfoView';
import ItvView from '../StatisticsAnalysis/ItvView';
import ActivityManagerView from '../GameManage/ActivityManager/ActivityManagerView';
import CplView from '../StatisticsAnalysis/CplView';
import HbLogView from '../LogManager/HbLogView';
import HotManagerView from '../GameManage/HotManager/HotManagerView';
import OnlinePlayerViewData from '../Monitoring/LoginNum/OnlinePlayerView';
import UserTotalView from '../StatisticsAnalysis/userTotal';
import AccountBindView from '../GameManage/AccountBindView';



export default class Home extends React.Component {
    constructor(props) {
        super();
        this.state = {
            collapsed: false,
        }
    }

    get sidebarWidth() {
        return this.state.collapsed ? 80 : 256
    }

    get sidebarStyle() {
        return {
            flex: '0 0 ' + this.sidebarWidth + 'px',
            width: this.sidebarWidth + 'px'
        }
    }

    setCollapsed() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    render() {
        return (
            <div className="ant-layout ant-layout-has-sider">
                <div style={this.sidebarStyle} className="ant-layout-sider ant-layout-sider-dark">
                    <SliderBarView collapsed={this.state.collapsed} />
                </div>
                <div className={`${styles['content-wrapper']} ant-layout`} style={{ overflow: "hidden" }}>
                    <div className={`${styles.header} ant-layout-header`}>
                        <UserSettingView collapsed={this.state.collapsed} setCollapsed={() => this.setCollapsed()} />
                    </div>
                    <div className={`${styles.content} ant-layout-content`}>
                        <Route path="/home/addAccount" component={AddAccountView} />
                        <Route path="/home/accountList" component={AccountListView} />
                        <Route path="/home/general" component={GeneralView} />
                        <Route path="/home/accountManager" component={AccountManger} />
                        <Route path="/home/platformInventory" component={PlatformInventoryView} />
                        {/* <Route path="/home/onlineNum" component={OnlinePlayerView} /> */}
                        <Route path="/home/userRetained" component={RetentionView} />
                        <Route path="/home/rechargeRocord" component={RechargeView} />
                        <Route path="/home/magicRetained" component={MagicRetainedView} />
                        <Route path="/home/Torpedo" component={TorpedoView} />
                        <Route path="/home/onlineInfo" component={GroupOnlineDataView} />
                        <Route path="/home/sendRecords" component={SendRecordsView} />
                        <Route path="/home/careQuery" component={CareQueryView} />
                        <Route path="/home/playerInfo" component={PlayerInfoView} />
                        <Route path="/home/setting" component={ChangePassView} />
                        <Route path="/home/noauth" component={LackAuthView} />

                        {/* 邮件管理 */}
                        <Route path="/home/systemMail" component={SysMailListView} />
                        <Route path="/home/playerMail" component={CostomMailListView} />
                        <Route path="/home/changeCustomMailData" component={ChangeCostomMailData} />
                        <Route path="/home/ChangeSysMailData" component={ChangeSysMailData} />

                        <Route path="/home/exchangeCodeClassify" component={CDKClassifyListView} />
                        <Route path="/home/exchangeCodeList" component={CDKeyListView} />
                        <Route path="/home/gameConfig" component={ServerConfigView} />

                        <Route path="/home/hotManager" component={HotManagerView} />

                        <Route path="/home/systemLog" component={LogView} />
                        <Route path="/home/authorityManage" component={PermissionsView} />
                        <Route path="/home/userManage" component={AccountView} />


                        {/* 实时监控 */}
                        <Route path="/home/realTimeRecharge" component={RealTimeRechargeView} />
                        <Route path="/home/newRegistration" component={NewRegistrationView} />
                        <Route path="/home/registrationRetained" component={RegistrationRetained} />
                        <Route path="/home/payRetained" component={PayRetainedView} />
                        <Route path="/home/loginNum" component={loginNumView} />
                        <Route path="/home/onlineNum" component={OnlineNumView} />
                        <Route path="/home/gameJoin" component={GameJoinView} />

                        <Route path="/home/onlinePlayer" component={OnlinePlayerViewData} />


                        {/* 配置管理 */}
                        <Route path="/home/playerManage" component={PlayerManageView} />
                        <Route path="/home/pwdManage" component={PwdManagerView} />
                        <Route path="/home/rootManage" component={RootManagerView} />
                        <Route path="/home/deviceManager" component={DeviceManagerView} />
                        <Route path="/home/feedBackManager" component={FeedBackView} />
                        <Route path="/home/accountBind" component={AccountBindView} />

                        {/* 配置管理 */}
                        <Route path="/home/shopConfig" component={ShopConfigView} />

                        {/* 日志管理 */}
                        <Route path="/home/loginLog" component={LoginLogView} />
                        <Route path="/home/offlineLog" component={OfflineLogView} />
                        <Route path="/home/rechargeLog" component={RechargeLogView} />
                        <Route path="/home/bulletBehavior" component={BulletBehaviorLogView} />
                        <Route path="/home/itemChange" component={ItemChangeLogView} />
                        <Route path="/home/propertyLog" component={PropetyLogView} />
                        <Route path="/home/exchangeLog" component={ExchangeLogView} />
                        <Route path="/home/exchangeStock" component={ExchangeStockView} />
                        <Route path="/home/activityManager" component={ActivityManagerView} />
                        <Route path="/home/hblog" component={HbLogView} />

                        {/* 统计分析 */}
                        <Route path="/home/channelCost" component={GroupCostView} />
                        <Route path="/home/playerData" component={PlayerDataView} />
                        <Route path="/home/groupData" component={GroupDataView} />
                        <Route path="/home/operation" component={OperationView} />
                        <Route path="/home/water" component={WaterView} />
                        <Route path="/home/channelInfo" component={ChannelInfoView} />
                        <Route path="/home/userltv" component={ItvView} />
                        <Route path="/home/cplCost" component={CplView} />
                        <Route path="/home/userTotal" component={UserTotalView} />

                    </div>
                </div>
            </div>
        );
    }
}