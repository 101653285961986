import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Button, DatePicker, Select } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../../Utils/TimeUtil';
import NetUtil from '../../../Utils/NetUtil';
import StorageValue from '../../../Utils/StorageValue';
import Util from '../../../Utils/Util';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    // begin: any
    // end: any
    // channel: string
    // platformId: string

}

/**
 *  在线玩家
 *Created by liangzai on 19/09/12.
 */
export default class OnlinePlayerViewData extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    // private now = new Date();
    // private yesterday = subDays(this.now, 6)
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            // begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
            // end: TimeUtil.Format(this.now, 'yyyy-MM-dd'),
            // channel: '',
            // platformId: ""

        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            // begin: this.state.begin,
            // end: this.state.end,
            // channel: this.state.channel,
            // platformId: this.state.platformId
        }
        NetUtil.Post(NetUtil.BaseUrl + "monitor/onlinePlayer", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                let data: Array<any> = [];
                // for (let index = 0; index < msg.data.length; index++) {
                //     const element = msg.data[index].hour;
                //     element.date = msg.data[index].date;
                //     element.total = msg.data[index].total;
                //     data.push(element)
                // }

                console.log(Util.Sort(msg.data, "date"))
                this.setState({
                    LogsList: (msg.data).sort(
                        (a: any, b: any) => {
                            return a.date > b.date ? -1 : 1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }

    //开始和结束时间
    // private OnTimeChange(date: any) {
    //     if (date.length < 1) {
    //         this.setState({
    //             begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
    //             end: TimeUtil.Format(this.now, 'yyyy-MM-dd')
    //         })
    //         return;
    //     }
    //     let begindata = TimeUtil.MomentToTimeString(date[0]);
    //     let enddata = TimeUtil.MomentToTimeString(date[1]);

    //     this.setState({
    //         begin: begindata,
    //         end: enddata
    //     })
    // }
    //设置搜索渠道
    // private SetChannel(channel: string) {
    //     this.setState({
    //         channel: channel
    //     })
    // }

    //设置搜索渠道
    // private SetPlatformId(platformId: string) {
    //     this.setState({
    //         platformId: platformId
    //     })
    // }


    render() {

        this.columns = [
            { align: "center", title: "玩家ID", dataIndex: "playerId", key: "playerId" },
            { align: "center", title: "系统", dataIndex: "platform", key: "platform" },
            { align: "center", title: "渠道", dataIndex: "packageId", key: "packageId" },
            { align: "center", title: "IP", dataIndex: "ip", key: "ip" },
            { align: "center", title: "地区", dataIndex: "area", key: "area" },
            { align: "center", title: "房间号", dataIndex: "room", key: "room" },
            { align: "center", title: "房间名", dataIndex: "roomName", key: "roomName" },
            { align: "center", title: "所在服", dataIndex: "game", key: "game" },
            { align: "center", title: "游戏版本", dataIndex: "gv", key: "gv" },
            { align: "center", title: "资源版本", dataIndex: "rv", key: "rv" },
            { align: "center", title: "手机信息", dataIndex: "phoneInfo", key: "phoneInfo" },

        ]
        return (
            <div>
                <Form layout='inline'>
                    {/* <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 6))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='来源' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetPlatformId(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            <Option key={"Android"} value={'Android'}>{"android"}</Option>
                            <Option key={"iOS"} value={'iOS'}>{"ios"}</Option>
                            <Option key={"Web"} value={'Web'}>{"web"}</Option>

                        </Select>
                    </Form.Item>

                    <Form.Item label='渠道' >o
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={''}
                            onChange={(value: string) => { this.SetChannel(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item> */}

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.NetRefreshPanel() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>
                <Divider type='horizontal' />

                <Table
                    pagination={false}
                    scroll={{ y: 700 }}

                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}