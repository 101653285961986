import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, message, Button, DatePicker, Alert, Statistic } from 'antd';
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import { isToday, format, subDays } from 'date-fns';
import RechargeManager from './RechargeManager';

export default class RechargeView extends React.Component {

    constructor() {
        super();
        this.state = {
            startValue: null,
            endValue: null,
            endOpen: false,
            tableData: null,
            allUserIdKeys: null,
            allDateKeys: null,
            filteredInfo: null,
            sortedInfo: null,
            isfiltrateUser: false
        }
    }

    OnButtonSerchClick() {
        if (this.state.startValue == null) {
            message.error("请先输入开始日期")
            return
        }

        if (this.state.endValue == null) {
            message.error("请先输入结束日期")
            return
        }

        RechargeManager.GetRechargeData(TimeUtil.MomentToTimeString(this.state.startValue), TimeUtil.MomentToTimeString(this.state.endValue),
            () => {
                this.setState({
                    tableData: RechargeManager.allRechargeData,
                    allUserIdKeys: RechargeManager.allUserIdKeys,
                    allDateKeys: RechargeManager.allDataKeys
                })
            },
            (err) => {
                message.error(err)
            });
    }

    OnButtonTodayClick() {
        let now = new Date()
        let today = format(subDays(now, 1), "YYYY-MM-DD")
        let yesterday = format(subDays(now, 7), "YYYY-MM-DD")

        this.setState({
            startValue: moment(yesterday),
            endValue: moment(today)
        })

        RechargeManager.GetRechargeData(yesterday, today,
            () => {
                this.setState({
                    tableData: RechargeManager.allRechargeData,
                    allUserIdKeys: RechargeManager.allUserIdKeys,
                    allDateKeys: RechargeManager.allDataKeys
                })
            },
            (err) => {
                message.error(err)
            });
    }

    disabledStartDate(startValue) {
        if (startValue == null) {
            return false;
        }
        if (startValue.valueOf() >= moment.now()) {
            return true;
        }
        if (this.state.endValue == null) {
            return false;
        }
        return startValue.valueOf() > this.state.endValue.valueOf();
    };

    disabledEndDate(endValue) {
        if (endValue == null) {
            return false;
        }
        if (endValue.valueOf() >= moment.now()) {
            return true;
        }
        if (this.state.startValue == null) {
            return false;
        }
        return endValue.valueOf() <= this.state.startValue.valueOf();
    };

    onChange(field, value) {
        this.setState({
            [field]: value,
        });
    };

    onStartChange(value) {
        this.onChange('startValue', value);
    };

    onEndChange(value) {
        this.onChange('endValue', value);
    };

    handleStartOpenChange(open) {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange(open) {
        this.setState({ endOpen: open });
    };

    handleChange = (pagination, filters, sorter) => {


        console.log('Various parameters', filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
            isfiltrateUser: false
        });
        if (filters.userId && filters.userId.length > 0) {
            RechargeManager.UserId = filters.userId;
            this.setState({
                isfiltrateUser: true
            })
        }
    };

    render() {
        // 日期选择框
        let dateSelectView = (
            <Form layout="inline">
                <Form.Item>
                    {"开始日期："}
                    <DatePicker
                        disabledDate={(current) => (this.disabledStartDate(current))}
                        showTime
                        format="YYYY-MM-DD"
                        value={this.state.startValue}
                        placeholder="Start"
                        onChange={(value) => this.onStartChange(value)}
                        onOpenChange={(value) => this.handleStartOpenChange(value)}
                    />
                </Form.Item>
                <Form.Item>
                    {"结束日期："}
                    <DatePicker
                        disabledDate={(current) => this.disabledEndDate(current)}
                        showTime
                        format="YYYY-MM-DD"
                        value={this.state.endValue}
                        placeholder="End"
                        onChange={(value) => this.onEndChange(value)}
                        open={this.state.endOpen}
                        onOpenChange={(value) => this.handleEndOpenChange(value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={() => { this.OnButtonSerchClick() }} >
                        查询
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={() => { this.OnButtonTodayClick() }} >
                        默认查询
                    </Button>
                </Form.Item>
            </Form >
        )

        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        this.columns = [
            {
                align: "center",
                dataIndex: "date",
                key: "date",
                title: "时间",
                // filters: this.state.allDateKeys,
                // filteredValue: filteredInfo.date || null,
                // onFilter: (value, record) => record.date == value,
                sorter: (a, b) => {
                    if (a.date == b.date) {
                        return 0
                    }
                    if (a.date < b.date) {
                        return 1
                    }
                    return -1
                },
                sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
                defaultSortOrder: 'descend'
            },
            {
                align: "center",
                title: " 订单号",
                dataIndex: "cpOrderId",
                key: "cpOrderId",
            },
            {
                align: "center",
                title: "平台",
                dataIndex: "platformId",
                key: "platformId",
            },
            {
                align: "center",
                title: "玩家ID",
                dataIndex: "userId",
                key: "userId",
                filters: this.state.allUserIdKeys,
                filteredValue: filteredInfo.userId || null,
                onFilter: (value, record) => record.userId == value,
                filterMultiple: false
            },
            {
                align: "center",
                title: "充值类型",
                dataIndex: "rechargeType",
                key: "rechargeType",
            },
            {
                align: "center",
                title: "商品ID",
                dataIndex: "rechargeId",
                key: "rechargeId",
            },
            {
                align: "center",
                title: "支付价格",
                dataIndex: "amount",
                key: "amount",
                render: num => <Statistic
                    value={num}
                    precision={2}
                    valueStyle={{ color: '#3f8600', fontSize: '16px' }}
                    suffix="元" />,
            }
        ]

        this.tableView = (
            <div>
                <Alert
                    message="【充值记录】查询，请先输入日期!!!"
                    description="选择您要查询的起始和结束日期!"
                />
            </div>
        )

        if (this.state.isfiltrateUser) {
            this.tableView = (
                <div>
                    <Table
                        pagination={true}
                        columns={this.columns}
                        bordered={true}
                        dataSource={this.state.tableData}
                        onChange={this.handleChange}
                        title={() => "累计充值为:" + RechargeManager.TotalPay}
                        footer={() => {
                            return '/* product by zhjd'
                        }}
                        scroll={{ x: 1000 }}
                    ></Table>
                </div>
            )
        }
        else {
            if (this.state.tableData != null) {
                this.tableView = (
                    <div>
                        <Table
                            // size={"small"}
                            pagination={true}
                            columns={this.columns}
                            bordered={true}
                            dataSource={this.state.tableData}
                            onChange={this.handleChange}
                            title={() => {
                                return "查询结果表："
                            }}
                            footer={() => {
                                return '/* product by zhjd'
                            }}
                            scroll={{ x: 1000 }}
                        ></Table>
                    </div>
                )
            }
        }

        return (
            <div>
                {dateSelectView}
                <br />
                {this.tableView}
            </div>
        )
    }
}