import React from 'react';
import { Table, Popconfirm, message } from 'antd';
import AccountManger from './AccountManger';

export default class AccountListView extends React.Component {
    constructor() {
        super();
        this.columns = [
            { align: "center", title: "账号", dataIndex: "account", key: "account" },
            { align: "center", title: "角色", dataIndex: "authLevel", key: "authLevel" },
            { align: "center", title: "渠道", dataIndex: "groupId", key: "groupId" },
            {
                align: "center", title: "操作", dataIndex: "opinion", key: "opinion", render:
                    (text, record) => <Popconfirm title="确定删除?" onConfirm={() => this.deleteAccount(record.account)}>
                        <a href="javascript:;">删除</a>
                    </Popconfirm>,
            }
        ]
        this.state = {
            tableData: null
        }
    }

    componentWillMount() {
        AccountManger.SelectAccount((data) => {
            this.setState({
                tableData: data
            })
        }, () => {

        })
    }

    deleteAccount(key) {
        console.log(key);

        AccountManger.RemoveAccount(key, () => {
            const tableData = [...this.state.tableData];
            this.setState({
                tableData: tableData.filter(item => item.account !== key)
            })
            message.info("删除账号成功");
        }, 
        (err) => {
            message.error(err);
        }) 
    }

    render() {
        return (
            <Table columns={this.columns} bordered={true} dataSource={this.state.tableData}>
            </Table>
        )
    }
}