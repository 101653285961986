import json2csv1 from 'json2csv';
import XLSX from 'xlsx';
// import json2csv from 'json2csv'

/**
 * excel 导出
 */
export default class ExportExcel {
    /**
    * @headers  表头columns
    * @data   数据
    * @sheetName  表格名称
    */
    public static exportExcel(sheets: Array<{ headers: Array<any>, data: Array<any>, sheetName: string }>, fileName = '考勤表') {
        const sheetsArr = sheets.map((sheet: any) => {
            const _headers = sheet.headers
                .map((item: any, i: number) => Object.assign({}, { key: item.key, title: item.title, position: this.getPostition(i) + 1 }))
                .reduce((prev: any, next: any) => Object.assign({}, prev, { [next.position]: { key: next.key, v: next.title } }), {});
            const dataArr = sheet.data
                .map((item: any, i: any) => sheet.headers.map((head: any, j: any) => {
                    let content = ""
                    if (head.render) {
                        content = head.render(item[head.dataIndex], item)

                    } else {
                        content = item[head.dataIndex]
                    }
                    return { content, position: this.getPostition(j) + (i + 2) }
                }
                ))

            // 对刚才的结果进行降维处理（二维数组变成一维数组）
            const _data = dataArr.reduce((prev: any, next: any) => prev.concat(next))
                // 转换成 worksheet 需要的结构
                .reduce((prev: any, next: any) => Object.assign({}, prev, { [next.position]: { v: next.content } }), {});
            // 合并 headers 和 data
            const output = Object.assign({}, _headers, _data);
            // 获取所有单元格的位置
            const outputPos = Object.keys(output);
            // 计算出范围 ,["A1",..., "H2"]
            const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`;
            return Object.assign(
                { sheetName: sheet.sheetName },
                output,
                {
                    '!ref': ref,
                    '!cols': this.getColWidth(sheet.headers, dataArr),
                },
            )
        })
        const sheetNames = sheetsArr.map((sheet: any) => sheet.sheetName)
        const wbSheets = sheetsArr.reduce((prev: any, next: any) =>
            Object.assign({}, prev, { [next.sheetName]: next }), {})
        // 构建 workbook 对象
        const wb = {
            SheetNames: sheetNames,
            Sheets: wbSheets,
        };
        // 导出 Excel
        XLSX.writeFile(wb, fileName + ".csv");
    }
    // 返回 A B C 
    private static getPostition(index: any) {
        let result = String.fromCharCode(65 + index % 26)
        let value = index / 26
        while (value >= 1) {
            result = String.fromCharCode(65 + value % 26 - 1) + result
            value = value / 26
        }
        return result
    }

    private static getColWidth(headers: any, dataArr: any) {

        const allWch = [headers,].concat(dataArr).map(item => item.map((val: any) => {
            let value = val.title || val.content || ""
            let length = 10

            /*先判断是否为null/undefined*/

            if (value) {
                /*再判断是否为中文*/
                if (value.toString().charCodeAt(0) > 255) {
                    length = value.toString().length * 2
                } else {
                    length = value.toString().length
                }
            }
            return {
                'wch': length < 40 ? length : 40
            };
        }))

        /*以第一行为初始值*/
        let colWidth = allWch[0];
        for (let i = 1; i < allWch.length; i++) {
            for (let j = 0; j < allWch[i].length; j++) {
                if (colWidth[j]['wch'] < allWch[i][j]['wch']) {
                    colWidth[j]['wch'] = allWch[i][j]['wch'];
                }
            }
        }
        return colWidth
    }

    public static ExportCsv(headers: Array<any>, data: Array<any>, sheetName: string, fileName = '考勤表') {

        try {
            let columns = headers.filter(t => t.key != null)
            const fields = columns.map(t => t.key)

            const result = json2csv1.parse(data, {
                fields: fields
            });
            // 判断浏览器类型
            if ((navigator.userAgent.indexOf('compatible') > -1 &&
                navigator.userAgent.indexOf('MSIE') > -1) ||
                navigator.userAgent.indexOf('Edge') > -1) {
                //IE10或Edge浏览器
                var BOM = "\uFEFF";
                var csvData = new Blob([BOM + result], { type: "text/csv" });
                navigator.msSaveBlob(csvData, `test.csv`);
            } else {
                //非IE浏览器
                var csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
                //使用a标签的download属性实现下载功能
                var link = document.createElement("a");
                link.href = encodeURI(csvContent);
                link.download = sheetName + `.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            // let columns = headers.filter(t => t.title != null)
            // const fields = columns.map(t => t.title)
            // // const fieldNames = columns.map(t => t.label)

            // var link = document.createElement("a");
            // var result = json2csv1(datas, { fields: fields });
            // var csvContent = "data:text/csv;charset=GBK,\uFEFF" + result;
            // var encodedUri = encodeURI(csvContent);
            // link.setAttribute("href", encodedUri);
            // link.setAttribute("download", "my_data.csv");
            // // var blob = new Blob(["\ufeff" + result], { type: 'text/csv' }); //解决大文件下载失败
            // // link.setAttribute("href", URL.createObjectURL(blob));
            // document.body.appendChild(link); // Required for FF
            // link.click(); // This will download the data file named "my_data.csv".
            // document.body.removeChild(link); // Required for FF
        } catch (err) {
            // Errors are thrown for bad options, or if the data is empty and no fields are provided.
            // Be sure to provide fields if it is possible that your data array will be empty.
            console.error(err);
        }
    }
}