import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, Select } from 'antd';
import NetUtil from '../../../Utils/NetUtil';
import StorageValue from '../../../Utils/StorageValue';
import { platform } from 'os';
const { Option } = Select;

interface IProps {

}

interface IState {
    costType: number,
    shopItem: number,
    shopPrice: number,
    platform: string,
    isOpen: boolean,

    ShopList: Array<any>

}

/**
 * 商城配置
 *Created by liangzai on 19/09/12.
 */
export default class ShopConfigView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    constructor(props: IProps) {
        super(props);
        this.state = {
            costType: 0,
            shopItem: 0,
            shopPrice: 0,
            platform: '',
            isOpen: true,
            ShopList: [],
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            costType: this.state.costType,
            shopItem: this.state.shopItem,
            shopPrice: this.state.shopPrice,
            platform: this.state.platform,
            isOpen: this.state.isOpen,
        }
        NetUtil.Post(NetUtil.BaseUrl + "monitor/recharge", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.setState({
                    ShopList: msg.data
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick(type: number) {
        this.setState({
            // type: type
        })
        this.NetRefreshPanel()
    }

    //设置搜索渠道
    private SetChannel(channel: number) {
        this.setState({
            // channel: channel
        })
    }

    render() {
        this.columns = [
            { align: "center", title: "商品编号", dataIndex: "date", key: "date" },
            { align: "center", title: "标签页", dataIndex: "total", key: "total" },
            { align: "center", title: "显示名称", dataIndex: "h0", key: "h0" },
            { align: "center", title: "赠送标记", dataIndex: "h1", key: "h1" },
            { align: "center", title: "特殊标记", dataIndex: "h2", key: "h2" },
            { align: "center", title: "商品价格", dataIndex: "h3", key: "h3" },
            { align: "center", title: "价格类型", dataIndex: "h4", key: "h4" },
            { align: "center", title: "商品图标", dataIndex: "h5", key: "h5" },
            { align: "center", title: "商品", dataIndex: "h6", key: "h6" },
            { align: "center", title: "商品数量", dataIndex: "h7", key: "h7" },
            { align: "center", title: "赠送数量", dataIndex: "h8", key: "h8" },
            { align: "center", title: "苹果ID", dataIndex: "h9", key: "h9" },
            { align: "center", title: "可见玩家", dataIndex: "h10", key: "h10" },
            { align: "center", title: "针对平台", dataIndex: "h11", key: "h11" },
            { align: "center", title: "开关", dataIndex: "h12", key: "h12" },
            { align: "center", title: "充值方式和通道", dataIndex: "h13", key: "h13" },

        ]
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label='价格类型' >
                        <Select
                            defaultValue={0}
                            onChange={(value: number) => { this.SetChannel(value) }}
                        >
                            <Option key={"0"} value={0}>{"全部"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label='商品' >
                        <Select
                            defaultValue={0}
                            onChange={(value: number) => { this.SetChannel(value) }}
                        >
                            <Option key={"0"} value={0}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label='商品价格' >
                        <Input
                            value={'0'}
                        ></Input>
                    </Form.Item>


                    <Form.Item label='平台' >
                        <Select
                            defaultValue={0}
                            onChange={(value: number) => { this.SetChannel(value) }}
                        >
                            <Option key={"0"} value={0}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label='开关' >
                        <Select
                            defaultValue={0}
                            onChange={(value: number) => { this.SetChannel(value) }}
                        >
                            <Option key={"0"} value={0}>{"开"}</Option>
                            <Option key={"1"} value={0}>{"关"}</Option>

                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick(0) }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>

                <Divider />
                <Table
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.ShopList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}