import { EnumPage } from "./EnumManager";

export let PagePermissionData = [
    { Page: EnumPage.monitoring, find: false, updata: false },
    { Page: EnumPage.onlineNum, find: false, updata: false },
    { Page: EnumPage.loginNum, find: false, updata: false },
    { Page: EnumPage.onlinePlayer, find: false, updata: false },

    { Page: EnumPage.realTimeRecharge, find: false, updata: false },
    { Page: EnumPage.gameJoin, find: false, updata: false },
    { Page: EnumPage.newRegistration, find: false, updata: false },
    { Page: EnumPage.registrationRetained, find: false, updata: false },
    { Page: EnumPage.payRetained, find: false, updata: false },


    { Page: EnumPage.statisticsAnalysis, find: false, updata: false },
    { Page: EnumPage.playerData, find: false, updata: false },
    { Page: EnumPage.rechargeAnalysis, find: false, updata: false },
    { Page: EnumPage.groupCost, find: false, updata: false },
    { Page: EnumPage.operational, find: false, updata: false },
    { Page: EnumPage.channelInfo, find: false, updata: false },
    { Page: EnumPage.userltv, find: false, updata: false },
    { Page: EnumPage.cplCost, find: false, updata: false },
    { Page: EnumPage.userTotal, find: false, updata: false },
    { Page: EnumPage.water, find: false, updata: false },

    { Page: EnumPage.gameData, find: false, updata: false },
    { Page: EnumPage.logQuery, find: false, updata: false },
    { Page: EnumPage.loginLog, find: false, updata: false },
    { Page: EnumPage.offlineLog, find: false, updata: false },
    { Page: EnumPage.rechargeLog, find: false, updata: false },
    { Page: EnumPage.exchangeLog, find: false, updata: false },
    { Page: EnumPage.hbLog, find: false, updata: false },
    { Page: EnumPage.exchangeStock, find: false, updata: false },

    { Page: EnumPage.propertyStatistics, find: false, updata: false },
    { Page: EnumPage.propertyLog, find: false, updata: false },
    { Page: EnumPage.bulletBehavior, find: false, updata: false },
    { Page: EnumPage.gameManage, find: false, updata: false },
    { Page: EnumPage.playerManage, find: false, updata: false },
    { Page: EnumPage.pwdManage, find: false, updata: false },
    { Page: EnumPage.rootManager, find: false, updata: false },
    { Page: EnumPage.deviceManager, find: false, updata: false },
    { Page: EnumPage.feedBackManager, find: false, updata: false },
    { Page: EnumPage.activityManager, find: false, updata: false },
    { Page: EnumPage.accountBind, find: false, updata: false },



    { Page: EnumPage.mailManage, find: false, updata: false },
    { Page: EnumPage.systemMail, find: false, updata: false },
    { Page: EnumPage.playerMail, find: false, updata: false },
    { Page: EnumPage.exchangeCodeManage, find: false, updata: false },
    { Page: EnumPage.exchangeCodeClassify, find: false, updata: false },
    { Page: EnumPage.exchangeCodeList, find: false, updata: false },

    { Page: EnumPage.configManager, find: false, updata: false },
    { Page: EnumPage.shopConfig, find: false, updata: false },
    { Page: EnumPage.gameConfig, find: false, updata: false },
    { Page: EnumPage.hotManager, find: false, updata: false },

    { Page: EnumPage.systemManage, find: false, updata: false },
    { Page: EnumPage.authorityManage, find: false, updata: false },
    { Page: EnumPage.userManage, find: false, updata: false },
    { Page: EnumPage.systemLog, find: false, updata: false },
]