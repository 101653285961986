import React from 'react';
import { Form } from '@ant-design/compatible';
import { Input, Button, Descriptions, message, Divider, Select, InputNumber, DatePicker } from 'antd';
import StorageValue from '../../../Utils/StorageValue';
import NetUtil from '../../../Utils/NetUtil';
import TimeUtil from '../../../Utils/TimeUtil';
const { Option } = Select;

enum Type {
    None = 0,// 无
    Wool = 1,//羊毛
    personal = 2, //后台个人
    wave = 3,   //后台波形
    Grouping = 4,  //分组个人
    GroupWave = 5,  //分组波形
    GroupInventory = 6  //分组库存
}


interface IProps {

}

interface IState {
    // selectType: string,
    userId: string,

    rate: number,
    rateLine:number,
    // rootId: string,
    // type: Type,   //调整类型


    // personData: any
    // groupData: any

    // settingData: {
    //     kill: number,
    //     value: number,
    //     odds: number,
    //     wave: number,
    //     time: number
    // }
    // rootAddData: {
    //     luck: number,
    //     total: number
    // }
}

/**
 * 
 *Created by liangzai on 19/12/19.
 */
export default class RootManagerView extends React.Component<IProps, IState> {
    private now = (new Date()).valueOf();

    constructor(props: IProps) {
        super(props);
        this.state = {
            // type: Type.None,
            userId: '',
            // selectType: "true",
            rate: 0,
            rateLine:0,
            // rootId: '',
            // rootAddData: {
            //     luck: -1,
            //     total: -1
            // },

            // personData: null,  //个人查询数据
            // groupData: null,   //工会查询数据

            // settingData: {
            //     kill: 0,
            //     value: 0,
            //     odds: 0,
            //     wave: 1,
            //     time: this.now,
            // } //个人设置
        }
    }

    //设置玩家搜索ID
    private playerId(value: string) {
        this.setState({
            userId: value
        })
    }
    //设置会长ID
    // private rootId(value: string) {
    //     this.setState({
    //         rootId: value
    //     })
    // }

    // //设置搜索type
    // private setSelectType(value: string) {
    //     this.setState({
    //         selectType: value
    //     })
    // }

    // //调整类型
    // private SetAdjustType(value: Type) {
    //     this.setState({
    //         type: value
    //     })
    // }

    //上分
    private SetRootAdd(value: Type) {
        this.setState({
            rate: value
        })
    }


    //调整杀放
    // private SetAdjustKill(value: number) {
    //     let data = this.state.settingData;
    //     data.kill = value
    //     this.setState({
    //         settingData: data
    //     })
    // }
    // //调整波形
    // private SetAdjustWave(value: number) {
    //     let data = this.state.settingData;
    //     data.wave = value
    //     this.setState({
    //         settingData: data
    //     })
    // }
    //调整控制比
    // private setAdjustOdds(value: number) {
    //     let data = this.state.settingData;
    //     data.odds = value
    //     this.setState({
    //         settingData: data
    //     })
    // }
    // 调整数值
    private setRateValue(value: number) {
        // let data = this.state.rate;
        // data.value = value
        this.setState({
            rate: value
        })
    }
    private setRateLine(value: number) {
        // let data = this.state.rate;
        // data.value = value
        this.setState({
            rateLine: value
        })
    }
    // //调整时间
    // private SetAdjustTime(value: any) {
    //     let data = this.state.settingData;
    //     if (value == null || value == undefined) {
    //         return
    //     }

    //     data.time = TimeUtil.MomentToTimestamp(value)
    //     this.setState({
    //         settingData: data
    //     })
    // }

    //设置
    private SetControl() {
        if (this.state.userId == '') {
            message.error('请输入ID...')
            return;
        }
        let postData = {
            token: StorageValue.Token,
            rate: this.state.rate,		// 类型: 
            rateLine: this.state.rateLine,
            userId: this.state.userId,	// 人个ID/会长ID
        };
        // switch (this.state.type) {
        //     case Type.None:
        //     case Type.Wool:

        //         break;
        //     case Type.personal:
        //     case Type.GroupInventory:
        //     case Type.Grouping:
        //         postData = {
        //             token: StorageValue.Token,
        //             type: this.state.type,		// 类型: 
        //             userId: this.state.userId,	// 人个ID/会长ID
        //             target: this.state.settingData.kill > 0 ? this.state.settingData.value : -this.state.settingData.value,		// 目标值
        //             param: this.state.settingData.odds,		// 参数
        //         }
        //         break;
        //     case Type.wave:
        //     case Type.GroupWave:
        //         postData = {
        //             token: StorageValue.Token,
        //             type: this.state.type,		// 类型: 
        //             userId: this.state.userId,	// 人个ID/会长ID

        //             param: this.state.settingData.wave > 0 ? 1 : -1,		// 参数
        //             time: this.state.settingData.time	// 时间 
        //         }
        //         break;
        //     default:
        //         break;
        // }
        // if (postData == {}) {
        //     return;
        // }

        NetUtil.Post(NetUtil.BaseUrl + "manage/setcontrol", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.info('设置成功！')
            },
            (err: any) => {
                throw (err)
            })
    }

    //网络请求刷新页面
    private GetControl() {
        let postData = {
            token: StorageValue.Token,
            // type: this.state.selectType == 'true' ? true : false,
            userId: this.state.userId
        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/getcontrol", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                this.setState({
                    rate: msg.data.rate,
                    rateLine : msg.data.rateLine
                    // personData: msg.data
                })

            },
            (err: any) => {
                throw (err)
            })
    }

    //上分
    // private AddRoot() {
    //     let postData = {
    //         token: StorageValue.Token,
    //         add: this.state.rate,
    //         userId: this.state.rootId
    //     }
    //     NetUtil.Post(NetUtil.BaseUrl + "manage/addroot", postData,
    //         (msg: any) => {
    //             let Result = msg.result
    //             message.error(msg.data.msg);
    //             if (Result != "OK") {
    //                 return;
    //             }
    //             let data = this.state.rootAddData;
    //             data.luck = msg.data.luck;
    //             data.total = msg.data.total;

    //             this.setState({
    //                 rootAddData: msg.data,
    //             })

    //         },
    //         (err: any) => {
    //             throw (err)
    //         })
    // }


    private GetType(type: Type) {
        switch (type) {
            case Type.None:
                return '无 '
            case Type.Wool:
                return '羊毛'
            case Type.personal:
                return '后台个人'
            case Type.wave:
                return '后台波形 '
            case Type.Grouping:
                return '分组个人 '
            case Type.GroupWave:
                return '分组波形 '
            case Type.GroupInventory:
                return '分组库存 '
            default:
                return '其他'
        }
    }


    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 3 },
                sm: { span: 1 },
            },
            wrapperCol: {
                xs: { span: 20 },
                sm: { span: 8 },
            }
        }

        // let setCon = {}
        // let rootAdd = {};

        // if (this.state.rootAddData.luck < 0 && this.state.rootAddData.total < 0) {
        //     rootAdd = {};
        // }
        // else {
        //     rootAdd = (
        //         < Form layout='inline' >
        //             <Form.Item label='当前上分'>
        //                 <Input
        //                     value={this.state.rootAddData.luck}
        //                 />
        //             </Form.Item>
        //             <Form.Item label='历史上分'>
        //                 <Input
        //                     value={this.state.rootAddData.total}
        //                 />
        //             </Form.Item>
        //         </Form >
        //     )
        // }

        // switch (this.state.type) {
        //     case Type.None:
        //     case Type.Wool:
        //         setCon = (
        //             <Form layout='inline'>
        //                 <Form.Item label='调整类型'>
        //                     <Select
        //                         style={{ width: "200px" }}
        //                         value={this.state.type}
        //                         onChange={(value: Type) => { this.SetAdjustType(value) }}
        //                     >
        //                         <Option key={Type.None} value={Type.None}>{'无'}</Option>
        //                         {/* <Option key={Type.Wool} value={Type.Wool}>{'羊毛'}</Option> */}
        //                         <Option key={Type.personal} value={Type.personal}>{"后台个人"}</Option>
        //                         {/* <Option key={Type.wave} value={Type.wave}>{'后台波形'}</Option> */}
        //                         {/* <Option key={Type.Grouping} value={Type.Grouping}>{"分组个人"}</Option> */}
        //                         {/* <Option key={Type.GroupWave} value={Type.GroupWave}>{' 分组波形'}</Option> */}
        //                         {/* <Option key={Type.GroupInventory} value={Type.GroupInventory}>{" 分组库存"}</Option> */}

        //                     </Select>
        //                 </Form.Item>
        //             </Form>
        //         );
        //         break;
        //     case Type.personal:
        //     case Type.GroupInventory:
        //     case Type.Grouping:
        //         setCon = (
        //             <Form layout='inline'>
        //                 <Form.Item label='调整类型'>
        //                     <Select
        //                         style={{ width: "200px" }}
        //                         value={this.state.type}
        //                         onChange={(value: Type) => { this.SetAdjustType(value) }}
        //                     >
        //                         <Option key={Type.None} value={Type.None}>{'无'}</Option>
        //                         {/* <Option key={Type.Wool} value={Type.Wool}>{'羊毛'}</Option> */}
        //                         <Option key={Type.personal} value={Type.personal}>{"后台个人"}</Option>
        //                         {/* <Option key={Type.wave} value={Type.wave}>{'后台波形'}</Option> */}
        //                         {/* <Option key={Type.Grouping} value={Type.Grouping}>{"分组个人"}</Option> */}
        //                         {/* <Option key={Type.GroupWave} value={Type.GroupWave}>{' 分组波形'}</Option> */}
        //                         {/* <Option key={Type.GroupInventory} value={Type.GroupInventory}>{" 分组库存"}</Option> */}
        //                     </Select>
        //                 </Form.Item>

        //                 {/* 个人库存 */}
        //                 <Form.Item label='杀放'>
        //                     <Select
        //                         style={{ width: "150px" }}
        //                         value={this.state.settingData.kill}
        //                         onChange={(value: number) => { this.SetAdjustKill(value) }}
        //                     >
        //                         <Option key={1} value={1}>{"杀"}</Option>
        //                         <Option key={0} value={0}>{'放'}</Option>
        //                     </Select>

        //                 </Form.Item>

        //                 <Form.Item label='数值'>
        //                     <InputNumber
        //                         value={this.state.settingData.value}
        //                         onChange={(value: any) => { this.setAdjustValue(value) }}
        //                     />

        //                 </Form.Item>

        //                 <Form.Item label='控制比'>
        //                     <InputNumber
        //                         value={this.state.settingData.odds}
        //                         onChange={(value: any) => { this.setAdjustOdds(value) }}
        //                     />
        //                 </Form.Item>

        //                 <Form.Item >
        //                     <Button type="primary" onClick={() => { this.SetControl() }} >
        //                         设置
        //                     </Button>
        //                 </Form.Item>
        //             </Form>
        //         )
        //         break;
        //     case Type.wave:
        //     case Type.GroupWave:
        //         setCon = (
        //             <Form layout='inline'>
        //                 <Form.Item label='调整类型'>
        //                     <Select
        //                         style={{ width: "200px" }}
        //                         value={this.state.type}
        //                         onChange={(value: Type) => { this.SetAdjustType(value) }}
        //                     >
        //                         <Option key={Type.None} value={Type.None}>{'无'}</Option>
        //                         <Option key={Type.Wool} value={Type.Wool}>{'羊毛'}</Option>
        //                         <Option key={Type.personal} value={Type.personal}>{"后台个人"}</Option>
        //                         <Option key={Type.wave} value={Type.wave}>{'后台波形'}</Option>
        //                         <Option key={Type.Grouping} value={Type.Grouping}>{"分组个人"}</Option>
        //                         <Option key={Type.GroupWave} value={Type.GroupWave}>{' 分组波形'}</Option>
        //                         <Option key={Type.GroupInventory} value={Type.GroupInventory}>{" 分组库存"}</Option>
        //                     </Select>
        //                 </Form.Item>

        //                 {/* 波形调整 */}
        //                 <Form.Item label='升降波'>
        //                     <Select
        //                         style={{ width: "150px" }}
        //                         value={this.state.settingData.wave}
        //                         onChange={(value: number) => { this.SetAdjustWave(value) }}
        //                     >
        //                         <Option key={1} value={1}>{"升波"}</Option>
        //                         <Option key={-1} value={-1}>{'降波'}</Option>
        //                     </Select>

        //                 </Form.Item>

        //                 <Form.Item label='时间'>
        //                     <DatePicker
        //                         placeholder="结束时间..."
        //                         showTime={true}
        //                         value={TimeUtil.TimestampToMoment(this.state.settingData.time)}
        //                         onChange={(value: any) => this.SetAdjustTime(value)}
        //                     />
        //                 </Form.Item>

        //                 <Form.Item >
        //                     <Button type="primary" onClick={() => { this.SetControl() }} >
        //                         设置
        //                     </Button>
        //                 </Form.Item>
        //             </Form>
        //         )
        //         break;
        //     default:
        //         break;
        // }

        return (
            <div >
                {/* 玩家查询 */}
                <Form layout='inline'>
                    <Form.Item >
                        <Input
                            placeholder="请输入玩家ID..."
                            value={this.state.userId}
                            onChange={(e) => this.playerId(e.target.value)}
                        />
                    </Form.Item>

                    {/* <Form.Item label='类型'>
                        <Select
                            style={{ width: "200px" }}
                            value={this.state.selectType}
                            onChange={(value: any) => { this.setSelectType(value) }}
                        >
                            <Option key={'true'} value={'true'}>{"个人"}</Option>
                            <Option key={'false'} value={'false'}>{"工会"}</Option>

                        </Select>
                    </Form.Item> */}

                    <Form.Item >
                        <Button type="primary" onClick={() => { this.GetControl() }} >
                            查询
                        </Button>
                    </Form.Item>
                </Form>
                <Divider />

                <Form layout='inline'>
                    <Form.Item label='控制比'>
                        <InputNumber
                            value={this.state.rate}
                            onChange={(value: any) => { this.setRateValue(value) }}
                        />
                    </Form.Item>
                    <Form.Item label='控制数值'>
                        <InputNumber
                            value={this.state.rateLine}
                            onChange={(value: any) => { this.setRateLine(value) }}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button type="primary" onClick={() => { this.SetControl() }} >
                            设置
                        </Button>
                    </Form.Item>

                </Form>

                {/* 玩家信息 */}
                {/* <Descriptions column={3} size='small' bordered={true} title="查询结果">
                    <Descriptions.Item label='类型'>
                        {this.state.personData == null ? '' : this.GetType(this.state.personData.type)}
                    </Descriptions.Item>

                    <Descriptions.Item label='ID'>
                        {this.state.personData == null ? '' : this.state.personData.userId}

                    </Descriptions.Item>
                    <Descriptions.Item label='目标值'>
                        {this.state.personData == null ? '' : this.state.personData.target}

                    </Descriptions.Item>
                    <Descriptions.Item label='参数'>
                        {this.state.personData == null ? '' : this.state.personData.param}

                    </Descriptions.Item>
                    <Descriptions.Item label='时间'>
                        {this.state.personData == null ? '' : TimeUtil.TimestampToTime(this.state.personData.time)}

                    </Descriptions.Item>
                </Descriptions>
                <Divider /> */}
                {/* 调整设置 */}
                {/* {setCon} */}

                <Divider />

                {/* <h3> 会长上分</h3>

                <Form layout='inline'>
                    <Form.Item label='会长ID' >
                        <Input
                            placeholder="请输入会长ID..."
                            value={this.state.rootId}
                            onChange={(e) => this.rootId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label='分数'>
                        <InputNumber
                            value={this.state.add}

                            onChange={(value: any) => { this.SetRootAdd(value) }}
                        >
                        </InputNumber>
                    </Form.Item>

                    <Form.Item >
                        <Button type="primary" onClick={() => { this.AddRoot() }} >
                            上分
                         </Button>
                    </Form.Item>

                </Form>
                <Divider />
                < Form layout='inline' >
                    <Form.Item label='当前上分'>
                        <Input
                            value={this.state.rootAddData.luck}
                        />
                    </Form.Item>
                    <Form.Item label='历史上分'>
                        <Input
                            value={this.state.rootAddData.total}
                        />
                    </Form.Item>
                </Form > */}
            </div >
        )
    }
}