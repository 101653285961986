import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";
import { array, string } from "prop-types";
import { totalmem } from "os";


export default class PlatformInventoryManager {

    public static allInventoryData: Array<PlatformInventoryData> = new Array<PlatformInventoryData>();
    public static curInventoryData: Array<PlatformInventoryData> = new Array<PlatformInventoryData>()
    public static allGroupIdKey: Array<string>;
    public static allTypeKey: Array<string>;

    public static allTypeKeys: Array<{ text: string, value: string }>
    public static allGroupIdKeys: Array<{ text: string, value: string }>

    public static groupid: any = null;
    public static type: any = null;

    private static GetTotal(): PlatformInventoryData {
        let target = new PlatformInventoryData();
        target.groupid = "统计" //统计
        target.type = '所有'  //this.type


        console.log('groupid>>>>>>' + this.groupid + "type>>>>>>" + this.type);

        if (this.type != null && this.groupid != null) {
            target.type = this.type
            for (let index = 0; index < this.allInventoryData.length; index++) {
                const element = this.allInventoryData[index];
                if (element.type == this.type && this.groupid.indexOf(element.groupid) >= 0) {
                    target.income += element.income;
                    target.outlay += element.outlay;
                    target.profit += element.profit;
                    target.stock += element.stock;
                    target.defaultStock += element.defaultStock;
                    target.curInventory += element.curInventory;
                    target.winlose += element.winlose;
                    target.drop += element.drop;
                }
            }
        }
        if (this.type == null && this.groupid != null) {
            target.type = "所有"
            for (let index = 0; index < this.allInventoryData.length; index++) {
                const element = this.allInventoryData[index];
                if (this.groupid.indexOf(element.groupid) >= 0) {
                    target.income += element.income;
                    target.outlay += element.outlay;
                    target.profit += element.profit;
                    target.stock += element.stock;
                    target.defaultStock += element.defaultStock;
                    target.curInventory += element.curInventory;
                    target.winlose += element.winlose;
                    target.drop += element.drop;
                }
            }
        }
        if (this.type != null && this.groupid == null) {
            target.type = this.type
            for (let index = 0; index < this.allInventoryData.length; index++) {
                const element = this.allInventoryData[index];
                if (element.type == this.type) {

                    console.log("aaaa>>>>>>>>>")

                    target.income += element.income;
                    target.outlay += element.outlay;
                    target.profit += element.profit;
                    target.stock += element.stock;
                    target.defaultStock += element.defaultStock;
                    target.curInventory += element.curInventory;
                    target.winlose += element.winlose;
                    target.drop += element.drop;
                }
            }
        }
        if (this.type == null && this.groupid == null) {
            for (let index = 0; index < this.allInventoryData.length; index++) {
                const element = this.allInventoryData[index];
                target.income += element.income;
                target.outlay += element.outlay;
                target.profit += element.profit;
                target.stock += element.stock;
                target.defaultStock += element.defaultStock;
                target.curInventory += element.curInventory;
                target.winlose += element.winlose;
                target.drop += element.drop;
            }

            target.stock = parseInt((target.stock).toFixed(2))
        }

        return target
    }



    public static InitInventoryData(success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "api/stock",
            {
                token: StorageValue.Token
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result);
                    }
                    return;
                }

                this.allTypeKey = new Array<string>();
                this.allGroupIdKey = new Array<string>();

                this.allInventoryData = new Array<PlatformInventoryData>();

                for (let i = 0; i < msg.data.length; i++) {

                    let item = new PlatformInventoryData(msg.data[i])

                    this.allInventoryData.push(item);

                    if (this.allTypeKey.indexOf(item.type) < 0) {
                        this.allTypeKey.push(item.type)
                    }

                    if (this.allGroupIdKey.indexOf(item.groupid) < 0) {
                        this.allGroupIdKey.push(item.groupid)
                    }
                }

                this.allGroupIdKeys = new Array<{ text: string, value: string }>()
                this.allTypeKeys = new Array<{ text: string, value: string }>()
                for (let index = 0; index < this.allGroupIdKey.length; index++) {
                    const element = this.allGroupIdKey[index];
                    this.allGroupIdKeys.push({ text: element, value: element })
                }

                for (let index = 0; index < this.allTypeKey.length; index++) {
                    const element = this.allTypeKey[index];
                    this.allTypeKeys.push({ text: element.toString(), value: element.toString() })
                }

                let total = this.GetTotal();
                console.log(total + "total");
                // this.soreData();
                // totol.stock = parseFloat((totol.stock/msg.data.length).toFixed(2));
                this.allInventoryData.unshift(total);
                if (success) {
                    success(this.allInventoryData)
                }
            },
            (err: any) => {
                return;
            })
    }

}

export class PlatformInventoryData {
    constructor(data?: any) {
        if (data == null) {
            return;
        }
        this.groupid = data.groupid;
        this.type = data.type;
        this.defaultStock = Math.floor(parseInt(data.defaultStock) / 10000);
        this.curInventory = Math.floor(parseInt(data.stock) / 10000);
        this.stock = parseFloat((this.curInventory / this.defaultStock).toFixed(2));
        this.profit = Math.floor(parseInt(data.profit) / 10000);
        this.income = Math.floor(parseInt(data.income) / 10000);
        this.outlay = Math.floor(parseInt(data.outlay) / 10000);
        this.drop = parseInt(data.drop);
        this.winlose = this.income - this.outlay - this.profit;
    }

    public defaultStock: number = 0; // 默认库存
    public groupid: string = "";  //渠道
    public type: string = "";  //场次
    public curInventory: number = 0;  //当前库存
    public stock: number = 0; //库存比
    public profit: number = 0;  //统抽水
    public winlose: number = 0;  //系统输赢
    public income: number = 0; //系统收入
    public outlay: number = 0;  //系统支出
    public drop: number = 0;  //掉落
}