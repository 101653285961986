import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";

export class RetentionData {
    constructor(data?: any) {
        if (data == null) {
            return
        }
        this.date = data.date;
        this.groupid = data.groupid;
        this.profit = data.profit;
        this.activeUser = data.activeUser;
        this.averageOnlineTime = data.averageOnlineTime;
        this.magicka = data.magicka;
        this.newPlayerList = new Array<number>()
        for (let index = 0; index < data.r.length; index++) {
            const element = data.r[index];
            this.newPlayerList.push(element)
        }
    }

    public date: string = "";//日期
    public groupid: number = 0;//渠道ID
    public profit: number = 0;          //利润
    public activeUser: number = 0;        //日活
    public averageOnlineTime: number = 0;         //平均在线(秒)
    public magicka: number = 0;     // 魔能
    public newPlayerList: Array<number> = new Array<number>();

    public get rate(): number {
        if (this.newAdd > 0) {
            return (this.magicka / this.newAdd) * 100
        }
        return 0
    }

    private _newAdd: number = 0
    public get newAdd(): number {
        if (this.date != "统计") {
            if (this.newPlayerList.length > 0 && this.newPlayerList[0] > 0) {
                return this.newPlayerList[0]
            }
            return 0;
        }
        return this._newAdd
    }

    public set newAdd(value) {
        if (this.date != "统计") {
            return
        }
        this._newAdd = value;
    }
}





export default class RetentionDataManager {

    private static allRetentionData: Array<RetentionData>
    private static allDateKey: Array<string>   //所有的日期的数组
    private static allGroupIdKey: Array<number>
    public static allDataKeys: Array<{ text: string, value: string }>
    public static allGroupIdKeys: Array<{ text: string, value: string }>


    public static GetGroupDataById(groupid: number[]) {
        let data = new RetentionData();
        data.date = "统计"
        data.groupid = -1;
        data.magicka = 0;
        data.newAdd = 0;

        for (let index = 0; index < this.allRetentionData.length; index++) {
            const element = this.allRetentionData[index];
            if (groupid.indexOf(element.groupid) > 0)  //所有的渠道，id相同就累加
             {
                data.magicka += element.magicka;
                data.newAdd += element.newAdd;
                data.averageOnlineTime += element.averageOnlineTime;
                data.activeUser += data.activeUser;
                data.profit += data.profit;
            }
        }
        return data;
    }


    // public static GetDailyMapData(){
    //     let target:Array<{
    //         date:string,
    //         groupid: number,
    //         activeUser:nu,
    //     }>
    //     for (let index = 0; index < this.allRetentionData.length; index++) {
    //         const element = this.allRetentionData[index];
    //         let item={
    //             date:element.date,
    //             groupid: element.groupid,
    //             activeUser:element.activeUser,
    //         }

    //     }
    // }


    public static GetRetentionData(startTime: string, endTime: string, success: Function, fail: Function) {

        NetUtil.Post(NetUtil.BaseUrl + "api/retention",
            {
                token: StorageValue.Token,
                beginDate: startTime,
                endDate: endTime
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (msg.data.length <= 0) {
                    if (fail) {
                        fail("今日暂无用户数据")
                    }
                    return;
                }

                console.log("GetRetentionData msg:" + JSON.stringify(msg))

                this.allDateKey = new Array<string>();
                this.allGroupIdKey = new Array<number>();
                this.allRetentionData = new Array<RetentionData>();
                for (let i = 0; i < msg.data.length; i++) {
                    let item = new RetentionData(msg.data[i])
                    this.allRetentionData.push(item);

                    if (this.allDateKey.indexOf(item.date) < 0) {
                        this.allDateKey.push(item.date)
                    }

                    if (this.allGroupIdKey.indexOf(item.groupid) < 0) {
                        this.allGroupIdKey.push(item.groupid)
                    }
                }

                this.allDateKey.sort(
                    (a, b) => {
                        let timeA = new Date(a)
                        let timeB = new Date(b)
                        return timeA.getTime() - timeB.getTime()
                    })
                this.allGroupIdKey.sort((a, b) => { return a - b })

                this.allDataKeys = new Array<{ text: string, value: string }>()
                this.allGroupIdKeys = new Array<{ text: string, value: string }>()
                for (let index = 0; index < this.allDateKey.length; index++) {
                    const element = this.allDateKey[index];
                    this.allDataKeys.push({ text: element, value: element })
                }

                for (let index = 0; index < this.allGroupIdKey.length; index++) {
                    const element = this.allGroupIdKey[index];
                    this.allGroupIdKeys.push({ text: element.toString(), value: element.toString() })
                }

                console.log("allGroupIdKeys=" + JSON.stringify(this.allGroupIdKeys))
                if (success) {
                    success(this.allRetentionData)
                }
            },
            (err: any) => {
                return;
            })
    }


}