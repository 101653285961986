import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select, Popconfirm } from 'antd';
import StorageValue from '../../../Utils/StorageValue';
import NetUtil from '../../../Utils/NetUtil';
import TimeUtil from '../../../Utils/TimeUtil';
import moment from 'moment';

interface IProps {

}

interface IState {
    LogsList: Array<any>
    ip: string;
    curIp: string;
}

/**
 * 热更管理
 *Created by liangzai on 19/09/12.
 */
export default class HotManagerView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            ip: '',
            curIp: '',
        }
    }

    //刷新界面
    private UpdateHelp() {
        let postData = {
            token: StorageValue.Token,
            ip: this.state.ip

        }
        NetUtil.Post(NetUtil.BaseUrl + "cfg/updateIp", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                this.setState({
                })

                message.info("修改测试白名单IP成功");
            },
            (err: any) => {
                throw (err)
            })
    }

    //刷新界面
    private UpdateHelp1(data: any) {
        let postData = {
            token: StorageValue.Token,
            id: data.id,
            name: data.name,
            platformId: data.platformId,
            packageId: data.packageId,
            resourceVersion: data.resourceVersion,
            resourceVersionUrl: data.resourceVersionUrl,
            time: TimeUtil.timeStringToNumber(data.time),

        }
        NetUtil.Post(NetUtil.BaseUrl + "cfg/updateRes", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                this.setState({
                })
            },
            (err: any) => {
                throw (err)
            })
    }



    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,

        }
        NetUtil.Post(NetUtil.BaseUrl + "cfg/getUpdate", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                this.setState({
                    curIp: msg.data.realIp,
                    ip: msg.data.ip,
                    LogsList: msg.data.res
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }

    private SetPlayerIp(ip: string) {
        this.setState({
            // playerId: PlayerId
            ip : ip
        })
    }
    private SetPlayerName(name: string, id: number) {
        let itemInfo = this.state.LogsList.find(itme=>itme.id == id)
        itemInfo.name = name;
        this.setState({
        })
    }

    private SetPlayerVersion(version: string, id: number) {
        let itemInfo = this.state.LogsList.find(itme=>itme.id == id)
        itemInfo.resourceVersion = version;
        this.setState({
        })
    }

    private SetPlayerVersionUrl(versionUrl: string, id: number) {
        let itemInfo = this.state.LogsList.find(itme=>itme.id == id)
        itemInfo.resourceVersionUrl = versionUrl;
        this.setState({
        })
    }

    private SetPacketId(packageId: number) {
        this.setState({
            // channel: channel
        })
    }

    private OnTimeChange(date: any, id: number) {
        let itemInfo = this.state.LogsList.find(itme=>itme.id == id)
        if (date == null || date == undefined) {
            this.setState({
            })
            return
        }

        let begindata = date.format("YYYY-MM-DD HH:mm:ss");
        itemInfo.time = begindata
        this.setState({
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "id", dataIndex: "id", key: "id" },
            {
                align: "center", title: "名称", dataIndex: "name", key: "name", render: (text, record) => {
                    return <Input
                        value={text}
                        onChange={(e) => this.SetPlayerName(e.target.value,record.id)}
                    ></Input>
                }
            },
            { align: "center", title: "渠道", dataIndex: "platformId", key: "platformId" },
            { align: "center", title: "包", dataIndex: "packageId", key: "packageId" },
            {
                align: "center", title: "资源版本", dataIndex: "resourceVersion", key: "resourceVersion", render: (text, record) => {
                    return <Input
                        value={text}
                        onChange={(e) => this.SetPlayerVersion(e.target.value,record.id)}
                    ></Input>
                }
            },
            {
                align: "center", title: "更新地址", dataIndex: "resourceVersionUrl", key: "resourceVersionUrl", render: (text, record) => {
                    return <Input
                        value={text}
                        onChange={(e) => this.SetPlayerVersionUrl(e.target.value,record.id)}
                    ></Input>
                }
            },
            {
                align: "center", title: "更新时间", dataIndex: "time", key: "time", render: (text, record) => {
                    //return <div>{TimeUtil.TimestampToTime(text, 'yyyy-MM-dd hh:mm:ss')}</div>
                    return <DatePicker
                    showTime
                    value={moment(text)}
                    format='YYYY-MM-DD HH:mm:ss'
                    onChange={(value: any) => { this.OnTimeChange(value,record.id) }}
                />
                }
            },
            {
                align: "center", title: "操作", dataIndex: "Operation", key: "Operation", render:

                    (text, record) =>
                        <div>
                            {/* <a href="javascript:;" onClick={() => this.updateSysMail(record)} >修改</a> */}
                            <Divider type="vertical" />
                            <Popconfirm title="确定修改?" onConfirm={() => this.UpdateHelp1(record)}>
                                <a href="javascript:;">修改</a>
                            </Popconfirm>
                        </div>
            },
        ]
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label={'当前IP：' + this.state.curIp}>
                    </Form.Item>

                    <Form.Item label='测试白名单IP'>
                        <Input
                            placeholder="ip..."
                            value={this.state.ip}
                            onChange={(e) => this.SetPlayerIp(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.UpdateHelp() }}
                        >
                            设置
                        </Button>
                    </Form.Item>
                </Form>
                <Divider />
                <Table
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}