import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";
import { format } from 'date-fns';
import { type } from "os";
import Util from "../../Utils/Util";

export default class CostomMaliManager {

    public static allCostomMailData: Array<CostomMailData> = new Array<CostomMailData>();
    public static CurCostomMailData: CostomMailData;
    //添加自定义邮件
    public static AddCostomMail(Identity: string, Type: string, Title: String, Content: String, From: String, To: any, Attachment: any, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "mail/addcustom",
            {
                token: StorageValue.Token,
                type: Type,
                identity: Identity,
                title: Title,
                content: Content,
                from: From,
                to: To,
                attachment: Attachment
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success()
                }
            },
            (err: any) => {
                return;
            })
    }

    //获取自定义邮件列表
    public static GetCostomMailList(success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "mail/customlist",
            {
                token: StorageValue.Token,
            },
            (msg: any) => {
                let result = msg.result;

                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }
                this.allCostomMailData = new Array<CostomMailData>();

                for (let i = 0; i < msg.data.length; i++) {
                    let item = new CostomMailData(msg.data[i])
                    this.allCostomMailData.push(item);
                }

                if (success) {
                    success(Util.Sort(this.allCostomMailData, 'Identity'))
                }
            },
            (err: any) => {
                return;
            })
    }

    //修改自定义邮件
    public static updateCostomMail(Identity: String, Type: String, Title: String, Content: String, From: String, To: any, Attachment: any, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "mail/updatecustom",
            {
                token: StorageValue.Token,
                type: Type,
                identity: Identity,
                title: Title,
                content: Content,
                from: From,
                to: To,
                attachment: Attachment
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }

    //删除自定义邮件
    public static DeleteCostomMail(Identity: string, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "mail/delcustom",
            {
                token: StorageValue.Token,
                identity: Identity
            },
            (msg: any) => {
                let result = msg.result;

                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }
    //发送自定义邮件
    public static SendCostomMail(Identity: string, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "mail/sendcustom",
            {
                token: StorageValue.Token,
                identity: Identity
            },
            (msg: any) => {
                let result = msg.code;

                if (result != 0) {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }


    //获取数据iD和名称
    public static GetItemInfo(success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "mail/itemlist",
            {
                token: StorageValue.Token,
            },
            (msg: any) => {
                let result = msg.result;

                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }
                if (success) {
                    success(Util.Sort(msg.data, 'identity'))
                }
            },
            (err: any) => {
                return;
            })
    }
}

export class CostomMailData {
    constructor(data?: any) {
        if (data == null) {
            return;
        }
        this.Identity = data.identity;
        this.Type = data.type;
        this.Title = data.title;
        this.Content = data.content;
        this.From = data.from;
        this.To = data.to;
        if (data.to && data.to.length && data.to.length > 1) {
            let AllTo = ''
            for (let index = 0; index < data.to.length; index++) {
                const element = data.to[index];
                AllTo = AllTo + element + ',';
            }
            this.To = AllTo;
        }

        this.Attachment = JSON.stringify(data.attachment);
        this.CreateTime = format(data.createTime, "YYYY-MM-DD  hh:mm:ss");
        this.Status = data.status;
        if (data.sendTime == 0) {
            this.SendTime = '无'
        }
        else {
            this.SendTime = format(data.sendTime, "YYYY-MM-DD  hh:mm:ss");
        }

        this.SendMsg = data.SendMsg;
    }

    public Identity: String = ''; // ID
    public Type: string = ''; // 类型
    public Title: string = "";  //邮件标题
    public Content: string = "";  //邮件内容
    public From: string = "";  //发件人名字
    public To: string = "";  //收件人ID
    public Attachment: string = "";  //附件
    public CreateTime: string = "";  //创建时间
    public Status: string = "";  //发送状态
    public SendTime: string = "";  //发送时间
    public SendMsg: string = "";  //发送反馈
}