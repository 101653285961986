import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Select, Button, Modal } from 'antd';
import CDKManager from './CDKManager';
import LoaderView from '../../components/Loader/LoaderView';
const { Option } = Select;

export default class BatchCreateCDkeyView extends React.Component {
    constructor() {
        super();
        this.state = {
            Num: null,
            tableData: null,
            Category: [],
            MailInfo: [],
            CategoryData: null,
            MailInfoData: null
        }
    }
    //数量
    setNum(value) {
        this.setState({
            Num: value
        })
    }
    //
    setCategoryData(value) {
        this.setState({
            CategoryData: value
        })
    }
    //
    setMailInfoData(value) {
        console.log(value)
        this.setState({
            MailInfoData: value
        })
    }

    componentWillMount() {
        CDKManager.GetCreateinfo((data) => {
            console.log("data" + JSON.stringify(data))
            this.setState({
                tableData: data,
                Category: data.Category,
                MailInfo: data.MailInfo
            })
        },
            () => {

            })
    }

    //添加cdK分类
    handleAddCdkey() {
        CDKManager.CreateKey(this.state.Num, this.state.CategoryData, this.state.MailInfoData, () => {
            message.info("CDK分类添加成功！");
            this.props.onCancel()
        }, (err) => {
            message.info(err);
        })
    }

    render() {
        if (!this.state.tableData) {
            return <LoaderView />
        }

        const formItemStle = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }


        return (
            <div>
                <Modal
                    okText='生成'
                    cancelText='取消'
                    width="60%"
                    onCancel={() => { this.props.onCancel() }}
                    onOk={() => { this.handleAddCdkey() }}
                    visible={this.props.formVisible}
                >
                    <Form style={{ textAlign: "center" }} onSubmit={() => { this.handleAddCdkey() }}>
                        <h1 style={{ fontSize: "30px" }}>批量生成CDKey</h1>
                        <Form.Item label='数量' {...formItemStle}>
                            <Input
                                placeholder="请输入数量..."
                                prefix={<UserOutlined />}
                                value={this.state.Num}
                                onChange={(e) => this.setNum(e.target.value)}
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item label='邮件ID' {...formItemStle}>
                            <Select
                                defaultValue={this.state.MailInfo.length > 0 ? this.state.MailInfo[0].Name : ""}
                                size={"large"}

                                onSelect={(key) => { this.setMailInfoData(key) }}
                            >
                                {this.state.MailInfo.map((item) => {
                                    return (
                                        <Option key={item.Identity} value={item.Identity}>{item.Name}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item label='类型' {...formItemStle}>
                            <Select
                                defaultValue={this.state.Category.length > 0 ? this.state.Category[0].Name : ""}
                                size={"large"}
                                onSelect={(key) => { this.setCategoryData(key) }}
                            >
                                {this.state.Category.map((item) => {
                                    return (
                                        <Option key={item.Identity} value={item.Identity}>{item.Name}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: '67%' }}>
                                批量生成CDK
                        </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}