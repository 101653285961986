import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";

export class MagicRetainedData {
    constructor(data: any) {
        this.date = data.date;
        this.groupid = data.groupid;
        this.activeUser = data.activeUser;
        this.averageOnlineTime = data.averageOnlineTime;
        this.newAddList = new Array<number>()
        for (let index = 0; index < data.r.length; index++) {
            const element = data.r[index];
            this.newAddList.push(element)
        }
    }

    public date: string = ""                            //日期
    public groupid: number = 0;                         //渠道ID
    public activeUser: number = 0;                      //日活
    public averageOnlineTime: number = 0;               //平均在线
    public get todayNewAdd(): number                    //当日新增量
    {
        if (this.newAddList.length > 0 && this.newAddList[0] > 0) {
            return this.newAddList[0]
        }
        return 0
    }
    public newAddList: Array<number> = new Array<number>()       // 新增
    public get conversionRate(): string {
        if (this.todayNewAdd > 0) {
            return (this.todayNewAdd / this.newAddList[0] * 100).toString() + "%"
        }
        return "0%"
    }
}

export default class MagicRetainedDataManager {
    // private static IsUseTextData = true;


    private static allRetentionData: Array<MagicRetainedData>
    private static allDateKey: Array<string>   //所有的日期的数组
    private static allGroupIdKey: Array<number>
    public static allDataKeys: Array<{ text: string, value: string }>
    public static allGroupIdKeys: Array<{ text: string, value: string }>
    public static GetMagicRetentionData(beginDate: string, endTime: string, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "api/retentionmk",
            {
                token: StorageValue.Token,
                beginDate: beginDate,
                endDate: endTime
            },
            (msg: any) => {
                this.allDateKey = new Array<string>();
                this.allGroupIdKey = new Array<number>();
                this.allRetentionData = new Array<MagicRetainedData>();

                // if (this.IsUseTextData) {
                //     for (let i = 0; i < msg.data.length; i++) {
                //         let item = new MagicRetainedData(this.table.data[i])
                //         this.allRetentionData.push(item);

                //         if (this.allDateKey.indexOf(item.date) < 0) {
                //             this.allDateKey.push(item.date)
                //         }

                //         if (this.allGroupIdKey.indexOf(item.groupid) < 0) {
                //             this.allGroupIdKey.push(item.groupid)
                //         }
                //     }
                // }
                // else {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (msg.data.length <= 0) {
                    if (fail) {
                        fail("今日暂无用户数据")
                    }
                    return;
                }
                for (let i = 0; i < msg.data.length; i++) {
                    let item = new MagicRetainedData(msg.data[i])
                    this.allRetentionData.push(item);

                    if (this.allDateKey.indexOf(item.date) < 0) {
                        this.allDateKey.push(item.date)
                    }

                    if (this.allGroupIdKey.indexOf(item.groupid) < 0) {
                        this.allGroupIdKey.push(item.groupid)
                    }
                    // }
                }

                console.log("GetMagicRetentionData allRetentionData:" + JSON.stringify(this.allRetentionData))

                this.allDateKey.sort(
                    (a, b) => {
                        let timeA = new Date(a)
                        let timeB = new Date(b)
                        return timeA.getTime() - timeB.getTime()
                    })
                this.allGroupIdKey.sort((a, b) => { return a - b })

                this.allDataKeys = new Array<{ text: string, value: string }>()
                this.allGroupIdKeys = new Array<{ text: string, value: string }>()
                for (let index = 0; index < this.allDateKey.length; index++) {
                    const element = this.allDateKey[index];
                    this.allDataKeys.push({ text: element, value: element })
                }

                for (let index = 0; index < this.allGroupIdKey.length; index++) {
                    const element = this.allGroupIdKey[index];
                    this.allGroupIdKeys.push({ text: element.toString(), value: element.toString() })
                }

                console.log("allGroupIdKeys=" + JSON.stringify(this.allGroupIdKeys))
                if (success) {
                    success(this.allRetentionData)
                }
            },
            (err: any) => {
                return;
            })
    }


    // public static table = {
    //     "data": [{
    //         "date": "2019-07-26",
    //         "groupid": 10007,
    //         "profit": 0,
    //         "activeUser": 8,
    //         "averageOnlineTime": 831,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10008,
    //         "profit": 0,
    //         "activeUser": 37,
    //         "averageOnlineTime": 4979,
    //         "magicka": 0,
    //         "r": [4, 1, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10009,
    //         "profit": 0,
    //         "activeUser": 17,
    //         "averageOnlineTime": 1847,
    //         "magicka": 0,
    //         "r": [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10003,
    //         "profit": 0,
    //         "activeUser": 11,
    //         "averageOnlineTime": 5791,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10004,
    //         "profit": 0,
    //         "activeUser": 40,
    //         "averageOnlineTime": 4299,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10006,
    //         "profit": 0,
    //         "activeUser": 1,
    //         "averageOnlineTime": 3436,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10000,
    //         "profit": 0,
    //         "activeUser": 0,
    //         "averageOnlineTime": 0,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10001,
    //         "profit": 0,
    //         "activeUser": 3,
    //         "averageOnlineTime": 2865,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10002,
    //         "profit": 0,
    //         "activeUser": 5,
    //         "averageOnlineTime": 7199,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10018,
    //         "profit": 0,
    //         "activeUser": 12,
    //         "averageOnlineTime": 1414,
    //         "magicka": 0,
    //         "r": [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10007,
    //         "profit": 0,
    //         "activeUser": 10,
    //         "averageOnlineTime": 3396,
    //         "magicka": 0,
    //         "r": [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10017,
    //         "profit": 0,
    //         "activeUser": 2,
    //         "averageOnlineTime": 1914,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10006,
    //         "profit": 0,
    //         "activeUser": 1,
    //         "averageOnlineTime": 710,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10004,
    //         "profit": 0,
    //         "activeUser": 29,
    //         "averageOnlineTime": 3336,
    //         "magicka": 0,
    //         "r": [2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10003,
    //         "profit": 0,
    //         "activeUser": 18,
    //         "averageOnlineTime": 2034,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10002,
    //         "profit": 0,
    //         "activeUser": 4,
    //         "averageOnlineTime": 539,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10001,
    //         "profit": 0,
    //         "activeUser": 8,
    //         "averageOnlineTime": 2522,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10000,
    //         "profit": 0,
    //         "activeUser": 0,
    //         "averageOnlineTime": 0,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10010,
    //         "profit": 0,
    //         "activeUser": 5,
    //         "averageOnlineTime": 2930,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10011,
    //         "profit": 0,
    //         "activeUser": 18,
    //         "averageOnlineTime": 5206,
    //         "magicka": 0,
    //         "r": [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10014,
    //         "profit": 0,
    //         "activeUser": 12,
    //         "averageOnlineTime": 1353,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10016,
    //         "profit": 0,
    //         "activeUser": 14,
    //         "averageOnlineTime": 1615,
    //         "magicka": 0,
    //         "r": [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10015,
    //         "profit": 0,
    //         "activeUser": 28,
    //         "averageOnlineTime": 3803,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 0,
    //         "profit": 0,
    //         "activeUser": 200,
    //         "averageOnlineTime": 5213,
    //         "magicka": 0,
    //         "r": [9, 8, 5, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10018,
    //         "profit": 0,
    //         "activeUser": 12,
    //         "averageOnlineTime": 1447,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10014,
    //         "profit": 0,
    //         "activeUser": 12,
    //         "averageOnlineTime": 2813,
    //         "magicka": 0,
    //         "r": [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10015,
    //         "profit": 0,
    //         "activeUser": 22,
    //         "averageOnlineTime": 4211,
    //         "magicka": 0,
    //         "r": [1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10016,
    //         "profit": 0,
    //         "activeUser": 9,
    //         "averageOnlineTime": 2985,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10017,
    //         "profit": 0,
    //         "activeUser": 2,
    //         "averageOnlineTime": 7732,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10010,
    //         "profit": 0,
    //         "activeUser": 7,
    //         "averageOnlineTime": 4161,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-26",
    //         "groupid": 10011,
    //         "profit": 0,
    //         "activeUser": 14,
    //         "averageOnlineTime": 6660,
    //         "magicka": 0,
    //         "r": [3, 5, 3, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10009,
    //         "profit": 0,
    //         "activeUser": 19,
    //         "averageOnlineTime": 3173,
    //         "magicka": 0,
    //         "r": [2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10008,
    //         "profit": 0,
    //         "activeUser": 35,
    //         "averageOnlineTime": 2827,
    //         "magicka": 0,
    //         "r": [4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10007,
    //         "profit": 0,
    //         "activeUser": 13,
    //         "averageOnlineTime": 5268,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10018,
    //         "profit": 0,
    //         "activeUser": 11,
    //         "averageOnlineTime": 1973,
    //         "magicka": 0,
    //         "r": [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10017,
    //         "profit": 0,
    //         "activeUser": 2,
    //         "averageOnlineTime": 9393,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10009,
    //         "profit": 0,
    //         "activeUser": 28,
    //         "averageOnlineTime": 2729,
    //         "magicka": 0,
    //         "r": [3, 1, 1, 0, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10016,
    //         "profit": 0,
    //         "activeUser": 8,
    //         "averageOnlineTime": 7872,
    //         "magicka": 0,
    //         "r": [2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10008,
    //         "profit": 0,
    //         "activeUser": 43,
    //         "averageOnlineTime": 4064,
    //         "magicka": 0,
    //         "r": [8, 3, 1, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10015,
    //         "profit": 0,
    //         "activeUser": 24,
    //         "averageOnlineTime": 2714,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10014,
    //         "profit": 0,
    //         "activeUser": 17,
    //         "averageOnlineTime": 6377,
    //         "magicka": 0,
    //         "r": [2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10011,
    //         "profit": 0,
    //         "activeUser": 16,
    //         "averageOnlineTime": 4183,
    //         "magicka": 0,
    //         "r": [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 10010,
    //         "profit": 0,
    //         "activeUser": 8,
    //         "averageOnlineTime": 5116,
    //         "magicka": 0,
    //         "r": [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10001,
    //         "profit": 0,
    //         "activeUser": 9,
    //         "averageOnlineTime": 1570,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10000,
    //         "profit": 0,
    //         "activeUser": 0,
    //         "averageOnlineTime": 0,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10003,
    //         "profit": 0,
    //         "activeUser": 13,
    //         "averageOnlineTime": 5100,
    //         "magicka": 0,
    //         "r": [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10002,
    //         "profit": 0,
    //         "activeUser": 9,
    //         "averageOnlineTime": 4033,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 10004,
    //         "profit": 0,
    //         "activeUser": 42,
    //         "averageOnlineTime": 3142,
    //         "magicka": 0,
    //         "r": [0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10009,
    //         "profit": 0,
    //         "activeUser": 23,
    //         "averageOnlineTime": 3345,
    //         "magicka": 0,
    //         "r": [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10014,
    //         "profit": 0,
    //         "activeUser": 17,
    //         "averageOnlineTime": 2335,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10015,
    //         "profit": 0,
    //         "activeUser": 16,
    //         "averageOnlineTime": 2935,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10000,
    //         "profit": 0,
    //         "activeUser": 0,
    //         "averageOnlineTime": 0,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10016,
    //         "profit": 0,
    //         "activeUser": 6,
    //         "averageOnlineTime": 4631,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10001,
    //         "profit": 0,
    //         "activeUser": 5,
    //         "averageOnlineTime": 4543,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10017,
    //         "profit": 0,
    //         "activeUser": 2,
    //         "averageOnlineTime": 17655,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10018,
    //         "profit": 0,
    //         "activeUser": 10,
    //         "averageOnlineTime": 3305,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 0,
    //         "profit": 0,
    //         "activeUser": 211,
    //         "averageOnlineTime": 4957,
    //         "magicka": 0,
    //         "r": [7, 5, 3, 5, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10004,
    //         "profit": 0,
    //         "activeUser": 31,
    //         "averageOnlineTime": 3391,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10002,
    //         "profit": 0,
    //         "activeUser": 5,
    //         "averageOnlineTime": 1327,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10003,
    //         "profit": 0,
    //         "activeUser": 14,
    //         "averageOnlineTime": 4389,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10008,
    //         "profit": 0,
    //         "activeUser": 35,
    //         "averageOnlineTime": 2976,
    //         "magicka": 0,
    //         "r": [4, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-29",
    //         "groupid": 0,
    //         "profit": 0,
    //         "activeUser": 210,
    //         "averageOnlineTime": 4397,
    //         "magicka": 0,
    //         "r": [20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10009,
    //         "profit": 0,
    //         "activeUser": 17,
    //         "averageOnlineTime": 2355,
    //         "magicka": 0,
    //         "r": [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10010,
    //         "profit": 0,
    //         "activeUser": 6,
    //         "averageOnlineTime": 4306,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10007,
    //         "profit": 0,
    //         "activeUser": 6,
    //         "averageOnlineTime": 4768,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10011,
    //         "profit": 0,
    //         "activeUser": 19,
    //         "averageOnlineTime": 5349,
    //         "magicka": 0,
    //         "r": [3, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10001,
    //         "profit": 0,
    //         "activeUser": 4,
    //         "averageOnlineTime": 296,
    //         "magicka": 0,
    //         "r": [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10002,
    //         "profit": 0,
    //         "activeUser": 1,
    //         "averageOnlineTime": 596,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10003,
    //         "profit": 0,
    //         "activeUser": 20,
    //         "averageOnlineTime": 2290,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10004,
    //         "profit": 0,
    //         "activeUser": 27,
    //         "averageOnlineTime": 4071,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10011,
    //         "profit": 0,
    //         "activeUser": 15,
    //         "averageOnlineTime": 9755,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10006,
    //         "profit": 0,
    //         "activeUser": 1,
    //         "averageOnlineTime": 748,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10007,
    //         "profit": 0,
    //         "activeUser": 9,
    //         "averageOnlineTime": 691,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10010,
    //         "profit": 0,
    //         "activeUser": 8,
    //         "averageOnlineTime": 2003,
    //         "magicka": 0,
    //         "r": [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10008,
    //         "profit": 0,
    //         "activeUser": 28,
    //         "averageOnlineTime": 3295,
    //         "magicka": 0,
    //         "r": [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10015,
    //         "profit": 0,
    //         "activeUser": 19,
    //         "averageOnlineTime": 7862,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10016,
    //         "profit": 0,
    //         "activeUser": 9,
    //         "averageOnlineTime": 4087,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10014,
    //         "profit": 0,
    //         "activeUser": 17,
    //         "averageOnlineTime": 2468,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10017,
    //         "profit": 0,
    //         "activeUser": 2,
    //         "averageOnlineTime": 31521,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 10018,
    //         "profit": 0,
    //         "activeUser": 13,
    //         "averageOnlineTime": 1566,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 10000,
    //         "profit": 0,
    //         "activeUser": 0,
    //         "averageOnlineTime": 0,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 0,
    //         "profit": 0,
    //         "activeUser": 229,
    //         "averageOnlineTime": 2990,
    //         "magicka": 0,
    //         "r": [11, 7, 8, 6, 6, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-28",
    //         "groupid": 0,
    //         "profit": 0,
    //         "activeUser": 189,
    //         "averageOnlineTime": 4537,
    //         "magicka": 0,
    //         "r": [5, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10014,
    //         "profit": 0,
    //         "activeUser": 14,
    //         "averageOnlineTime": 3657,
    //         "magicka": 0,
    //         "r": [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10011,
    //         "profit": 0,
    //         "activeUser": 12,
    //         "averageOnlineTime": 7696,
    //         "magicka": 0,
    //         "r": [0, 1, 1, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10010,
    //         "profit": 0,
    //         "activeUser": 3,
    //         "averageOnlineTime": 4630,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10017,
    //         "profit": 0,
    //         "activeUser": 2,
    //         "averageOnlineTime": 22333,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10018,
    //         "profit": 0,
    //         "activeUser": 10,
    //         "averageOnlineTime": 2236,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10015,
    //         "profit": 0,
    //         "activeUser": 21,
    //         "averageOnlineTime": 2403,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10016,
    //         "profit": 0,
    //         "activeUser": 10,
    //         "averageOnlineTime": 2552,
    //         "magicka": 0,
    //         "r": [1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10009,
    //         "profit": 0,
    //         "activeUser": 25,
    //         "averageOnlineTime": 1802,
    //         "magicka": 0,
    //         "r": [2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10008,
    //         "profit": 0,
    //         "activeUser": 37,
    //         "averageOnlineTime": 3985,
    //         "magicka": 0,
    //         "r": [3, 3, 3, 2, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10007,
    //         "profit": 0,
    //         "activeUser": 9,
    //         "averageOnlineTime": 3778,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10006,
    //         "profit": 0,
    //         "activeUser": 1,
    //         "averageOnlineTime": 73,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10004,
    //         "profit": 0,
    //         "activeUser": 41,
    //         "averageOnlineTime": 3871,
    //         "magicka": 0,
    //         "r": [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10003,
    //         "profit": 0,
    //         "activeUser": 17,
    //         "averageOnlineTime": 2028,
    //         "magicka": 0,
    //         "r": [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-23",
    //         "groupid": 0,
    //         "profit": 0,
    //         "activeUser": 248,
    //         "averageOnlineTime": 3602,
    //         "magicka": 0,
    //         "r": [15, 6, 3, 3, 4, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10002,
    //         "profit": 0,
    //         "activeUser": 7,
    //         "averageOnlineTime": 5155,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10001,
    //         "profit": 0,
    //         "activeUser": 5,
    //         "averageOnlineTime": 972,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10000,
    //         "profit": 0,
    //         "activeUser": 0,
    //         "averageOnlineTime": 0,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-27",
    //         "groupid": 0,
    //         "profit": 0,
    //         "activeUser": 196,
    //         "averageOnlineTime": 2710,
    //         "magicka": 0,
    //         "r": [6, 2, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10002,
    //         "profit": 0,
    //         "activeUser": 7,
    //         "averageOnlineTime": 5854,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10003,
    //         "profit": 0,
    //         "activeUser": 17,
    //         "averageOnlineTime": 4852,
    //         "magicka": 0,
    //         "r": [1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10000,
    //         "profit": 0,
    //         "activeUser": 0,
    //         "averageOnlineTime": 0,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10001,
    //         "profit": 0,
    //         "activeUser": 9,
    //         "averageOnlineTime": 2448,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10008,
    //         "profit": 0,
    //         "activeUser": 41,
    //         "averageOnlineTime": 5996,
    //         "magicka": 0,
    //         "r": [1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10009,
    //         "profit": 0,
    //         "activeUser": 20,
    //         "averageOnlineTime": 3242,
    //         "magicka": 0,
    //         "r": [1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10006,
    //         "profit": 0,
    //         "activeUser": 1,
    //         "averageOnlineTime": 1897,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10007,
    //         "profit": 0,
    //         "activeUser": 8,
    //         "averageOnlineTime": 3819,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-25",
    //         "groupid": 10004,
    //         "profit": 0,
    //         "activeUser": 36,
    //         "averageOnlineTime": 7200,
    //         "magicka": 0,
    //         "r": [2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10018,
    //         "profit": 0,
    //         "activeUser": 11,
    //         "averageOnlineTime": 4443,
    //         "magicka": 0,
    //         "r": [1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10017,
    //         "profit": 0,
    //         "activeUser": 2,
    //         "averageOnlineTime": 18936,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10016,
    //         "profit": 0,
    //         "activeUser": 14,
    //         "averageOnlineTime": 1455,
    //         "magicka": 0,
    //         "r": [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10015,
    //         "profit": 0,
    //         "activeUser": 27,
    //         "averageOnlineTime": 3075,
    //         "magicka": 0,
    //         "r": [2, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10014,
    //         "profit": 0,
    //         "activeUser": 10,
    //         "averageOnlineTime": 2205,
    //         "magicka": 0,
    //         "r": [0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10011,
    //         "profit": 0,
    //         "activeUser": 18,
    //         "averageOnlineTime": 1417,
    //         "magicka": 0,
    //         "r": [1, 1, 2, 2, 2, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }, {
    //         "date": "2019-07-24",
    //         "groupid": 10010,
    //         "profit": 0,
    //         "activeUser": 5,
    //         "averageOnlineTime": 6869,
    //         "magicka": 0,
    //         "r": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //     }],
    //     "result": "OK"
    // }
}