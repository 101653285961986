import React from 'react';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Button, Input, message, Modal } from 'antd';
import logo from '../assets/images/logo.svg';
import styles from './Login.module.css';
import LoginHandle from './LoginHandle';
import StorageValue from '../Utils/StorageValue';
import UserInfo from '../UserInfo/UserInfo';

import CommDialog from '../Utils/CommDilog';


export default class Login extends React.Component {
    constructor() {
        super();
        this.isRemoberPassValue = StorageValue.isRemberPass ? StorageValue.isRemberPass : false;
        console.log(this.isRemoberPassValue + "isRemoberPassValue")
        this.state = {
            userName: "root",
            password: "e1027137154d2917f01b1b4cf6aa175b",
            isRememberPass: this.isRemoberPassValue,
            authCode: '',
        }
    }
    //组件渲染完成时触发
    componentWillMount() {
        if (this.isRemoberPassValue) {
            this.setAccountInfo()
        }
    }

    //账号
    setUserName(value) {
        this.setState({
            userName: value
        })
    }

    //是否记住密码，然后把信息显示在页面上
    setAccountInfo() {
        let userNameValue = StorageValue.userName ? StorageValue.userName : "admin";
        let passwordValue = StorageValue.passWord ? StorageValue.passWord : "123456";
        let isRemoberPassValue = StorageValue.isRemberPass ? StorageValue.isRemberPass : false;
        console.log(isRemoberPassValue);

        this.setState({
            userName: userNameValue,
            password: passwordValue,
            isRemoberPass: isRemoberPassValue,
        })
    }

    //是否记住密码
    setIsRemberPass(value) {
        this.setState({
            isRemoberPass: value
        })
    }

    //验证码
    setauthCode(value) {
        if (value.length > 6) {
            // if (!RegExpCheck.Code.test(this.state.authCode)) {
            //     message.error('验证码仅能输入6位数字！')
            //     return
            // }
            return
        }
        this.setState({ authCode: value })
    }

    //登录
    gotoLogin(e) {
        e.preventDefault();
        UserInfo.Account = this.state.userName;
        UserInfo.Password = this.state.password;
        StorageValue.isRemberPass = this.state.isRememberPass
        StorageValue.userName = this.state.userName;
        StorageValue.passWord = this.state.password;
        UserInfo.googleCode = this.state.authCode;
        LoginHandle.OnError = (err) => {

            CommDialog.Modle("提示", err)

            // CommDialog.showConfirm("提示",err,()=>{
            //     console.log('aaaaaaaaaaa');
            //     this.props.history.push('/home/general')
            // });
        }
        LoginHandle.history = this.props.history;
        LoginHandle.LoginStep1()
    }

    setPassword(value) {
        this.setState({
            password: value
        })
    }

    render() {
        return (
            <div>
                <div className={styles.header}>
                    <div className={styles['header-wrapper']}>
                        <header>
                            <div className={styles['nav-wrapper']}>
                            </div>
                        </header>
                    </div>
                </div>
                <div className={styles.content}>
                    <Form onSubmit={(e) => { this.gotoLogin(e) }} className={styles['login-form']}>
                        <h3>欢迎登录</h3>
                        <Form.Item>
                            <Input
                                placeholder="请输入用户名..."
                                prefix={<UserOutlined />}
                                suffix={this.state.userNameSuffix}
                                value={this.state.userName}
                                onChange={(e) => this.setUserName(e.target.value)}
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Input
                                type="password"
                                placeholder="请输入密码..."
                                prefix={<EyeOutlined />}
                                value={this.state.password}
                                onChange={(e) => this.setPassword(e.target.value)}
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Input
                                placeholder="请输入验证码..."
                                prefix={<EyeOutlined />}
                                value={this.state.authCode}
                                onChange={(e) => this.setauthCode(e.target.value)}
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Checkbox
                                onChange={(e) => {
                                    console.log(e)
                                    this.setIsRemberPass(e.target.checked)
                                }}
                                checked={
                                    this.state.isRememberPass
                                }
                            >记住
                            </Checkbox>
                            <Button type="primary" htmlType="submit" className={styles['login-form-button']}>
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}

