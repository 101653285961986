import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import NetUtil from '../../../Utils/NetUtil';
import TimeUtil from '../../../Utils/TimeUtil';
import StorageValue from '../../../Utils/StorageValue';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    BeginTime: number
    EndTime: number
    PP: string
    OP: string
    Account: string
    LogInfoId: string
    logInfoModalVisible: boolean
}

/**
 * 操作日志
 *Created by liangzai on 19/09/12.
 */
export default class LogView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private now = new Date();
    private yesterday = subDays(this.now, 7)
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            BeginTime: TimeUtil.GetDayBegin(this.yesterday),
            EndTime: TimeUtil.GetDayEnd(this.now),
            PP: '0',
            OP: '0',
            Account: '',
            LogInfoId: '0',
            logInfoModalVisible: false
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            BeginTime: this.state.BeginTime,
            EndTime: this.state.EndTime,
            PP: parseInt(this.state.PP),
            OP: parseInt(this.state.OP),
            Account: this.state.Account
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/log/all", postData,
            (msg: any) => {
                let Result = msg.Result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.setState({
                    LogsList: msg.Data
                })
            },
            () => {

            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                BeginTime: this.yesterday.valueOf(),
                EndTime: this.now.valueOf(),
            })

            return;
        }
        let begindata = moment(date[0]).valueOf();
        let enddata = moment(date[1]).valueOf();

        this.setState({
            BeginTime: begindata,
            EndTime: enddata
        })
    }
    //来源
    private SetSelPP(value: string) {
        this.setState({
            PP: value
        })
    }

    //操作
    private SetSelOP(value: string) {
        this.setState({
            OP: value
        })
    }
    //用户
    private SetSelAccount(value: string) {
        this.setState({
            Account: value
        })
    }
    //关闭弹窗
    private closeModal() {
        this.setState({
            logInfoModalVisible: false
        })
    }

    //隐藏时间
    private disabledDate(current: moment.Moment | undefined):any {
        return current && current > moment().endOf('day');
    }


    render() {
        this.columns = [
            {
                align: "center", title: "日志编号", dataIndex: "OpId", key: "OpId",
                sorter: (a: any, b: any) => a.OpId - b.OpId,
                // sortOrder: false,
                defaultSortOrder: 'descend',
            },
            {
                align: "center", title: "时间", dataIndex: "Time", key: "Time", render:

                    (text: any, record: any) => {
                        return <div>{TimeUtil.TimestampToTime(text)}</div>
                    }
            },
            {
                align: "center", title: "来源", dataIndex: "PP", key: "PP"
                // , render:

                //     (text: any, record: any) => {
                //         return <div>{ArrayPage.map((item) => {
                //             if (item.Id == text) {
                //                 return item.Name;
                //             }
                //         })}</div>
                //     }
            },
            {
                align: "center", title: "操作", dataIndex: "OP", key: "OP"
                // , render:

                //     (text: any, record: any) => {
                //         return <div>{ArrayOptionType.map((item) => {
                //             if (item.Id == text) {
                //                 return item.Name;
                //             }
                //         })}</div>
                //     }
            },
            { align: "center", title: "用户", dataIndex: "Account", key: "Account" },
            { align: "center", title: "IP", dataIndex: "IP", key: "IP" },
            { align: "center", title: "描述", dataIndex: "Desc", key: "Desc" },
            {
                align: "center", title: "操作", dataIndex: "Detail", key: "Detail", render:
                    (text: any, record: any) => {
                        if (text) {
                            return <a onClick={() => {
                                this.closeModal();
                                this.setState({
                                    logInfoModalVisible: true,
                                    LogInfoId: record.OpId
                                })
                            }}>详情</a>
                        }
                        return null
                    }
            },
        ]
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 7))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            disabledDate={(current: moment.Moment | undefined) => this.disabledDate(current)}
                            showTime={{
                                hideDisabledOptions: true,
                                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                            }}
                            format='YYYY-MM-DD HH:mm:ss'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='来源' >
                        <Select
                            defaultValue={"全部来源"}

                            onSelect={(key: string) => { this.SetSelPP(key) }}
                        >
                            {/* <Option key={"0"} value={0}>{"全部来源"}</Option>
                            {ArrayPage.map((item: any) => {
                                return (
                                    <Option key={item.Id} value={item.Id}>{item.Name}</Option>
                                )
                            })} */}
                        </Select>
                    </Form.Item>
                    <Form.Item label='操作' >

                        <Select
                            defaultValue={"全部操作"}

                            onSelect={(key: string) => { this.SetSelOP(key) }}
                        >
                            <Option key={"0"} value={0}>{"全部操作"}</Option>
                            {/* {ArrayOptionType.map((item: any) => {
                                return (
                                    <Option key={item.Id} value={item.Id}>{item.Name}</Option>
                                )
                            })} */}
                        </Select>
                    </Form.Item>
                    <Form.Item label='账号'  >
                        <Input
                            value={this.state.Account}
                            placeholder="账号"
                            onChange={(e: any) => { this.SetSelAccount(e.target.value) }}
                        />
                    </Form.Item>
                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>

                </Form>
{/* 
                <LogInfoModal
                    LogInfoId={this.state.LogInfoId}
                    visible={this.state.logInfoModalVisible}
                    onCancel={() => { this.closeModal() }}
                    onSendApplySuccess={() => {
                        this.closeModal();

                    }}
                /> */}
                <Divider type='horizontal' />
                <Table
                    title={() => {
                        return (<div style={{ textAlign: "center", fontSize: 20 }}>
                            <b>日志列表</b>
                        </div>)
                    }}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}