import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Button, DatePicker, Select } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import StorageValue from '../../Utils/StorageValue';
import NetUtil from '../../Utils/NetUtil';
const { Option } = Select;
const { RangePicker } = DatePicker;

interface IProps {
}

interface IState {
    LogsList: Array<any>
    begin: string
    end: string
}

/**
 * 运营统计
 *Created by liangzai on 21/04/02.
 */
export default class OperationView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private now = new Date();
    private yesterday = subDays(this.now, 6)
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
            end: TimeUtil.Format(this.now, 'yyyy-MM-dd'),
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
        }
        NetUtil.Post(NetUtil.BaseUrl + "analysis/operational", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                let data: Array<any> = [];
                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index];
                    element.beanOutIn = (element.beanOutIn * 100).toFixed(2) + "%";
                    element.roi = (element.roi * 100).toFixed(2) + "%";
                    data.push(element)
                }

                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.date > b.date ? -1 : 1
                        })
                })
            },
            () => {

            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }

    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
                end: TimeUtil.Format(this.now, 'yyyy-MM-dd')
            })
            return;
        }
        let begindata = TimeUtil.MomentToTimeString(date[0]);
        let enddata = TimeUtil.MomentToTimeString(date[1]);

        this.setState({
            begin: begindata,
            end: enddata
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "日期", dataIndex: "date", key: "date", width: "120px", fixed: 'left' },
            { align: "center", title: "新增", dataIndex: "create", key: "create" },
            { align: "center", title: "日活", dataIndex: "login", key: "login" },
            { align: "center", title: "CPL", dataIndex: "cpl", key: "cpl" },
            { align: "center", title: "充值", dataIndex: "recharge", key: "recharge" },
            { align: "center", title: "ROI", dataIndex: "roi", key: "roi" },
            { align: "center", title: "手动", dataIndex: "beanExchangeOut", key: "beanExchangeOut" },
            { align: "center", title: "福利", dataIndex: "daiamondExchange", key: "daiamondExchange" },

            { align: "center", title: "利益", dataIndex: "profit", key: "profit" },
            { align: "center", title: "游戏产豆", dataIndex: "beanGameIn", key: "beanGameIn" },
            { align: "center", title: "运营产豆", dataIndex: "beanOperatIn", key: "beanOperatIn" },
            { align: "center", title: "内耗豆", dataIndex: "beanGameOut", key: "beanGameOut" },
        ]
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 6))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    {/* <Form.Item label='渠道' >
                        <Select
                            style={{ width: "200Px" }}
                            defaultValue={0}
                            onChange={(value: number) => { this.SetChannel(value) }}
                        >
                            <Option key={"0"} value={0}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item> */}

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>
                <Divider />
                <Table
                    pagination={false}
                    scroll={{ y: 700 }}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}