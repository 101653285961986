import React from 'react';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Select, Button, Modal } from 'antd';
import CDKManager from './CDKManager';
import StorageValue from '../../Utils/StorageValue';
const { Option } = Select;
/**
 * 添加CDK分类
*/
export default class AddCDKeyClassifyView extends React.Component {
    constructor() {
        super();
        this.state = {
            Id: null,
            Name: null,
            Des: null,
            Staus: 'Y'
        }
    }
    //名称
    setName(value) {
        this.setState({
            Name: value
        })
    }
    //Id
    setId(value) {
        this.setState({
            Id: value
        })
    }
    //描述
    setDes(value) {
        this.setState({
            Des: value
        })
    }
    //状态
    handStausChange(value) {
        this.setState({
            Staus: value
        })
    }
    //添加cdK分类
    handleAddCdkey() {
        CDKManager.AddCategory(this.state.Id, this.state.Name, this.state.Staus, this.state.Des, () => {
            message.info("CDK分类添加成功！");
        }, (err) => {
            message.info(err);
        })
    }

    render() {

        const formItemStle = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }
        return (
            <Modal
                okText='添加'
                cancelText='取消'
                width="60%"
                onCancel={() => { this.props.onCancel() }}
                onOk={() => { this.handleAddCdkey() }}
                visible={this.props.formVisible}
            >
                <Form style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "30px" }}>添加CDK分类</h1>
                    <Form.Item label='ID:' {...formItemStle}>
                        <Input
                            placeholder="请输入ID..."
                            prefix={<UserOutlined />}
                            value={this.state.Id}
                            onChange={(e) => this.setId(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='名称:' {...formItemStle}>
                        <Input
                            placeholder="请输入名称..."
                            prefix={<EyeOutlined />}
                            value={this.state.Name}
                            onChange={(e) => this.setName(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='描述:' {...formItemStle}>
                        <Input
                            placeholder="描述..."
                            prefix={<EyeOutlined />}
                            value={this.state.Des}
                            onChange={(e) => this.setDes(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='状态:' {...formItemStle}>
                        <Select
                            defaultValue='Y'
                            size={"large"}
                            onSelect={(key) => { this.handStausChange(key) }}
                        >
                            <Option value="Y">启用</Option>
                            <Option value="N">禁用</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}