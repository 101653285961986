import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DownOutlined, PoweroffOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import styles from './UserSetting.module.css';
import StorageValue from '../../Utils/StorageValue';


export default class UserSettingView extends React.Component {
    constructor(props) {
        super();
    }

    setCollapsed() {
        this.props.setCollapsed()
    }

    render() {
        return (
            <div className={styles['header-wrapper']} >
                <span className={styles['header-collapsed']} onClick={() => this.setCollapsed()}>
                    <LegacyIcon type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'} />
                </span>
                <div className={styles['header-user-info']}>
                    <Dropdown overlay={HeaderMenu} placement="bottomRight">
                        <span className={styles['header-dropdown-link']}>
                            <UserOutlined /> {StorageValue.userName} <DownOutlined />
                        </span>
                    </Dropdown>
                </div>
            </div >
        );
    }
}

function HeaderMenu() {
    return (
        <Menu>
            <Menu.Item key="1"> 
                <Link to="/home/setting">
                    <SettingOutlined />&nbsp;修改密码
                    </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="2">
                <Link to="/login">
                    <PoweroffOutlined />&nbsp;退出登录
                </Link>
            </Menu.Item>
        </Menu>
    );

}