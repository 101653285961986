import React from 'react';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Select, Button, AutoComplete } from 'antd';
import AccountManger from './AccountManger';
import StorageValue from '../../Utils/StorageValue';
const { Option } = Select;

export default class AddAccountView extends React.Component {
    constructor() {
        super();
        this.state = {
            authLevel: "JuniorAdmin",
            userName: null,
            password: null,
            groupId: 0
        }
    }

    setAccount(value) {
        this.setState({
            userName: value
        })
    }

    setPassword(value) {
        this.setState({
            password: value
        })
    }

    handleauthLevelChange(value) {
        this.setState({
            authLevel: value
        })
    }

    handleGroupsChange(value) {
        this.setState({
            groupId: value
        })
    }

    handleRegist() {
        if(this.state.userName==null){
            message.error("账号不能为空！");
            return;
        }
        if(this.state.password==null){
            message.error("密码不能为空！");
            return;
        }
        console.log("aaa");
        AccountManger.CreateAccount(this.state.userName, this.state.password, this.state.authLevel, this.state.groupId, () => {
            message.info("添加账号成功");
           this.setState({
                authLevel: "JuniorAdmin",
                userName: '',
                password: '',
                groupId: 0
           })
        }, (err) => {
            message.error(err)
        })
    }

    render() {
        let groupIdView = null;
        if (this.state.authLevel == "GroupAdmin") {
            groupIdView = (
                <Form.Item>
                    <Select
                        defaultValue='0'
                        size={"large"}
                        style={{ width: '50%' }}
                        onSelect={(key) => { this.handleGroupsChange(key) }}
                    >
                        {StorageValue.GroupIds.groupIds.map((item) => {
                            return (
                                <Option key={item} value={item}>{item}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            )
        }

        return (
            <div>
                <Form  style = {{textAlign:"center"}} onSubmit={() => { this.handleRegist() }}>
                    <h1 style = {{fontSize:"30px"}}>添加账号</h1>
                    <Form.Item>
                        <Input
                            placeholder="请输入用户名..."
                            style={{ width: '50%' }}
                            prefix={<UserOutlined />}
                            value={this.state.userName}
                            onChange={(e) => this.setAccount(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            style={{ width: '50%' }}
                            type="password"
                            placeholder="请输入密码..."
                            prefix={<EyeOutlined />}
                            value={this.state.password}
                            onChange={(e) => this.setPassword(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Select
                            defaultValue='JuniorAdmin'
                            size={"large"}
                            style={{ width: '50%' }}
                            onSelect={(key) => { this.handleauthLevelChange(key) }}
                        >
                            <Option value="JuniorAdmin">管理员</Option>
                            <Option value="GroupAdmin">渠道管理</Option>
                        </Select>
                    </Form.Item>
                    {groupIdView}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style = {{ width:'50%' }}>
                            添加
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}