import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select, Tooltip } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../../Utils/TimeUtil';
import NetUtil from '../../../Utils/NetUtil';
import StorageValue from '../../../Utils/StorageValue';
const { Option } = Select;
const { RangePicker } = DatePicker;

interface IProps {
}

interface IState {
    LogsList: Array<any>
    begin: string
    end: string
    channel: string
    platformId: string
}

/**
 * 付费留存
 *Created by liangzai on 19/09/12.
 */
export default class PayRetainedView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private now = new Date();
    private yesterday = subDays(this.now, 6)
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
            end: TimeUtil.Format(this.now, 'yyyy-MM-dd'),
            channel: '',
            platformId: ""
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
            channel: this.state.channel,
            platformId: this.state.platformId
        }
        NetUtil.Post(NetUtil.BaseUrl + "monitor/retentionmk", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                let data: Array<any> = [];
                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index].retention;
                    element.date = msg.data[index].date;
                    if (element.r0 && element.r0 != 0) {
                        element.r1 = (element.r1 * 100 / element.r0).toFixed(1);
                        element.r2 = (element.r2 * 100 / element.r0).toFixed(1);
                        element.r3 = (element.r3 * 100 / element.r0).toFixed(1);
                        element.r4 = (element.r4 * 100 / element.r0).toFixed(1);
                        element.r5 = (element.r5 * 100 / element.r0).toFixed(1);

                        element.r6 = (element.r6 * 100 / element.r0).toFixed(1);
                        element.r7 = (element.r7 * 100 / element.r0).toFixed(1);
                        element.r8 = (element.r8 * 100 / element.r0).toFixed(1);
                        element.r9 = (element.r9 * 100 / element.r0).toFixed(1);
                        element.r10 = (element.r10 * 100 / element.r0).toFixed(1);

                        element.r11 = (element.r11 * 100 / element.r0).toFixed(1);
                        element.r12 = (element.r12 * 100 / element.r0).toFixed(1);
                        element.r13 = (element.r13 * 100 / element.r0).toFixed(1);
                        element.r14 = (element.r14 * 100 / element.r0).toFixed(1);
                        element.r15 = (element.r15 * 100 / element.r0).toFixed(1);

                        element.r16 = (element.r16 * 100 / element.r0).toFixed(1);
                        element.r17 = (element.r17 * 100 / element.r0).toFixed(1);
                        element.r18 = (element.r18 * 100 / element.r0).toFixed(1);
                        element.r19 = (element.r19 * 100 / element.r0).toFixed(1);
                        element.r20 = (element.r20 * 100 / element.r0).toFixed(1);

                        element.r21 = (element.r21 * 100 / element.r0).toFixed(1);
                        element.r22 = (element.r22 * 100 / element.r0).toFixed(1);
                        element.r23 = (element.r23 * 100 / element.r0).toFixed(1);
                        element.r24 = (element.r24 * 100 / element.r0).toFixed(1);
                        element.r25 = (element.r25 * 100 / element.r0).toFixed(1);


                        element.r26 = (element.r26 * 100 / element.r0).toFixed(1);
                        element.r27 = (element.r27 * 100 / element.r0).toFixed(1);
                        element.r28 = (element.r28 * 100 / element.r0).toFixed(1);
                        element.r29 = (element.r29 * 100 / element.r0).toFixed(1);
                        element.r30 = (element.r30 * 100 / element.r0).toFixed(1);
                    }
                    else {

                        element.r1 = '0.0';
                        element.r2 = '0.0';
                        element.r3 = '0.0';
                        element.r4 = '0.0';
                        element.r5 = '0.0';

                        element.r6 = '0.0';
                        element.r7 = '0.0';
                        element.r8 = '0.0';
                        element.r9 = '0.0';
                        element.r10 = '0.0';

                        element.r11 = '0.0';
                        element.r12 = '0.0';
                        element.r13 = '0.0';
                        element.r14 = '0.0';
                        element.r15 = '0.0';

                        element.r16 = '0.0';
                        element.r17 = '0.0';
                        element.r18 = '0.0';
                        element.r19 = '0.0';
                        element.r20 = '0.0';

                        element.r21 = '0.0';
                        element.r22 = '0.0';
                        element.r23 = '0.0';
                        element.r24 = '0.0';
                        element.r25 = '0.0';

                        element.r26 = '0.0';
                        element.r27 = '0.0';
                        element.r28 = '0.0';
                        element.r29 = '0.0';
                        element.r30 = '0.0';

                    }
                    data.push(element)
                }

                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.date > b.date ? -1 : 1
                        })
                })
            },
            () => {

            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }

    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
                end: TimeUtil.Format(this.now, 'yyyy-MM-dd')
            })
            return;
        }
        let begindata = TimeUtil.MomentToTimeString(date[0]);
        let enddata = TimeUtil.MomentToTimeString(date[1]);

        this.setState({
            begin: begindata,
            end: enddata
        })
    }

    //设置搜索渠道
    private SetPlatformId(platformId: string) {
        this.setState({
            platformId: platformId
        })
    }
    //设置搜索渠道
    private SetChannel(channel: string) {
        this.setState({
            channel: channel
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "日期", dataIndex: "date", key: "date", width: "120px", fixed: 'left' },
            { align: "center", title: "新增注册", dataIndex: "r0", key: "r0" },
            { align: "center", title: "次留", dataIndex: "r1", key: "r1" },

            { align: "center", title: "2留", dataIndex: "r2", key: "r2" },
            { align: "center", title: "3留", dataIndex: "r3", key: "r3" },
            { align: "center", title: "4留", dataIndex: "r4", key: "r4" },
            { align: "center", title: "5留", dataIndex: "r5", key: "r5" },

            { align: "center", title: "6留", dataIndex: "r6", key: "r6" },
            { align: "center", title: "7留", dataIndex: "r7", key: "r7" },
            { align: "center", title: "8留", dataIndex: "r8", key: "r8" },
            { align: "center", title: "9留", dataIndex: "r9", key: "r9" },

            { align: "center", title: "10留", dataIndex: "r10", key: "r10" },

            { align: "center", title: "11留", dataIndex: "r11", key: "r11" },
            { align: "center", title: "12留", dataIndex: "r12", key: "r12" },
            { align: "center", title: "13留", dataIndex: "r13", key: "r13" },
            { align: "center", title: "14留", dataIndex: "r14", key: "r14" },

            { align: "center", title: "15留", dataIndex: "r15", key: "r15" },
            { align: "center", title: "16留", dataIndex: "r16", key: "r16" },
            { align: "center", title: "17留", dataIndex: "r17", key: "r17" },
            { align: "center", title: "18留", dataIndex: "r18", key: "r18" },

            { align: "center", title: "19留", dataIndex: "r19", key: "r19" },
            { align: "center", title: "20留", dataIndex: "r20", key: "r20" },
            { align: "center", title: "21留", dataIndex: "r21", key: "r21" },
            { align: "center", title: "22留", dataIndex: "r22", key: "r22" },

            { align: "center", title: "23留", dataIndex: "r23", key: "r23" },
            { align: "center", title: "24留", dataIndex: "r24", key: "r24" },
            { align: "center", title: "25留", dataIndex: "r25", key: "r25" },
            { align: "center", title: "26留", dataIndex: "r26", key: "r26" },

            { align: "center", title: "27留", dataIndex: "r27", key: "r27" },
            { align: "center", title: "28留", dataIndex: "r28", key: "r28" },
            { align: "center", title: "29留", dataIndex: "r29", key: "r29" },
            { align: "center", title: "30留", dataIndex: "r30", key: "r30" },
        ]
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 6))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>


                    <Form.Item label='来源' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetPlatformId(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            <Option key={"Android"} value={'Android'}>{"android"}</Option>
                            <Option key={"iOS"} value={'iOS'}>{"ios"}</Option>
                            <Option key={"Web"} value={'Web'}>{"web"}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label='渠道' >
                        <Select
                            style={{ width: "200Px" }}
                            defaultValue={''}
                            onChange={(value: string) => { this.SetChannel(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>
                <Divider />
                <Table
                    pagination={false}
                    scroll={{ y: 700 }}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}