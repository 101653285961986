import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Divider, Descriptions, message, Select, InputNumber, Input, Button } from 'antd';
import StorageValue from '../../Utils/StorageValue';
import NetUtil from '../../Utils/NetUtil';
const { Option } = Select;

interface IProps {

}

interface IState {
    OldId: string
    OldUserData: any,
    NewId: string,
    NewUserData: any,

}

/**
 * 账号绑定
 *Created by liangzai on 19/12/19.
 */
export default class AccountBindView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {

            OldId: '',
            NewId: '',
            OldUserData: null,
            NewUserData: null,

        }
    }
    //设置旧ID
    private setOldId(value: string) {
        this.setState({
            OldId: value
        })
    }
    //设置新id
    private setNewId(value: string) {
        this.setState({
            NewId: value,
        })
    }

    //账号换绑
    private Bindchange() {
        let postData = {
            token: StorageValue.Token,
            old: this.state.OldId,
            new: this.state.NewId
        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/bindchange", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.info('操作成功！')
            },
            (err: any) => {
                throw (err)
            })
    }

    //网络请求刷新页面
    private Bindfind() {
        let postData = {
            token: StorageValue.Token,
            old: this.state.OldId,
            new: this.state.NewId
        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/bindfind", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.setState({
                    NewUserData: msg.data.new,
                    OldUserData: msg.data.old
                })
            },
            (err: any) => {
                throw (err)
            })
    }

    render() {
        if (this.state.NewUserData == null || this.state.OldUserData == null) {

            return (
                <div>
                    < Form layout='inline' >

                        <Form.Item label='老ID'>
                            <Input
                                placeholder="请输入老ID..."
                                value={this.state.OldId}
                                onChange={(e) => this.setOldId(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item label='新ID'>
                            <Input
                                placeholder="请输入新ID..."
                                value={this.state.NewId}
                                onChange={(e) => this.setNewId(e.target.value)}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={() => { this.Bindfind() }} >
                                查询
                            </Button>
                        </Form.Item>

                    </Form>
                </div>
            )
        }
        return (
            <div>
                < Form layout='inline' >

                    <Form.Item label='老ID'>
                        <Input
                            placeholder="请输入老ID..."
                            value={this.state.OldId}
                            onChange={(e) => this.setOldId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label='新ID'>
                        <Input
                            placeholder="请输入新ID..."
                            value={this.state.NewId}
                            onChange={(e) => this.setNewId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={() => { this.Bindfind() }} >
                            查询
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={() => { this.Bindchange() }} >
                            换绑
                        </Button>
                    </Form.Item>
                </Form>


                <div style={{ width: '60%', float: "left" }}>
                    < Descriptions title={'老玩家'} bordered size='small' column={1}>
                        <Descriptions.Item label='玩家ID:'>
                            {this.state.OldUserData.playerId}


                        </Descriptions.Item>
                        <Descriptions.Item label='vip:'>
                            {this.state.OldUserData.vip}

                        </Descriptions.Item>

                        <Descriptions.Item label='渠道:'>
                            {this.state.OldUserData.packageId}

                        </Descriptions.Item>

                        <Descriptions.Item label='玩家名:'>
                            {this.state.OldUserData.nickName}
                        </Descriptions.Item>

                        <Descriptions.Item label='设备号:'>
                            {this.state.OldUserData.deviceId}
                        </Descriptions.Item>


                        <Descriptions.Item label='实名:'>
                            {this.state.OldUserData.realName}
                        </Descriptions.Item>

                        <Descriptions.Item label='身份证:'>
                            {this.state.OldUserData.idCard}
                        </Descriptions.Item>
                        <Descriptions.Item label='最近登录ip:'>
                            {this.state.OldUserData.ip}
                        </Descriptions.Item>

                        <Descriptions.Item label='设备信息:'>
                            {this.state.OldUserData.phoneInfo}
                        </Descriptions.Item>
                        {/* 
                        <Descriptions.Item>
                            <Form layout='inline'>
                                <Form.Item >
                                    <Button type="primary" onClick={() => { this.NetRefresh() }} >
                                        一键封禁/解封
                                    </Button>
                                </Form.Item>

                                <Form.Item >
                                    <Button type="primary" onClick={() => { this.NetRefresh() }} >
                                        全部保存
                                    </Button>
                                </Form.Item>
                                <Form.Item >
                                    <Button type="primary" onClick={() => { this.Bindfind() }} >
                                        踢下线
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Descriptions.Item> */}

                    </Descriptions>
                </div>

                <Divider type='vertical' />

                {/* 右边 */}
                <div style={{ width: '35%', float: "right" }}>
                    < Descriptions title={'新玩家'} bordered size='small' column={1}>
                        <Descriptions.Item label='玩家ID:'>
                            {this.state.NewUserData.playerId}


                        </Descriptions.Item>
                        <Descriptions.Item label='vip:'>
                            {this.state.NewUserData.vip}

                        </Descriptions.Item>

                        <Descriptions.Item label='渠道:'>
                            {this.state.NewUserData.packageId}

                        </Descriptions.Item>

                        <Descriptions.Item label='玩家名:'>
                            {this.state.NewUserData.nickName}
                        </Descriptions.Item>

                        <Descriptions.Item label='设备号:'>
                            {this.state.NewUserData.deviceId}
                        </Descriptions.Item>


                        <Descriptions.Item label='实名:'>
                            {this.state.NewUserData.realName}
                        </Descriptions.Item>

                        <Descriptions.Item label='身份证:'>
                            {this.state.NewUserData.idCard}
                        </Descriptions.Item>
                        <Descriptions.Item label='最近登录ip:'>
                            {this.state.NewUserData.ip}
                        </Descriptions.Item>

                        <Descriptions.Item label='设备信息:'>
                            {this.state.NewUserData.phoneInfo}
                        </Descriptions.Item>
                    </Descriptions>

                    {/* <div style={{}}>
                        <Button type="primary" onClick={() => { this.changevalue() }} >
                            确定修改
                        </Button>
                    </div> */}
                </div>
            </div >
        )
    }
}