import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Button, DatePicker, Select, Checkbox } from 'antd';
import { subDays } from 'date-fns'
import StorageValue from '../../Utils/StorageValue';
import NetUtil from '../../Utils/NetUtil';
import ExportExcel from '../../Utils/ExcelExport';

const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    channel: string
    platformId: string

}

/**
 * cpl消耗
 *Created by liangzai on 19/09/12.
 */
export default class UserTotalView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private now = new Date();
    private yesterday = subDays(this.now, 6)
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            channel: '',
            platformId: ""

        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            channel: this.state.channel,
            platformId: this.state.platformId
        }
        NetUtil.Post(NetUtil.BaseUrl + "analysis/usertotal", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                let data: Array<any> = [];
                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index];

                    data.push(element)
                }

                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.vip > b.vip ? 1 : -1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }

    //导出excel
    private export() {
        if (this.state.LogsList == null || this.state.LogsList.length < 1) {
            message.error('请先查询数据或导数据为空！')
            return;
        }

        ExportExcel.ExportCsv(this.columns, this.state.LogsList, '充值数据')

    }

    //设置搜索渠道
    private SetPlatformId(platformId: string) {
        this.setState({
            platformId: platformId
        })
    }

    //设置搜索渠道
    private SetChannel(channel: string) {
        this.setState({
            channel: channel
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "vip", dataIndex: "vip", key: "vip", width: "40px", fixed: 'left' },
            { align: "center", title: "人数", dataIndex: "num", key: "num" },
            { align: "center", title: "金币", dataIndex: "gold", key: "gold" },
            { align: "center", title: "福豆", dataIndex: "bean", key: "bean" },
            { align: "center", title: "内耗", dataIndex: "item", key: "item" },
            { align: "center", title: "兑换", dataIndex: "out", key: "out" },
            { align: "center", title: "100累计", dataIndex: "b100", key: "b100" },
            { align: "center", title: "1000累计", dataIndex: "b1000", key: "b1000" },
            { align: "center", title: "游戏产豆", dataIndex: "cbean", key: "cbean" },

            { align: "center", title: "1", dataIndex: "1", key: "1" },
            { align: "center", title: "2", dataIndex: "2", key: "2" },
            
            { align: "center", title: "3", dataIndex: "3", key: "3" },
            { align: "center", title: "4", dataIndex: "4", key: "4" },
            { align: "center", title: "5", dataIndex: "5", key: "5" },

            { align: "center", title: "6", dataIndex: "6", key: "6" },
            { align: "center", title: "7", dataIndex: "7", key: "7" },
            { align: "center", title: "8", dataIndex: "8", key: "8" },
            { align: "center", title: "9", dataIndex: "9", key: "9" },
            { align: "center", title: "10", dataIndex: "10", key: "10" },

            { align: "center", title: "11", dataIndex: "11", key: "11" },
            { align: "center", title: "12", dataIndex: "12", key: "12" },
            { align: "center", title: "13", dataIndex: "13", key: "13" },
            { align: "center", title: "14", dataIndex: "14", key: "14" },

            { align: "center", title: "15", dataIndex: "15", key: "15" },
            { align: "center", title: "16", dataIndex: "16", key: "16" },
            { align: "center", title: "17", dataIndex: "17", key: "17" },
            { align: "center", title: "18", dataIndex: "18", key: "18" },

            { align: "center", title: "19", dataIndex: "19", key: "19" },
            { align: "center", title: "20", dataIndex: "20", key: "20" },
            { align: "center", title: "21", dataIndex: "21", key: "21" },
            { align: "center", title: "22", dataIndex: "22", key: "22" },

            { align: "center", title: "23", dataIndex: "23", key: "23" },
            { align: "center", title: "24", dataIndex: "24", key: "24" },
            { align: "center", title: "25", dataIndex: "25", key: "25" },
            { align: "center", title: "26", dataIndex: "26", key: "26" },

            { align: "center", title: "27", dataIndex: "27", key: "27" },
            { align: "center", title: "28", dataIndex: "28", key: "28" },
            { align: "center", title: "29", dataIndex: "29", key: "29" },
            { align: "center", title: "30", dataIndex: "30", key: "30" },

            { align: "center", title: "31", dataIndex: "31", key: "31" },
            { align: "center", title: "32", dataIndex: "32", key: "32" },
            { align: "center", title: "33", dataIndex: "33", key: "33" },
            { align: "center", title: "34", dataIndex: "34", key: "34" },
            { align: "center", title: "35", dataIndex: "35", key: "35" },
            { align: "center", title: "36", dataIndex: "36", key: "36" },

        ]
        return (
            <div>
                <Form layout='inline'>
                   
                    <Form.Item label='来源' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetPlatformId(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            <Option key={"Android"} value={'Android'}>{"android"}</Option>
                            <Option key={"iOS"} value={'iOS'}>{"ios"}</Option>
                            <Option key={"Web"} value={'Web'}>{"web"}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label='渠道' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={''}
                            onChange={(value: string) => { this.SetChannel(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.NetRefreshPanel() }}
                        >
                            查询
                        </Button>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.export() }}
                        >
                            导出
                        </Button>
                    </Form.Item>

                </Form>
                <Divider type='horizontal' />

                <Table
                    pagination={false}
                    scroll={{ y: 700 }}

                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}