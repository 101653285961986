import React from 'react';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Select, Button, Modal } from 'antd';
import CostomMaliManager, { SystemMailData, CostomMailData } from '../CostomMaliManager';
const { Option } = Select;
export default class ChangeCostomMailData extends React.Component {
    constructor() {
        super();

        if (!CostomMaliManager.CurCostomMailData) {
            CostomMaliManager.CurCostomMailData = new CostomMailData();
        }
        this.state = {
            To: CostomMaliManager.CurCostomMailData.To ? CostomMaliManager.CurCostomMailData.To : [],
            ID: CostomMaliManager.CurCostomMailData.Identity ? CostomMaliManager.CurCostomMailData.Identity : "",
            sendMsg: CostomMaliManager.CurCostomMailData.SendMsg ? CostomMaliManager.CurCostomMailData.SendMsg : "",
            type: CostomMaliManager.CurCostomMailData.Type ? CostomMaliManager.CurCostomMailData.Type : "Users",
            title: CostomMaliManager.CurCostomMailData.Title ? CostomMaliManager.CurCostomMailData.Title : "",
            content: CostomMaliManager.CurCostomMailData.Content ? CostomMaliManager.CurCostomMailData.Content : "",
            from: CostomMaliManager.CurCostomMailData.From ? CostomMaliManager.CurCostomMailData.From : "",
            attach: (!CostomMaliManager.CurCostomMailData.Attachment || CostomMaliManager.CurCostomMailData.Attachment == "null") ? [] : JSON.parse(CostomMaliManager.CurCostomMailData.Attachment),
            acctchNum: 0,
            ItemInfo: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        this.NetRefresh()
        if (nextProps.formVisible) {
            this.setState({
                To: CostomMaliManager.CurCostomMailData.To ? CostomMaliManager.CurCostomMailData.To : [],
                ID: CostomMaliManager.CurCostomMailData.Identity ? CostomMaliManager.CurCostomMailData.Identity : "",
                sendMsg: CostomMaliManager.CurCostomMailData.SendMsg ? CostomMaliManager.CurCostomMailData.SendMsg : "",
                type: CostomMaliManager.CurCostomMailData.Type ? CostomMaliManager.CurCostomMailData.Type : "Users",
                title: CostomMaliManager.CurCostomMailData.Title ? CostomMaliManager.CurCostomMailData.Title : "",
                content: CostomMaliManager.CurCostomMailData.Content ? CostomMaliManager.CurCostomMailData.Content : "",
                from: CostomMaliManager.CurCostomMailData.From ? CostomMaliManager.CurCostomMailData.From : "",
                attach: (!CostomMaliManager.CurCostomMailData.Attachment || CostomMaliManager.CurCostomMailData.Attachment == "null") ? [] : JSON.parse(CostomMaliManager.CurCostomMailData.Attachment),
                acctchNum: 0,
                ItemInfo: [],
            })
        }
    }

    NetRefresh() {
        if (this.state.attach && this.state.attach.length > 0) {
            for (let index = 0; index < this.state.attach.length; index++) {
                this.setAttachID(index, this.state.attach[index].itemId)
                this.setAttachNumber(index, this.state.attach[index].itemCount)
            }
        }
        CostomMaliManager.GetItemInfo(
            (data) => {
                this.setState({
                    ItemInfo: data
                })
            },
            () => {
            }
        )

    }

    //类型
    setType(value) {
        this.setState({
            type: value
        })
    }
    //标题
    setTitle(value) {
        this.setState({
            title: value
        })
    }
    //ID邮件内容
    setcontent(value) {
        this.setState({
            content: value
        })
    }
    //发件人姓名
    setFrom(value) {
        this.setState({
            from: value
        })
    }
    //发送反馈
    setSendMsg(value) {
        console.log(value)
        this.setState({
            sendMsg: value
        })
    }
    //添加附件
    HandleAddAttach() {
        let data = {
            itemId: null,
            itemCount: null
        }
        this.state.acctchNum += 1;
        let attochData = [...this.state.attach];
        attochData.push(data);
        this.setState({
            attach: attochData
        })
    }
    //设置附件id
    setAttachID(index, value) {
        let attachData = [...this.state.attach];
        attachData[index].itemId = value;
        this.setState({
            attach: attachData
        })
    }
    //设置附件数量
    setAttachNumber(index, value) {
        let attachData = [...this.state.attach];
        attachData[index].itemCount = value;
        this.setState({
            attach: attachData
        })
    }
    //删除附件
    deleteAttach(index) {
        this.state.acctchNum -= 1;
        this.state.attach.splice(index, 1);
        let attochData = this.state.attach;
        this.setState({
            attach: attochData
        })
    }
    //接收人Id
    setTo(value) {
        if (value.indexOf('，') >= 0) {
            message.error('你输入有误，请重新输入！')
            return;
        }
        let newToArr = value.split(',');
        let NewTo = [...newToArr]
        console.log(NewTo)

        this.setState({
            To: NewTo
        })
    }

    //修改
    handleChangeMail() {
        CostomMaliManager.updateCostomMail(this.state.ID, this.state.type, this.state.title, this.state.content, this.state.from, this.state.To, this.state.attach, () => {
            message.info("修改邮件添加成功！");
            this.props.onCancel()
        },
            (err) => {
                message.info(err);
            })
    }

    render() {

        const formItemStle = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }
        return (
            <Modal
                okText='修改'
                cancelText='取消'
                width="60%"
                onCancel={() => { this.props.onCancel() }}
                onOk={() => { this.handleChangeMail() }}
                visible={this.props.formVisible}
            >
                <Form style={{ textAlign: "center", width: "100%" }}>
                    <h1 style={{ fontSize: "30px" }}>修改自定义邮件</h1>
                    <Form.Item label='Id' {...formItemStle} >
                        <Input
                            placeholder="1->无穷大"
                            prefix={<UserOutlined />}
                            value={this.state.ID}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='类型' {...formItemStle} >
                        <Select
                            defaultValue='Users'
                            size={"large"}
                            onSelect={(key) => { this.setType(key) }}
                        >
                            <Option value="Users">Users</Option>
                            <Option value="ALL">ALL</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='标题' {...formItemStle} >
                        <Input
                            placeholder="邮件标题"
                            prefix={<EyeOutlined />}
                            value={this.state.title}
                            onChange={(e) => this.setTitle(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='邮件内容' {...formItemStle} >
                        <Input

                            placeholder="邮件内容"
                            prefix={<EyeOutlined />}
                            value={this.state.content}
                            onChange={(e) => this.setcontent(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='发件人名字' {...formItemStle} >
                        <Input
                            placeholder="发件人名字"
                            prefix={<EyeOutlined />}
                            value={this.state.from}
                            onChange={(e) => this.setFrom(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='接收人ID' {...formItemStle} >
                        <Input
                            placeholder="接收人ID"
                            prefix={<EyeOutlined />}
                            value={this.state.To}
                            onChange={(e) => this.setTo(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    {/* 
                    <Form.Item>
                        <Input
                            style={{ width: '50%' }}
                            placeholder="创建时间"
                            prefix={<Icon type="eye" />}
                            value={CostomMaliManager.CurCostomMailData.CreateTime}
                            size="large"
                        />
                    </Form.Item> */}

                    {/* <Form.Item>
                        <Input
                            style={{ width: '50%' }}
                            placeholder="发送状态"
                            prefix={<Icon type="eye" />}
                            value={CostomMaliManager.CurCostomMailData.Status}
                            size="large"
                        />
                    </Form.Item> */}
                    {/* 
                    <Form.Item>
                        <Input
                            style={{ width: '50%' }}
                            placeholder="发送时间"
                            prefix={<Icon type="eye" />}
                            value={CostomMaliManager.CurCostomMailData.SendTime}
                            size="large"
                        />
                    </Form.Item> */}

                    <Form.Item label='发送反馈' {...formItemStle} >
                        <Input
                            placeholder="发送反馈"
                            prefix={<EyeOutlined />}
                            value={this.state.sendMsg}
                            onChange={(e) => this.setSendMsg(e.target.value)}
                            size="large"
                        />
                    </Form.Item>


                    {this.state.attach.map((item, index) => {
                        return (
                            <Form.Item key={index}>
                                <Select
                                    style={{ width: '40%', marginRight: "2%" }}
                                    size={"large"}
                                    value={this.state.attach[index].itemId}
                                    onChange={(value) => this.setAttachID(index, value)}
                                >
                                    <Option key={''} value={''}>{"请选择游戏道具"}</Option>
                                    {this.state.ItemInfo.map((item) => {
                                        return <Option key={item.identity} value={item.identity}>{item.name}</Option>
                                    })}

                                </Select>
                                <Input
                                    placeholder="道具数量"
                                    style={{ width: '40%', marginRight: "2%" }}
                                    value={this.state.attach[index].itemCount}
                                    onChange={(e) => this.setAttachNumber(index, e.target.value)}
                                    size="large"
                                />
                                <Button type="primary" onClick={() => { this.deleteAttach(index) }} style={{ width: '10%' }}>
                                    删除附件
                                </Button>
                            </Form.Item>
                        )
                    })}
                    <Form.Item>
                        <Button type="primary" onClick={() => { this.HandleAddAttach() }} style={{ width: '50%' }}>
                            添加附件
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}