import React from 'react'
import data from './data';
import { Link } from 'react-router-dom';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Menu } from 'antd';
import SliderStyle from './SliderBarView.module.css';
import logo from '../../assets/images/logo.svg';
import { EnumPageOption } from '../../UserInfo/EnumManager';
import Util from '../../Utils/Util';
const { SubMenu } = Menu;

export default class SliderBarView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Current: 'overview',

    }
  }

  setCollapsed(value) {
    this.props.Setcollapsed(value)
  }

  setCurrent(data) {
    this.setState({
      Current: data
    })
  }

  render() {
    return (
      <div className="ant-layout-sider-children">
        <div className={SliderStyle.logo}>
          <a href="/">
          </a>
        </div>
        <Menu
          theme="dark"
          onClick={(e) => this.setCurrent(e.key)}
          style={{ padding: '16px 0', width: '100%' }}
          defaultOpenKeys={['overview', 'sub-res', 'sub-other']}
          selectedKeys={[this.state.Current]}
          mode="inline"
          inlineCollapsed={this.props.collapsed}
        >
          {
            data.map((item) => {
              //case 多级结构
              if (item.children instanceof Array) {
                if (Util.LackPP(item.ID, EnumPageOption.find)) {
                  return null;
                }

                return (
                  <SubMenu key={item.key}
                    title={
                      <div style={{ fontSize: '15px' }}>
                        <LegacyIcon style={{ fontSize: '15px' }} type={item.icon} />
                        <b>
                          {item.label}
                        </b>
                      </div>
                    }
                  >
                    {
                      item.children.map((subItem) => {
                        if (Util.LackPP(subItem.ID, EnumPageOption.find)) {
                          return null;
                        }
                        return (
                          <Menu.Item key={subItem.key}>
                            <Link to={subItem.url}>{subItem.label}</Link>
                          </Menu.Item>
                        )
                      })
                    }
                  </SubMenu>
                );
              }
              //单机结构目前没有  直接注释
              //case 单级结构
              // return (
              //   <Menu.Item key={item.key}>
              //     <Link to={item.url}>
              //       <div style={{ fontSize: '15px' }}>
              //         <Icon style={{ fontSize: '15px' }} type={item.icon} />
              //         <b>
              //           {item.label}
              //         </b>
              //       </div>
              //     </Link>
              //   </Menu.Item>
              // )
            })
          }
        </Menu>
      </div>
    );
  }
}