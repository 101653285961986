import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Button, DatePicker, Select } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../../Utils/TimeUtil';
import NetUtil from '../../../Utils/NetUtil';
import StorageValue from '../../../Utils/StorageValue';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: any
    begin: any
    end: any
}

/**
 * 福豆变动
 *Created by liangzai on 19/09/12.
 */
export default class GroupCostView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();

    private defaultData = 3;
    private now = new Date();
    private yesterday = subDays(this.now, this.defaultData)

    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
            end: TimeUtil.Format(this.now, 'yyyy-MM-dd'),
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        console.log(this.now, this.yesterday)

        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
        }
        NetUtil.Post(NetUtil.BaseUrl + "analysis/beanChange", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                let data: Array<any> = [];
                for (let index = 0; index < msg.data.length; index++) {
                    let element = msg.data[index];
                    element.total = -element.v0;
                    element.mail = 0;
                    element.lottery = 0;
                    element.fl = 0;
                    element.task = 0;
                    element.exchange = 0;
                    element.rank = 0;
                    element.rand = 0;
                    element.box = 0;

                    if (element.CoinLottery_3 ) { element.total += element.CoinLottery_3; element.mail += element.CoinLottery_3}
                    if (element.CoinLottery_4 ) { element.total += element.CoinLottery_4; element.mail += element.CoinLottery_4}
                    if (element.CoinLottery_103 ) { element.total += element.CoinLottery_103; element.lottery += element.CoinLottery_103}
                    if (element.CoinLottery_104 ) { element.total += element.CoinLottery_104; element.lottery += element.CoinLottery_104}
                    if (element.CoinLottery_105 ) { element.total += element.CoinLottery_105; element.lottery += element.CoinLottery_105}
                    if (element.CoinLottery_102 ) { element.total += element.CoinLottery_102; element.fl += element.CoinLottery_102}
                    if (element.CoinLottery_8 ) { element.total += element.CoinLottery_8; element.task += element.CoinLottery_8}
                    if (element.CoinLottery_101 ) { element.total += element.CoinLottery_101; element.exchange += element.CoinLottery_101}
                    if (element.CoinLottery_18 ) { element.total += element.CoinLottery_18; element.rank += element.CoinLottery_18}
                    if (element.CoinLottery_17 ) { element.total += element.CoinLottery_17; element.rand += element.CoinLottery_17}
                    if (element.CoinLottery_1000 ) { element.total += element.CoinLottery_1000; element.mail += element.CoinLottery_1000}

                    data.push(element)
                }

                this.setState({
                    LogsList: data
                })

            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }

    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
                end: TimeUtil.Format(this.now, 'yyyy-MM-dd')
            })
            return;
        }
        let begindata = TimeUtil.MomentToTimeString(date[0]);
        let enddata = TimeUtil.MomentToTimeString(date[1]);

        this.setState({
            begin: begindata,
            end: enddata
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "日期", dataIndex: "date", key: "date" },
            { align: "center", title: "库存", dataIndex: "total", key: "total" },
            { align: "center", title: "V0", dataIndex: "v0", key: "v0" },
            { align: "center", title: "V1", dataIndex: "v1", key: "v1" },
            { align: "center", title: "邮件", dataIndex: "mail", key: "mail" },
            { align: "center", title: "手动", dataIndex: "lottery", key: "lottery" },
            { align: "center", title: "福利", dataIndex: "fl", key: "fl" },
            { align: "center", title: "任务", dataIndex: "task", key: "task" },
            { align: "center", title: "内耗", dataIndex: "exchange", key: "exchange" },
            { align: "center", title: "排行", dataIndex: "rank", key: "rank" },
            { align: "center", title: "转盘", dataIndex: "rand", key: "rand" },
            { align: "center", title: "宝箱", dataIndex: "box", key: "box" },
        ]
        return (
            <div>
                <Form layout='inline'>

                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), this.defaultData))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>

                <Table
                    size={"small"}
                    pagination={false}
                    indentSize={30}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey='channel'
                />
            </div>
        );
    }
}