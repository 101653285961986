import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select, TreeSelect, Checkbox } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import NetUtil from '../../Utils/NetUtil';
import { ShopItem } from "../../Utils/TableShopItem";
import StorageValue from '../../Utils/StorageValue';
import LoaderView from '../../components/Loader/LoaderView';
import EnumUtil from '../../Utils/EnumUtil';
import ExportExcel from '../../Utils/ExcelExport';
const { RangePicker } = DatePicker;
const { Option } = Select;
interface IProps {

}

interface IState {
    LogsList: Array<any>
    begin: any
    end: any
    userId: string
    channel: number
    payType: string
    payChannel: string
    payId: string
    success: boolean

}

/**
 * 充值日志
 *Created by liangzai on 19/09/12.
 */
export default class RechargeLogView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private nowTime: number = TimeUtil.GetDayBegin(new Date());
    private yesterday = TimeUtil.GetDayBegin(subDays(this.nowTime, 6));
    private PayData: { PayChannel: any, PayType: any } = { PayChannel: null, PayType: null };

    private PayId: any = [{
        title: '全部',
        value: '0',
        key: '0',
        children: []
    }];

    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: this.yesterday,
            end: this.nowTime,
            userId: '',
            channel: 0,
            payType: '',
            payChannel: '',
            payId: '0',
            success: true
        }

        ShopItem.forEach(item => {
            this.PayId[0].children.push({
                title: item.name,
                value: item.identity,
                key: item.identity,
                children: []
            })
        })
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
            userId: this.state.userId,
            channel: this.state.channel,
            payType: this.state.payType,
            payChannel: this.state.payChannel,
            rechargeId: this.state.payId,
            success: this.state.success
        }
        NetUtil.Post(NetUtil.BaseUrl + "log/recharge", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                this.setState({
                    LogsList: msg.data.sort((a: any, b: any) => {
                        return a.createTime > b.createTime ? -1 : 1
                    })
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.GetPayChannelData();
        this.NetRefreshPanel();
    }

    //获取充值渠道数据
    private GetPayChannelData() {
        let postData = {
            token: StorageValue.Token,
        }
        NetUtil.Post(NetUtil.BaseUrl + "log/rt", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                let PayChannel: any = {}
                let PayType: any = {}

                PayChannel.title = '所有通道';
                PayChannel.value = '';
                PayChannel.key = '';
                PayChannel.children = [];

                PayType.title = '所有类型';
                PayType.value = '';
                PayType.key = '';
                PayType.children = [];

                for (let index = 0; index < msg.data.type.length; index++) {
                    let data: any = {}
                    const element = msg.data.type[index];
                    data.title = element;
                    data.value = element;
                    data.key = element;
                    PayType.children.push(data)
                }

                for (let index = 0; index < msg.data.channel.length; index++) {
                    let data: any = {}
                    const element = msg.data.channel[index];
                    data.title = element;
                    data.value = element;
                    data.key = element;
                    PayChannel.children.push(data)
                }

                let Type = [];
                Type.push(PayType)
                let Channel = [];
                Channel.push(PayChannel)

                this.PayData.PayChannel = Channel;
                this.PayData.PayType = Type;

            },
            (err: any) => {
                throw (err)
            })
    }

    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: this.yesterday,
                end: this.nowTime,
            })
            return;
        }
        let begindata = moment(date[0]).valueOf();
        let enddata = moment(date[1]).valueOf();

        this.setState({
            begin: begindata,
            end: enddata
        })
    }
    //设置玩家id
    private SetPlayerId(PlayerId: string) {
        this.setState({
            userId: PlayerId
        })
    }
    //设置会长id
    private SetChannel(InviteId: number) {
        this.setState({
            channel: InviteId
        })
    }
    //设置充值渠道
    private SetPayChannel(channel: string) {
        let data = '';
        for (let index = 0; index < channel.length; index++) {
            const element = channel[index];
            if (index == channel.length - 1) {
                data = data + element
            }
            else {
                data = data + element + ','
            }
        }
        this.setState({
            payChannel: data
        })
    }

    //是否记住密码
    private setIsFittle(value: boolean) {
        this.setState({
            success: value
        })
    }

    //设置游戏类型
    private SetPayType(type: string) {
        let data = '';
        for (let index = 0; index < type.length; index++) {
            const element = type[index];
            if (index == type.length - 1) {
                data = data + element
            }
            else {
                data = data + element + ','
            }
        }
        this.setState({
            payType: data
        })
    }

    //导出excel
    private export() {
        if (this.state.LogsList == null || this.state.LogsList.length < 1) {
            message.error('请先查询数据或导数据为空！')
            return;
        }
        // ExportExcel.exportExcel([{ headers: this.columns, data: this.state.LogsList, sheetName: "充值数据" }], "充值数据")

        ExportExcel.ExportCsv(this.columns, this.state.LogsList, '充值数据')

    }

    //设置游戏类型
    private SetPayId(type: string) {
        let data = '';
        for (let index = 0; index < type.length; index++) {
            const element = type[index];
            if (index == type.length - 1) {
                data = data + element
            }
            else {
                data = data + element + ','
            }
        }
        this.setState({
            payId: data
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "玩家ID", dataIndex: "userId", key: "userId" },
            {
                align: "center", title: "充值时间", dataIndex: "createTime", key: "createTime", render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text, 'yyyy-MM-dd hh:mm:ss')}</div>
                }
            },
            {
                align: "center", title: "充值ID", dataIndex: "rechargeId", key: "rechargeId", render: (text, record) => {
                    return <div>{EnumUtil.GetPayNameByPayId(text)}</div>
                }
            },
            {
                align: "center", title: "充值金额", dataIndex: "amount", key: "amount", render: (text, record) => {
                    return <div>{text / 100}</div>
                }
            },
            // { align: "center", title: "充值方式", dataIndex: "payType", key: "payType" },
            // { align: "center", title: "充值通道", dataIndex: "payChannel", key: "payChannel" },
            {
                align: "center", title: "到账时间", dataIndex: "tradeTime", key: "tradeTime", render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text, 'yyyy-MM-dd hh:mm:ss')}</div>
                }
            },
            // { align: "center", title: "会长ID", dataIndex: "channel", key: "channel" },
        ]

        if (this.PayData == null) {
            // message.error('获取充值渠道数据错误！');
            return (<LoaderView />);
        }
        return (
            <div>
                <Form layout='inline'>

                    <Form.Item label='玩家ID'>
                        <Input
                            placeholder="请输入玩家ID..."
                            value={this.state.userId}
                            onChange={(e) => this.SetPlayerId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 7))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='充值Id'>
                        <TreeSelect
                            style={{ width: "200px" }}
                            treeData={this.PayId}
                            treeCheckable={true}
                            defaultValue={['0']}
                            maxTagCount={3}
                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                            onChange={(value: any) => { this.SetPayId(value) }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Checkbox
                            onChange={(e) => {
                                console.log(e)
                                this.setIsFittle(e.target.checked)
                            }}
                            checked={
                                this.state.success
                            }
                        >过滤
                        </Checkbox>
                    </Form.Item>

                    {/* <Form.Item label='会长ID'>
                        <Select
                            defaultValue={0}
                            onChange={(value: number) => { this.SetChannel(value) }}
                        >
                            <Option key={"0"} value={0}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item> */}

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.export() }}
                        >
                            导出
                        </Button>
                    </Form.Item>


                </Form>
                <Divider type='horizontal' />
                <Table
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}