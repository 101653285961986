import md5 from  "md5"

export default class UserInfo{
    public static Account:string;       //账号
    public static Password:string;      //密码
    public static Token:string; 
    public static RandSalt:string;
    public static StaticSalt:string;
    public static googleCode:string;    //谷歌验证码
    public static Name:string;          //姓名 

    public static get md5Password():string{
        return md5(md5(this.Password + this.StaticSalt) + this.RandSalt)
    }
    public static Level:string          //权限等级
}