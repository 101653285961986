import React from 'react';
import { Button, message, Upload } from 'antd';
import NetUtil from '../../../Utils/NetUtil';
import StorageValue from '../../../Utils/StorageValue';

export default class ServerConfigView extends React.Component {

    constructor() {
        super();
        this.state = {
            data: null,
            name: ''
        }
    }

    //上传
    handleUpload() {
        let data = this.state.data;
        if (typeof (data) == 'json') {
            data = JSON.stringify(data)
        }
        NetUtil.Post(NetUtil.BaseUrl + "api/cfg",
            {
                token: StorageValue.Token,
                Name: this.state.name,
                Data: data
            },
            (msg) => {
                let result = msg.result;
                if (result != 'OK') {
                    return;
                }
                message.info('上传成功！')
            },
            (err) => {
                return;
            })
    }

    render() {
        // 日期选择框
        return (
            <Upload
                showUploadList={false}
                multiple={false}
                beforeUpload={(file, FileList) => {
                    let data = new FileReader();
                    data.readAsText(file);
                    data.onload = () => {
                        this.setState({
                            data: data.result,
                            name: file.name.substring(0, file.name.indexOf('.'))
                        })
                    }
                }}
                onChange={(info) => {
                    console.log(info)
                    if (info.file.status === 'error' || info.file.status === 'done') {
                        this.handleUpload()
                    }
                }}
            >
                <Button type="primary" onClick={() => {
                    this.setState({
                        formVisible: true
                    })
                }} >
                    上传文件
                </Button>
            </Upload  >

        )
    }
}