import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Button, DatePicker, Select } from 'antd';
import TimeUtil from '../../../Utils/TimeUtil';
import NetUtil from '../../../Utils/NetUtil';
import StorageValue from '../../../Utils/StorageValue';
import moment from 'moment';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Legend,
} from 'bizcharts';
const { Option } = Select;

interface IProps {

}

interface IState {
    items: Array<any>
    date: string
    channel: string
    platformId: string
}

/**
 * 在线人数
 *Created by liangzai on 19/09/12.
 */
export default class OnlineNumView extends React.Component<IProps, IState> {
    private now = new Date();
    constructor(props: IProps) {
        super(props);
        this.state = {
            items: [],
            date: TimeUtil.Format(this.now, 'yyyy-MM-dd hh:mm:ss'),
            channel: "",
            platformId: ""
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            date: this.state.date,
            channel: this.state.channel,
            platformId: this.state.platformId

        }
        NetUtil.Post(NetUtil.BaseUrl + "monitor/online", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                let data: Array<any> = [];
                for (let k = 0; k < msg.data.length; k++) {
                    console.log(k + '>>>>>>>>>>>>>>>>>>>>>>>>')
                    const element = msg.data[k];
                    if (element.min) {
                        for (let i = 0; i < element.min.length; i++) {
                            const list = element.min[i];
                            list.hour = 'hour' + element.hour;
                            data.push(list)
                        }
                    }
                }

                console.log(JSON.stringify(data))

                this.setState({
                    items: data
                })
            },
            () => {

            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }

    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date == null || date == undefined) {
            this.setState({
                date: TimeUtil.Format(this.now, 'yyyy-MM-dd HH:mm:ss')
            })
            return
        }
        let begindata = date.format("YYYY-MM-DD HH:mm:ss");
        this.setState({
            date: begindata,
        })
    }
    //设置搜索渠道
    private SetPlatformId(platformId: string) {
        this.setState({
            platformId: platformId
        })
    }

    //设置搜索渠道
    private SetChannel(channel: string) {
        this.setState({
            channel: channel
        })
    }


    render() {

        const cols = {
            month: {
                range: [0, 1],
            },
        };


        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label='日期选择' >
                        <DatePicker
                            showTime
                            value={moment(this.state.date)}
                            format='YYYY-MM-DD HH:mm:ss'
                            onChange={(value: any) => { this.OnTimeChange(value) }}
                        />
                    </Form.Item>

                    <Form.Item label='来源' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetPlatformId(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            <Option key={"Android"} value={'Android'}>{"android"}</Option>
                            <Option key={"iOS"} value={'iOS'}>{"ios"}</Option>
                            <Option key={"Web"} value={'Web'}>{"web"}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label='渠道' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetChannel(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>
                <Divider />


                <Chart height={400} data={this.state.items} scale={cols} padding="auto" forceFit>
                    <Legend />
                    <Axis name="key" />
                    <Axis
                        name="value"
                        label={{
                            formatter: (text) => { return text }
                        }}
                    />
                    <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    />
                    <Geom
                        type="line"
                        position="key*value"
                        size={2}
                        // color={['hour', ['#2443ff', '#0bfe12']]}
                        color={"hour"}
                        shape={"smooth"}
                    />

                    <Geom
                        type="point"
                        position="key*value"
                        size={2}
                        shape={'circle'}
                        color={'hour'}
                        style={{
                            stroke: '#fff',
                            lineWidth: 1,
                        }}
                    ></Geom>
                </Chart>
            </div >
        );
    }
}