import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select, TreeSelect } from 'antd';
import moment, { now, relativeTimeThreshold } from 'moment';
import StorageValue from '../../../Utils/StorageValue';
import NetUtil from '../../../Utils/NetUtil';
import LoaderView from '../../../components/Loader/LoaderView';
import ExportExcel from '../../../Utils/ExcelExport';
import { GameTypeData } from '../../../Utils/DefaultValue';
import TimeUtil from '../../../Utils/TimeUtil';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: any
    begin: number
    end: number
    playerId: string
    inviteId: number
    fishType: string
    platformId: string
}

/**
 * 玩家数据
 *Created by liangzai on 19/09/12.
 */
export default class PlayerDataView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private end: number = (new Date()).getTime();
    private begin: number = this.end - 7200000;
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: null,
            begin: this.begin,
            end: this.end,
            playerId: '',
            inviteId: 0,
            fishType: '4,5,6',
            platformId: ""
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
            userId: this.state.playerId,
            channel: this.state.inviteId,
            type: this.state.fishType,
            platformId: this.state.platformId
        }
        NetUtil.Post(NetUtil.BaseUrl + "analysis/userinfo", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                let data: Array<any> = [];
                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index];
                    // element.winlose = element.win + element.lose;

                    if (element) {
                        if (element.winRate)
                            element.winRate = (element.winRate * 100).toFixed(2) + "%";
                        else
                            element.winRate = "0";
                        data.push(element);
                    }
                }

                this.setState({
                    LogsList: data
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //导出excel
    private export() {
        if (this.state.LogsList == null || this.state.LogsList.length < 1) {
            message.error('请先查询数据或导数据为空！')
            return;
        }
        // ExportExcel.exportExcel([{ headers: this.columns, data: this.state.LogsList, sheetName: "玩家数据" }], "玩家数据")

        ExportExcel.ExportCsv(this.columns, this.state.LogsList, '玩家数据')

    }

    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.setState({
            LogsList: null
        })

        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: this.begin,
                end: this.end,
            })

            return;
        }
        let begindata = moment(date[0]).valueOf();
        let enddata = moment(date[1]).valueOf();

        this.setState({
            begin: begindata,
            end: enddata
        })
    }
    //设置玩家id
    private SetPlayerId(PlayerId: string) {
        this.setState({
            playerId: PlayerId
        })
    }
    //设置所属工会
    private SetChannel(InviteId: number) {
        this.setState({
            inviteId: InviteId
        })
    }

    //设置搜索渠道
    private SetPlatformId(platformId: string) {
        this.setState({
            platformId: platformId
        })
    }

    //设置捕鱼场次
    private SetFishType(type: string) {
        let data = '';
        for (let index = 0; index < type.length; index++) {
            const element = type[index];
            if (index == type.length - 1) {
                data = data + element
            }
            else {
                data = data + element + ','
            }
        }
        this.setState({
            fishType: data
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "玩家ID", dataIndex: "playerId", key: "playerId" },
            { align: "center", title: "渠道", dataIndex: "packageId", key: "packageId" },
            {
                align: "center", title: "注册时间", dataIndex: "createTime", key: "createTime", render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text, 'yyyy-MM-dd hh:mm:ss')}</div>
                }
            },
            {
                align: "center", title: "盈利", dataIndex: "win", key: "win",
                sorter: (a, b) => a.win - b.win
            },
            {
                align: "center", title: "兑出", dataIndex: "lotteryOut", key: "lotteryOut",
                sorter: (a, b) => a.lose - b.lose
            },
            {
                align: "center", title: "金币", dataIndex: "gold", key: "gold",
                sorter: (a, b) => a.gold - b.gold
            },
            {
                align: "center", title: "福豆", dataIndex: "lottery", key: "lottery",
                sorter: (a, b) => a.lottery - b.lottery
            },
            {
                align: "center", title: "当期福豆", dataIndex: "clottery", key: "clottery",
                sorter: (a, b) => a.clottery - b.clottery
            },
            {
                align: "center", title: "赢取福豆", dataIndex: "wlottery", key: "wlottery",
                sorter: (a, b) => a.wlottery - b.wlottery
            },
            {
                align: "center", title: "累计福豆", dataIndex: "tlottery", key: "tlottery",
                sorter: (a, b) => a.tlottery - b.tlottery
            },
            {
                align: "center", title: "当期充值", dataIndex: "cmount", key: "cmount",
                sorter: (a, b) => a.cmount - b.cmount
            },
            {
                align: "center", title: "充值", dataIndex: "amount", key: "amount",
                sorter: (a, b) => a.amount - b.amount
            },
        ]

        let tableView = <LoaderView />;

        if (this.state.LogsList != null) {
            tableView =
                <Table
                    pagination={false}
                    scroll={{ x: 1000, y: 700 }}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
        }


        return (
            <div>
                <Form layout='inline'>

                    <Form.Item label='玩家ID'>
                        <Input
                            placeholder="请输入玩家ID..."
                            value={this.state.playerId}
                            onChange={(e) => this.SetPlayerId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label='日期选择' >
                        <RangePicker
                            showTime={true}
                            defaultValue={[moment(this.begin), moment(this.end)]}
                            format='YYYY-MM-DD HH:mm:ss'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='来源' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetPlatformId(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            <Option key={"Android"} value={'Android'}>{"android"}</Option>
                            <Option key={"iOS"} value={'iOS'}>{"ios"}</Option>
                            <Option key={"Web"} value={'Web'}>{"web"}</Option>
                        </Select>
                    </Form.Item>

                    {/* <Form.Item label='所属工会'>
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={0}
                            onChange={(value: number) => { this.SetChannel(value) }}
                        >
                            <Option key={"0"} value={0}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item> */}

                    {/* <Form.Item label='游戏类型'>
                        <TreeSelect
                            treeData={GameTypeData}
                            style={{ width: "200px" }}
                            treeCheckable={true}
                            maxTagCount={3}
                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                            onChange={(value: any) => { this.SetFishType(value) }}
                        />
                    </Form.Item> */}

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>


                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.export() }}
                        >
                            导出
                        </Button>
                    </Form.Item>
                </Form>
                <Divider type='horizontal' />

                {tableView}

            </div>
        );
    }
}