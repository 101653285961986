import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, message, Button, DatePicker, Alert, Input } from 'antd';
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import SendRecordsManager from './SendRecordsManager';

export default class SendRecordsView extends React.Component {

    constructor() {
        super();
        this.state = {
            startValue: null,
            endValue: null,
            endOpen: false,
            tableData: null,
            sendId: null,
            recvID: null,
            allCount:0
        }
    }

    OnButtonSerchClick() {
        if (this.state.startValue == null) {
            message.error("请先输入开始日期")
            return
        }

        if (this.state.endValue == null) {
            message.error("请先输入结束日期")
            return
        }

        SendRecordsManager.GetSendRecordsData(TimeUtil.MomentToTimeString(this.state.startValue),
            TimeUtil.MomentToTimeString(this.state.endValue), this.state.sendId, this.state.recvID,
            () => {
                this.setState({
                    tableData: SendRecordsManager.allSendrecordsData,
                    allCount:SendRecordsManager.allCount
                })
            },
            (err) => {
                message.error(err)
            });
    }

    disabledStartDate(startValue) {
        if (startValue == null) {
            return false;
        }
        if (startValue.valueOf() >= moment.now()) {
            return true;
        }
        if (this.state.endValue == null) {
            return false;
        }
        return startValue.valueOf() > this.state.endValue.valueOf();
    };

    disabledEndDate(endValue) {
        if (endValue == null) {
            return false;
        }
        if (endValue.valueOf() >= moment.now()) {
            return true;
        }
        if (this.state.startValue == null) {
            return false;
        }
        return endValue.valueOf() <= this.state.startValue.valueOf();
    };

    onChange(field, value) {
        this.setState({
            [field]: value,
        });
    };

    onStartChange(value) {
        this.onChange('startValue', value);
    };

    onEndChange(value) {
        this.onChange('endValue', value);
    };

    handleStartOpenChange(open) {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange(open) {
        this.setState({ endOpen: open });
    };

    setRecvId(value) {
        this.setState({
            recvID: value
        })
    }

    setSendId(value) {
        this.setState({
            sendId: value
        })

    }

    render() {
        // 日期选择框
        let dateSelectView = (
            <Form layout="inline">
                <Form.Item>
                    {"开始日期："}
                    <DatePicker
                        disabledDate={(current) => (this.disabledStartDate(current))}
                        showTime
                        format="YYYY-MM-DD"
                        value={this.state.startValue}
                        placeholder="Start"
                        onChange={(value) => this.onStartChange(value)}
                        onOpenChange={(value) => this.handleStartOpenChange(value)}
                    />
                </Form.Item>
                <Form.Item>
                    {"结束日期："}
                    <DatePicker
                        disabledDate={(current) => this.disabledEndDate(current)}
                        showTime
                        format="YYYY-MM-DD"
                        value={this.state.endValue}
                        placeholder="End"
                        onChange={(value) => this.onEndChange(value)}
                        open={this.state.endOpen}
                        onOpenChange={(value) => this.handleEndOpenChange(value)}
                    />
                </Form.Item>

                <Form.Item>
                    <Input
                        placeholder="请输入赠送着ID..."
                        value={this.state.sendId}
                        onChange={(e) => this.setSendId(e.target.value)}
                    />

                    <Input
                        width = {10}
                        placeholder="请输入接收者ID..."
                        value={this.state.recvID}
                        onChange={(e) => this.setRecvId(e.target.value)}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" onClick={() => { this.OnButtonSerchClick() }} >
                        查询
                    </Button>
                </Form.Item>
            </Form >
        )

        this.columns = [
            {
                align: "center",
                dataIndex: "orderId",
                key: "orderId",
                title: "订单号",
            },
            {
                align: "center",
                title: " 赠送者ID",
                dataIndex: "send",
                key: "send",
            },
            {
                align: "center",
                title: "接收者ID",
                dataIndex: "recv",
                key: "recv",
            },
            {
                align: "center",
                title: "时间",
                dataIndex: "time",
                key: "time",
            },
            {
                align: "center",
                title: "数量",
                dataIndex: "count",
                key: "count",
            },
            {
                align: "center",
                title: "是否完成",
                dataIndex: "finish",
                key: "finish",
            },
        ]

        let tableView = (
            <div>
                <Alert
                    message="【赠送记录】查询"
                    description="赠送记录查询，开始时间和结束时间为必选,赠送者和接收者ID可不选！！！" 
                />
            </div>
        )

        if (this.state.tableData != null) {
           tableView = (
                <div>
                    <Table
                        rowKey={record => record.id}
                        size="small"  //default | middle | small
                        pagination={true}
                        columns={this.columns}
                        bordered={true}
                        dataSource={this.state.tableData}
                        onChange={this.handleChange}
                        title={() => {
                            return "累计赠送数量 ： " + this.state.allCount;
                        }}
                        footer={() => {
                            return '* product by zhjd '
                        }}
                        scroll={{ x: 1000 }}
                    ></Table>
                </div>
            )
        }

        return (
            <div>
                {dateSelectView}
                <br />
                {tableView}
            </div>
        )
    }
}