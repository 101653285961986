import React from 'react';
import { Table,message } from 'antd';
import GroupOnlingDataManager from './GroupOnlingDataManager';

export default class GroupOnlineDataView extends React.Component {

    constructor() {
        super();
        this.state = {
            tableData: null,
            allGroupIdKeys: null,
            filteredInfo: null,
            sortedInfo: null,
        }
    }

    componentWillMount() {
        GroupOnlingDataManager.InitOnlineData(() => {
            this.setState({
                tableData:GroupOnlingDataManager.allgroupOnlineData,
                allGroupIdKeys:GroupOnlingDataManager.allGroupIdKeys
            })
        }, (err) => {
            message.error(err)
        });
    }

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', filters,sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    render() {
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        this.columns = [
            {
                align: "center",
                dataIndex: "type",
                key: "type",
                title: "场景类型",    
            },
            {
                align: "center",
                title: "渠道ID",
                dataIndex: "groupid",
                key: "groupid",
                filters: this.state.allGroupIdKeys,
                filteredValue: filteredInfo.groupid || null,
                onFilter: (value, record) => record.groupid == value,
                sorter: (a, b) => a.groupid-b.groupid,
                // sortOrder: sortedInfo.columnKey === 'groupid' && sortedInfo.order,
                defaultSortOrder: 'ascend'
            },
            {
                align: "center",
                title: "在线人数",
                dataIndex: "onlineNnum",
                key: "onlineNnum",
            },
        ]
           
        return (
            <div>
                <Table
                    pagination={true}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.tableData}
                    onChange={this.handleChange}
                    title={() => {
                        return "在线数据："
                    }}
                    footer={() => {
                        return '/* product by zhjd'
                    }}
                    scroll={{ x: 1000 }}
                ></Table>
            </div>
        )
    }
}