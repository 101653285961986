import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Button, DatePicker, Select, TreeSelect } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../../Utils/TimeUtil';
import NetUtil from '../../../Utils/NetUtil';
import StorageValue from '../../../Utils/StorageValue';
import { GameTypeData } from '../../../Utils/DefaultValue';
import LoaderView from '../../../components/Loader/LoaderView';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: any
    begin: any
    end: any
    channel: string
    type: string

}

/**
 * 游戏参与
 *Created by liangzai on 19/09/12.
 */
export default class GameJoinView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private now = new Date();
    private yesterday = subDays(this.now, 3)
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: null,
            begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
            end: TimeUtil.Format(this.now, 'yyyy-MM-dd'),
            channel: '',
            type: '0',

        }
    }

    //刷新界面
    private NetRefreshPanel() {
        this.setState({
            LogsList: null
        })

        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
            channel: this.state.channel,
            type: this.state.type,
        }
        NetUtil.Post(NetUtil.BaseUrl + "monitor/play", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                let data: Array<any> = [];
                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index].hour;
                    if (!element) {
                        return;
                    }
                    element.date = msg.data[index].date;
                    element.total = msg.data[index].hour.total;
                    data.push(element)
                }

                // console.log(data)
                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.date > b.date ? -1 : 1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }

    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
                end: TimeUtil.Format(this.now, 'yyyy-MM-dd')
            })
            return;
        }
        let begindata = TimeUtil.MomentToTimeString(date[0]);
        let enddata = TimeUtil.MomentToTimeString(date[1]);

        this.setState({
            begin: begindata,
            end: enddata
        })
    }


    //设置搜索渠道
    private SetChannel(channel: string) {
        this.setState({
            channel: channel
        })
    }

    //设置游戏类型
    private SetType(type: string) {
        let data = '';
        for (let index = 0; index < type.length; index++) {
            const element = type[index];
            if (index == type.length - 1) {
                data = data + element
            }
            else {
                data = data + element + ','
            }
        }
        this.setState({
            type: data
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "日期", dataIndex: "date", key: "date", width: "120px", fixed: 'left' },
            { align: "center", title: "总计", dataIndex: "total", key: "total" },
            { align: "center", title: "渠道", dataIndex: "platformId", key: "platformId" },

            { align: "center", title: "0时", dataIndex: "h0", key: "h0" },
            { align: "center", title: "1时", dataIndex: "h1", key: "h1" },
            { align: "center", title: "2时", dataIndex: "h2", key: "h2" },
            { align: "center", title: "3时", dataIndex: "h3", key: "h3" },
            { align: "center", title: "4时", dataIndex: "h4", key: "h4" },
            { align: "center", title: "5时", dataIndex: "h5", key: "h5" },
            { align: "center", title: "6时", dataIndex: "h6", key: "h6" },
            { align: "center", title: "7时", dataIndex: "h7", key: "h7" },
            { align: "center", title: "8时", dataIndex: "h8", key: "h8" },
            { align: "center", title: "9时", dataIndex: "h9", key: "h9" },
            { align: "center", title: "10时", dataIndex: "h10", key: "h10" },
            { align: "center", title: "11时", dataIndex: "h11", key: "h11" },
            { align: "center", title: "12时", dataIndex: "h12", key: "h12" },
            { align: "center", title: "13时", dataIndex: "h13", key: "h13" },
            { align: "center", title: "14时", dataIndex: "h14", key: "h14" },
            { align: "center", title: "15时", dataIndex: "h15", key: "h15" },
            { align: "center", title: "16时", dataIndex: "h16", key: "h16" },
            { align: "center", title: "17时", dataIndex: "h17", key: "h17" },
            { align: "center", title: "18时", dataIndex: "h18", key: "h18" },
            { align: "center", title: "19时", dataIndex: "h19", key: "h19" },
            { align: "center", title: "20时", dataIndex: "h20", key: "h20" },
            { align: "center", title: "21时", dataIndex: "h21", key: "h21" },
            { align: "center", title: "22时", dataIndex: "h22", key: "h22" },
            { align: "center", title: "23时", dataIndex: "h23", key: "h23" },
        ]

        let tableView = <LoaderView />;

        if (this.state.LogsList != null) {
            tableView =
                <Table
                    pagination={false}
                    scroll={{ y: 700 }}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
        }
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 3))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    {/* <Form.Item label='来源' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetPlatformId(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            <Option key={"Android"} value={'Android'}>{"android"}</Option>
                            <Option key={"iOS"} value={'iOS'}>{"ios"}</Option>
                            <Option key={"Web"} value={'Web'}>{"web"}</Option>
                        </Select>
                    </Form.Item> */}

                    <Form.Item label='渠道' >
                        <Select
                            style={{ width: "300PX" }}
                            defaultValue={''}
                            onChange={(value: string) => { this.SetChannel(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label='游戏类型'>
                        <TreeSelect
                            treeData={GameTypeData}
                            style={{ width: "200px" }}
                            treeCheckable={true}
                            maxTagCount={3}
                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                            onChange={(value: any) => { this.SetType(value) }}
                        />
                    </Form.Item>

                    {/* <Form.Item label='游戏类型' >
                        <Select
                            style={{ width: "200PX" }}
                            value={this.state.type}
                            onChange={(value: string) => { this.SetType(value) }}
                        >
                            <Option key={"0"} value={'0'}>{"全部类型"}</Option>
                            <Option key={'1'} value={'1'}>{'金币1'}</Option>
                            <Option key={'2'} value={'2'}>{'金币2'}</Option>
                            <Option key={'3'} value={'3'}>{'金币3'}</Option>
                            <Option key={'4'} value={'1,2,3'}>{'金币场'}</Option>
                            <Option key={'5'} value={'4'}>{'魔能1'}</Option>
                            <Option key={'6'} value={'5'}>{'魔能2'}</Option>
                            <Option key={'7'} value={'6'}>{'魔能3'}</Option>
                            <Option key={'8'} value={'4,5,6'}>{'魔能场'}</Option>

                        </Select>
                    </Form.Item> */}

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.NetRefreshPanel() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>
                <Divider type='horizontal' />

                {tableView}
            </div>
        );
    }
}