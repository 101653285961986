import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Modal, message, InputNumber, DatePicker } from 'antd';
import TimeUtil from '../../../Utils/TimeUtil';
import moment from 'moment';
import StorageValue from '../../../Utils/StorageValue';
import NetUtil from '../../../Utils/NetUtil';
const { Option } = Select;



interface IProps {
    onCancel: Function
    onOk: Function
    formVisible: boolean
}

interface IState {
    name: string
    total: number//总金额
    msg: string  // 
    end: number  //结束时间
    day: number  //
    left: number  //活动最大次数
}


export default class AddAactivityView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            name: '',
            total: 0,//总金额
            msg: "",  // 
            end: new Date().getTime(),  //结束时间
            day: 0,  //
            left: 0  //活动最大次数

        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.formVisible) {

        }
    }


    private SetName(value: string) {
        this.setState({
            name: value
        })
    }

    private Settotal(value: any) {
        this.setState({
            total: value
        })
    }

    private Setmsg(value: string) {
        this.setState({
            msg: value
        })
    }

    private Setend(date: any) {
        if (date == null || date == undefined) {
            this.setState({
                end: new Date().getTime()//TimeUtil.Format(this.now, 'yyyy-MM-dd HH:mm:ss')
            })
            return
        }
        // let begindata = date.format("YYYY-MM-DD HH:mm:ss");
        this.setState({
            end: TimeUtil.MomentToTimestamp(date),
        })
    }

    private Setday(value: any) {
        this.setState({
            day: value
        })
    }
    private Setleft(value: any) {
        this.setState({
            left: value
        })
    }

    private OnbtOkClick() {
        let postData = {
            token: StorageValue.Token,
            name: this.state.name,
            total: this.state.total,//总金额
            msg: this.state.msg,  // 
            end: this.state.end,  //结束时间
            day: this.state.day,  //
            left: this.state.left  //活动最大次数
        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/createAliRed", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

            },
            (err: any) => {
                throw (err)
            })
    }



    render() {
        const formItemStle = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }

        return (
            <Modal
                okText='确定'
                cancelText='取消'
                width="60%"
                onCancel={() => {

                    this.props.onCancel();
                }}
                onOk={() => {

                    this.props.onOk()
                    this.OnbtOkClick();
                }}
                visible={this.props.formVisible}
            >
                <Form style={{ textAlign: "center", width: "100%" }}>
                    <h1 style={{ fontSize: "30px" }}>地址信息</h1>
                    <Form.Item label='姓名:' {...formItemStle}>
                        <Input
                            placeholder="姓名"
                            prefix={<UserOutlined />}
                            value={this.state.name}
                            size="large"
                            onChange={(e) => this.SetName(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label='总金额:' {...formItemStle}>
                        <InputNumber
                            placeholder="总金额"
                            // prefix={<UserOutlined />}
                            value={this.state.total}
                            onChange={(value) => this.Settotal(value)}
                            size="large"

                        />
                    </Form.Item>
                    <Form.Item label='活动名称:' {...formItemStle}>
                        <Input
                            placeholder="姓名"
                            prefix={<UserOutlined />}
                            value={this.state.msg}
                            onChange={(e) => this.Setmsg(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='结束时间:' {...formItemStle}>
                        <DatePicker
                            // showTime
                            value={moment(this.state.end)}
                            format='YYYY-MM-DD'
                            onChange={(value: any) => { this.Setend(value) }}
                        />
                    </Form.Item>

                    <Form.Item label='每天兑换次数:' {...formItemStle}>
                        <InputNumber
                            placeholder="每天兑换次数"

                            // prefix={<UserOutlined />}
                            value={this.state.day}
                            onChange={(value) => this.Setday(value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='总次数:' {...formItemStle}>
                        <InputNumber
                            placeholder="活动期间总次数"
                            // prefix={<UserOutlined />}
                            onChange={(value) => this.Setleft(value)}
                            value={this.state.left}
                            size="large"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}