export enum EnumAuthLevel {
    None = "None",
    GM = "GM",                                  //客服
    JuniorAdmin = "JuniorAdmin",                //管理员
    SeniorAdmin = "SeniorAdmin",                //root
    Inquirer = "Inquirer",                      //运营
    ServerManager = "ServerManager",            //运维
}

//物品Id
export enum EnumItemId {
    RMB = 10000,
    Gold = 10001,      // 金币
    Diamond = 10002,    // 钻石
    Lottery = 10003,    // 福豆
    Magicka = 10004,    // 魔能
    VipExp = 10005,         // VIP经验
    GameExp = 10006,        // 游戏经验
    VipLevel = 10007,       // VIP等级
    GameLevel = 10008,      // 游戏等级
    NameCard = 10009,       //改名卡 id:0
    RemedySignCard = 10010, //补签名
    TurretLevel = 10011,    // 炮台倍率
    Frzeen = 20001,      // 冰封
    Horm = 20002,      // 号角
    BronzeTorpedo = 20003,// 青铜鱼雷
    SilverTorpedo = 20004,// 白银鱼雷
    GoldTorpedo = 20005,  // 黄金鱼雷
    OceanHeart = 40001,//海洋之心
    BlessGem = 40002, //祝福宝石
    Lock = 50001,   //锁定
    Auto = 50002,
    Fast = 50003,   //
    Multi = 50004,
}

//显示页面
export enum EnumPage {
    None = 0,                             //无        -0
    monitoring = 100,                     //实时监控   -100
    onlineNum = 101,                      //在线人数  101
    onlinePlayer = 111,                   //在线玩家


    loginNum = 102,                       //登录人数  102
    realTimeRecharge = 103,               //实时充值  103
    gameJoin = 105,                       //游戏参与  105
    newRegistration = 106,                //新增注册  106
    registrationRetained = 107,           //注册留存  107
    payRetained = 108,                    //付费留存  108
    statisticsAnalysis = 200,             // 统计分析   -200
    playerData = 201,                     //玩家数据  201
    rechargeAnalysis = 202,               // 充值数据  202
    groupCost = 206,                      //工会消耗  206
    gameData = 203,                       //游戏数据  203
    operational = 208,                   // 运营统计
    channelInfo = 209,                   //渠道统计
    userltv = 210,                      //用户价值
    cplCost = 211,                      //cpl 消耗
    userTotal = 213,                    //当前统计
    water = 214,                        //流水

    logQuery = 300,                       //日志查询   -300
    loginLog = 301,                       //登录日志  301
    offlineLog = 302,                     //下线日志  302
    rechargeLog = 303,                    //充值日志  303
    propertyStatistics = 304,             //财产统计  304
    propertyLog = 305,                    //财产日志  305
    bulletBehavior = 306,                 //子弹行为  306
    exchangeLog = 308,                    //兑换日志   308
    hbLog = 309,                           //福利      309
    exchangeStock = 310,                  // 兑换库存   310

    gameManage = 400,                     //游戏管理   -400
    playerManage = 401,                   //玩家管理  401
    pwdManage = 403,                     //密码管理  403
    rootManager = 404,                    //rooter  404
    deviceManager = 405,                  //设备码管理
    feedBackManager = 406,                 //客服反馈
    activityManager = 407,                 //活动管理
    accountBind = 408,                      //账号绑定

    mailManage = 500,                     //邮件管理   -500
    systemMail = 501,                     //系统邮件 -501
    playerMail = 502,                     //玩家邮件 -502
    exchangeCodeManage = 600,             //兑换码管理 -600
    exchangeCodeClassify = 601,           //兑换码分类 -601
    exchangeCodeList = 602,               //兑换码列表 -602
    configManager = 700,                  //配置管理  -700
    shopConfig = 701,                     //商城配置  701
    gameConfig = 702,                     //游戏配置  702
    hotManager = 703,                     //热更管理   703                 

    systemManage = 800,                   //系统管理   -800
    authorityManage = 801,                //权限管理  801
    userManage = 802,                     //用户管理  802
    systemLog = 803,                      //系统日志  803
}
//操作权限
export enum EnumPageOption {
    None = 0, // 无权限
    find = 1, // 查看
    updata = 2, // 增改
    // Del = 3, // 删除
}

export let GetItemNameById = (itemId: number): string => {
    switch (itemId) {
        case EnumItemId.RMB:
            return ''
        case EnumItemId.Gold:
            return '金币'
        case EnumItemId.Diamond:
            return '钻石'
        case EnumItemId.Lottery:
            return '福豆'
        case EnumItemId.Magicka:
            return '魔能'
        case EnumItemId.VipExp:
            return 'VIP经验'
        case EnumItemId.GameExp:
            return '游戏经验'
        case EnumItemId.VipLevel:
            return 'VIP等级'
        case EnumItemId.GameLevel:
            return '游戏等级'
        case EnumItemId.NameCard:
            return '改名卡'
        case EnumItemId.RemedySignCard:
            return '补签到卡'
        case EnumItemId.TurretLevel:
            return '炮台倍率'
        case EnumItemId.Frzeen:
            return '冰封'
        case EnumItemId.Horm:
            return '号角'
        case EnumItemId.BronzeTorpedo:
            return '青铜鱼雷'
        case EnumItemId.SilverTorpedo:
            return '白银鱼雷'
        case EnumItemId.GoldTorpedo:
            return '黄金鱼雷'
        case EnumItemId.OceanHeart:
            return '海洋之心'
        case EnumItemId.BlessGem:
            return '祝福宝石'
        default:
            return ''
    }
}

export enum EnumInfoChangeReason {
    None = 0,               //无
    REGISTER = 1,           // 注册赠送
    RECHARGE = 2,           // 充值直接获取
    GROUP_MAIL = 3,         // 群发邮件,GM
    SINGLE_MAIL = 4,        // 单发邮件，GM
    EXCHANGE = 5,           // 商城兑换
    SIGN = 6,               // 签到
    SAFE_GUARD = 7,         // 补助
    ROBOT = 8,              // 机器人使用
    TASK = 9,               // 完成任务
    GM_REWRITE = 10,        // 后台修改
    LOTTERY_EXCHANGE = 11,  // 兑换
    LOTTERY_RETURN = 12,    // 退还兑换券
    USE_ITEM = 13,          // 道具使用（大厅）
    UPGRADE_AWARD = 14,     // 升级礼包
    CREATE_ROOM = 15,       // 创建房间
    USE_CARD = 16,          // 体验卡使用
    OPEN_TASK = 17,         // 解锁任务
    SAVE_PAY_AWARD = 18,    // 累计充值
    LOTTERY_REDBACKAGE = 20,// 福利兑换
    MANUAL_EXCHANGE = 21,   // 手动兑换
    CHANGE_NICKNAME = 22,   // 改名
    VIP_AWARD = 23,         // VIP
    WEEK = 24,              // 周卡
    Month = 25,             // 月卡
    ACTIVITY = 26,          // 签到

    AIRCRAFT_LEVEL_UP = 27, // 攻击力升级
    AIRCRAFT_STAR_UP = 28,  // 飞机升星

    GAME_RESULT = 1000,     // 游戏赢取
    GAME_DROP_ITEMS = 1001, // 掉落道具
    BOMB = 1002,            // 鱼雷轰炸
    FIRE = 1003,            // 开火
    LOTTERY_SCORE = 1004,   // 积分抽奖
}


export let InfoChangeReason = [
    { key: EnumInfoChangeReason.None, value: '全部' },
    { key: EnumInfoChangeReason.REGISTER, value: '注册赠送' },
    { key: EnumInfoChangeReason.RECHARGE, value: '充值直接获取' },
    { key: EnumInfoChangeReason.GROUP_MAIL, value: '群发邮件' },
    { key: EnumInfoChangeReason.SINGLE_MAIL, value: '单发邮件，GM' },
    { key: EnumInfoChangeReason.EXCHANGE, value: '商城兑换' },
    { key: EnumInfoChangeReason.SIGN, value: '签到' },
    { key: EnumInfoChangeReason.SAFE_GUARD, value: '补助' },
    { key: EnumInfoChangeReason.ROBOT, value: '机器人使用' },
    { key: EnumInfoChangeReason.TASK, value: '完成任务' },
    { key: EnumInfoChangeReason.GM_REWRITE, value: '补助' },
    { key: EnumInfoChangeReason.ROBOT, value: '机器人使用' },
    { key: EnumInfoChangeReason.TASK, value: '完成任务' },
    { key: EnumInfoChangeReason.GM_REWRITE, value: '后台修改' },
    { key: EnumInfoChangeReason.LOTTERY_EXCHANGE, value: '兑换' },
    { key: EnumInfoChangeReason.LOTTERY_RETURN, value: '退还兑换券' },
    { key: EnumInfoChangeReason.USE_ITEM, value: '道具使用（大厅）' },
    { key: EnumInfoChangeReason.UPGRADE_AWARD, value: '升级礼包' },
    { key: EnumInfoChangeReason.CREATE_ROOM, value: '创建房间' },
    { key: EnumInfoChangeReason.USE_CARD, value: '账号绑定' },
    { key: EnumInfoChangeReason.LOTTERY_EXCHANGE, value: '兑换' },
    { key: EnumInfoChangeReason.LOTTERY_RETURN, value: '体验卡使用' },
    { key: EnumInfoChangeReason.OPEN_TASK, value: '解锁任务' },
    { key: EnumInfoChangeReason.SAVE_PAY_AWARD, value: '累计充值' },

    { key: EnumInfoChangeReason.LOTTERY_REDBACKAGE, value: '福利兑换' },
    { key: EnumInfoChangeReason.MANUAL_EXCHANGE, value: '手动兑换' },
    { key: EnumInfoChangeReason.CHANGE_NICKNAME, value: '改名' },
    { key: EnumInfoChangeReason.VIP_AWARD, value: 'VIP' },
    { key: EnumInfoChangeReason.WEEK, value: '周卡' },
    { key: EnumInfoChangeReason.Month, value: '月卡' },
    { key: EnumInfoChangeReason.ACTIVITY, value: '签到' },
    { key: EnumInfoChangeReason.AIRCRAFT_LEVEL_UP, value: '攻击力升级' },
    { key: EnumInfoChangeReason.AIRCRAFT_STAR_UP, value: '飞机升星' },
    { key: EnumInfoChangeReason.GAME_RESULT, value: '游戏赢取' },
    { key: EnumInfoChangeReason.GAME_DROP_ITEMS, value: '掉落道具' },
    { key: EnumInfoChangeReason.BOMB, value: '鱼雷轰炸' },
    { key: EnumInfoChangeReason.FIRE, value: '开火' },
    { key: EnumInfoChangeReason.LOTTERY_SCORE, value: '积分抽奖' },
]