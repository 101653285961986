import React from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Table, Popconfirm, Divider, message, Button, Select } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import AccountInfoModal from './AccountInfoModal';
import ResetAccountModal from './ResetAccountModal';
import AddAccountModal from './AddAccountModal';
import NetUtil from '../../../Utils/NetUtil';
import Util from '../../../Utils/Util';
import StorageValue from '../../../Utils/StorageValue';
import { string } from 'prop-types';

const { Option } = Select;

interface IProps {

}

interface IState {
    usersList: Array<any>,
    department: string,
    name: string,
    account: string,

    editVisibleForm: boolean,
    ResetAccountVisible: boolean,
    addAccountFormVisible: boolean,


    AccounteditData: { Account: string, Name: string, Department: string, State: number, RoleId: string, Desc: string, },
    roleList: Array<string>
    ReastEditData: { Account: string, Pwd: string }
}

/**
 *Created by liangzai on 19/09/01.
 */
export default class AccountView extends React.Component<IProps, IState> {

    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    constructor(props: IProps) {
        super(props);
        this.state = {
            usersList: [],
            department: '0',
            name: '',
            account: '',
            editVisibleForm: false,
            ResetAccountVisible: false,
            addAccountFormVisible: false,
            roleList: [''],
            AccounteditData: { Account: '', Name: '', Department: '', State: 0, RoleId: '', Desc: '' },
            ReastEditData: { Account: '', Pwd: '' }
        }
    }

    //render渲染之前执行
    componentWillMount() {
        this.GetRoleName();
        this.NetRefreshPanel()
    }
    //获取用户信息   刷新页面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/user/list", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                let data = msg.data.sort((a: any, b: any) => {
                    return a.Account > b.Account ? 1 : -1
                })

                this.setState({
                    usersList: Util.Sort(data, "Account")
                })
            },
            () => {

            })
    }

    //获取所有的权限角色
    private GetRoleName() {
        let postData = {
            token: StorageValue.Token,
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/role/all", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                let data = Array<string>()

                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index];
                    data.push(element.name)
                }

                this.setState({
                    roleList: data
                })
            },
            () => {

            })
    }
    //关闭弹框
    private closeModal() {
        this.setState({
            editVisibleForm: false,
            ResetAccountVisible: false,
            addAccountFormVisible: false
        })
    }

    //删除账号
    private deleteAccount(account: string) {
        let postData = {
            token: StorageValue.Token,
            account: account
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/user/remove", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.NetRefreshPanel();
            },
            () => {

            })
    }

    //搜索登录ID
    private SetSearAccount(value: string) {
        this.setState({
            account: value
        })
    }
    //查询按钮触发事件
    private OnSelectClick() {
        this.NetRefreshPanel()
        // NetUtil.Post()
    }

    //取消
    private handleCancel() {
        this.setState({
            editVisibleForm: false,
            ResetAccountVisible: false,
            addAccountFormVisible: false
        })
    }

    //处理弹出框提交完成关闭弹框  操作
    private handleCreate() {
        this.closeModal();
        this.NetRefreshPanel();
    }

    render() {
        const formItemLayout = {
            wr: { span: 24, }
        }

        this.columns = [
            { align: "center", title: "账号", dataIndex: "account", key: "account" },
            {
                align: "center", title: "权限角色", dataIndex: "role", key: "role", render:
                    (text: any, record: any) => {
                        return (
                            this.state.roleList.map(element => {
                                if (element == text) {
                                    return <div key={element}>{element}</div>
                                }
                            })
                        )
                    }
            },
            {
                align: "center", title: "状态", dataIndex: "state", key: "state", render:
                    (text: any, record: any) => {
                        if (text = false) {
                            return <div>{"禁用"}</div>
                        }
                        return <div>{"正常"}</div>
                    }
            },
            { align: "center", title: "描述", dataIndex: "desc", key: "desc" },
            {
                align: "center", title: "操作", dataIndex: "opinion", key: "opinion", render:
                    (text: any, record: any) => {

                        return (
                            (<div>
                                <a onClick={() => {
                                    this.setState({
                                        AccounteditData: record,
                                        editVisibleForm: true
                                    })
                                }}>修改</a>
                                <Divider type="vertical" />
                                < Popconfirm
                                    title="确定删除?"
                                    onConfirm={() => this.deleteAccount(record.account)
                                    }>
                                    <a>删除</a>
                                </Popconfirm >
                            </div>)
                        )
                    }
            }
        ]
        return (
            <div>

                <Form layout='inline'>

                    {/* <Form.Item label='花名' {...formItemLayout} >
                        <Input
                            value={this.state.name}
                            placeholder="花名"
                        // onChange={(e: any) => { this.SetSelName(e.target.value) }}
                        />
                    </Form.Item> */}
                    <Form.Item label='账号'  {...formItemLayout}>
                        <Input
                            value={this.state.account}
                            placeholder="账号"
                            onChange={(e: any) => { this.SetSearAccount(e.target.value) }}
                        />
                    </Form.Item>
                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                    <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        style={{ float: "right" }}
                        onClick={() => { this.setState({ addAccountFormVisible: true }) }}
                    >
                        添加账号
                    </Button>

                </Form>
                <Divider type='horizontal' />
                {/* //修改账号 */}
                <AccountInfoModal
                    roleList={this.state.roleList}
                    editData={this.state.AccounteditData}
                    visible={this.state.editVisibleForm}
                    onCancel={() => { this.handleCancel() }}
                    onSendApplySuccess={() => { this.handleCreate() }}
                />
                {/* //重置密码 */}
                <ResetAccountModal
                    ReastEditData={this.state.ReastEditData}
                    visible={this.state.ResetAccountVisible}
                    onCancel={() => { this.handleCancel() }}
                    onSendApplySuccess={() => { this.handleCreate() }}
                />
                {/* //添加账号 */}
                <AddAccountModal
                    roleList={this.state.roleList}
                    visible={this.state.addAccountFormVisible}
                    onCancel={() => { this.handleCancel() }}
                    onSendApplySuccess={() => { this.handleCreate() }}
                />

                <Table
                    title={() => {
                        return (<div style={{ textAlign: "center", fontSize: 20 }}>
                            <b>账号列表</b>
                        </div>)
                    }}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.usersList}
                    rowKey="Account">
                </Table>
            </div>
        );
    }
}