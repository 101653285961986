import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select, Tooltip } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../../Utils/TimeUtil';
import NetUtil from '../../../Utils/NetUtil';
import { Chart, Legend, Axis, Geom } from 'bizcharts';
import StorageValue from '../../../Utils/StorageValue';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    begin: any
    end: any
    channel: string
    type: number
    item: Array<any>
    platformId: string

}

/**
 * 实时充值
 *Created by liangzai on 19/09/12.
 */
export default class RealTimeRechargeView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private now = new Date();
    private yesterday = subDays(this.now, 6)
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
            end: TimeUtil.Format(this.now, 'yyyy-MM-dd'),
            channel: '',
            type: 0,
            item: [],
            platformId: ""
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
            channel: this.state.channel,
            type: this.state.type,
            platformId: this.state.platformId
        }
        NetUtil.Post(NetUtil.BaseUrl + "monitor/recharge", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                let data: Array<any> = [];
                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index].hour;
                    element.date = msg.data[index].date;
                    element.total = msg.data[index].total;
                    data.push(element)
                }

                console.log(data)
                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.date > b.date ? -1 : 1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick(type: number) {
        this.setState({
            type: type
        })
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
                end: TimeUtil.Format(this.now, 'yyyy-MM-dd')
            })
            return;
        }
        let begindata = TimeUtil.MomentToTimeString(date[0]);
        let enddata = TimeUtil.MomentToTimeString(date[1]);

        this.setState({
            begin: begindata,
            end: enddata
        })
    }

    //设置搜索渠道
    private SetPlatformId(platformId: string) {
        this.setState({
            platformId: platformId
        })
    }
    //设置搜索渠道
    private SetChannel(channel: string) {
        this.setState({
            channel: channel
        })
    }

    render() {
        const cols = {
            width: {
                range: [0, 1]
            }
        };
        this.columns = [
            { align: "center", title: "日期", dataIndex: "date", key: "date", width: "120px", fixed: 'left' },
            { align: "center", title: "总计", dataIndex: "total", key: "total", width: "100px", fixed: 'left' },
            { align: "center", title: "0时", dataIndex: "h0", key: "h0" },
            { align: "center", title: "1时", dataIndex: "h1", key: "h1" },
            { align: "center", title: "2时", dataIndex: "h2", key: "h2" },
            { align: "center", title: "3时", dataIndex: "h3", key: "h3" },
            { align: "center", title: "4时", dataIndex: "h4", key: "h4" },
            { align: "center", title: "5时", dataIndex: "h5", key: "h5" },
            { align: "center", title: "6时", dataIndex: "h6", key: "h6" },
            { align: "center", title: "7时", dataIndex: "h7", key: "h7" },
            { align: "center", title: "8时", dataIndex: "h8", key: "h8" },
            { align: "center", title: "9时", dataIndex: "h9", key: "h9" },
            { align: "center", title: "10时", dataIndex: "h10", key: "h10" },
            { align: "center", title: "11时", dataIndex: "h11", key: "h11" },
            { align: "center", title: "12时", dataIndex: "h12", key: "h12" },
            { align: "center", title: "13时", dataIndex: "h13", key: "h13" },
            { align: "center", title: "14时", dataIndex: "h14", key: "h14" },
            { align: "center", title: "15时", dataIndex: "h15", key: "h15" },
            { align: "center", title: "16时", dataIndex: "h16", key: "h16" },
            { align: "center", title: "17时", dataIndex: "h17", key: "h17" },
            { align: "center", title: "18时", dataIndex: "h18", key: "h18" },
            { align: "center", title: "19时", dataIndex: "h19", key: "h19" },
            { align: "center", title: "20时", dataIndex: "h20", key: "h20" },
            { align: "center", title: "21时", dataIndex: "h21", key: "h21" },
            { align: "center", title: "22时", dataIndex: "h22", key: "h22" },
            { align: "center", title: "23时", dataIndex: "h23", key: "h23" },
        ]
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 6))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='来源' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetPlatformId(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            <Option key={"Android"} value={'Android'}>{"android"}</Option>
                            <Option key={"iOS"} value={'iOS'}>{"ios"}</Option>
                            <Option key={"Web"} value={'Web'}>{"web"}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label='渠道' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={''}
                            onChange={(value: string) => { this.SetChannel(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick(0) }}
                        >
                            查询
                        </Button>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick(0) }}
                        >
                            会员费
                        </Button>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick(1) }}
                        >
                            一级教练
                        </Button>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick(2) }}
                        >
                            其他
                        </Button>
                    </Form.Item>
                </Form>

                <Divider />
                <Table
                    // title={() => {
                    //     return (<div style={{ textAlign: "center", fontSize: 20 }}>
                    //         <b>日志列表</b>
                    //     </div>)
                    // }}
                    pagination={false}
                    scroll={{ x: 2400, y: 700 }}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />


                {/* <Chart height={600} data={this.state.item} scale={cols} forceFit>
                    <Legend />
                    <Axis name="index" />
                    <Axis
                        name="coin"
                        label={{
                            formatter: (text) => { return text }
                        }}
                    />
                    {/* <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    /> 
                    <Geom
                        type="line"
                        position="index*coin"
                        size={2}
                        color={"type"}
                        shape={"smooth"}
                    />
                    <Geom
                        type="point"
                        position="index*coin"
                        size={4}
                        shape={"circle"}
                        color={"type"}
                        style={{
                            stroke: "#fff",
                            lineWidth: 1
                        }}
                    />
                </Chart> */}
            </div>
        );
    }
}