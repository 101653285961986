import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import NetUtil from '../../Utils/NetUtil';
import StorageValue from '../../Utils/StorageValue';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    begin: any
    end: any
    userId: string
    channel: number

}

/**
 * 下线日志
 *Created by liangzai on 19/09/12.
 */
export default class OfflineLogView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private nowTime: number = TimeUtil.GetDayBegin(new Date());
    private yesterday = TimeUtil.GetDayBegin(subDays(this.nowTime, 6));
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: this.yesterday,
            end: this.nowTime,
            userId: '',
            channel: 0
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
            userId: this.state.userId,
            channel: this.state.channel,
        }
        NetUtil.Post(NetUtil.BaseUrl + "log/offline", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                this.setState({
                    LogsList: msg.data
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: this.yesterday,
                end: this.nowTime,
            })
            return;
        }
        let begindata = moment(date[0]).valueOf();
        let enddata = moment(date[1]).valueOf();

        this.setState({
            begin: begindata,
            end: enddata
        })
    }
    //设置玩家id
    private SetPlayerId(userId: string) {
        this.setState({
            userId: userId
        })
    }
    //设置会长id
    private SetChannel(channel: number) {
        this.setState({
            channel: channel
        })
    }

    render() {
        this.columns = [
            { align: "center", title: "玩家ID", dataIndex: "userId", key: "userId" },
            {
                align: "center", title: "下线时间", dataIndex: "logout", key: "logout", render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text, 'yyyy-MM-dd hh:mm:ss')}</div>
                }
            },
            {
                align: "center", title: "最近登录", dataIndex: "login", key: "login", render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text, 'yyyy-MM-dd hh:mm:ss')}</div>
                }
            },
            { align: "center", title: "登录时长", dataIndex: "duration", key: "duration" },
            { align: "center", title: "会长ID", dataIndex: "channel", key: "channel" },
        ]
        return (
            <div>
                <Form layout='inline'>

                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 7))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='会长ID'>
                        <Select
                            defaultValue={0}
                            onChange={(value: number) => { this.SetChannel(value) }}
                        >
                            <Option key={"0"} value={0}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label='玩家ID'>
                        <Input
                            placeholder="请输入玩家ID..."
                            value={this.state.userId}
                            onChange={(e) => this.SetPlayerId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>

                <Divider />
                <Table
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}