import React from 'react';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Select, Button, Modal } from 'antd';
import SystemMailManager, { SystemMailData } from '../SystemMailManager';
import CostomMaliManager from '../CostomMaliManager';
const { Option } = Select;
export default class ChangeSysMailData extends React.Component {
    constructor() {
        super();

        if (!SystemMailManager.CurSysMailData) {
            SystemMailManager.CurSysMailData = new SystemMailData();
        }
        this.state = {
            ID: SystemMailManager.CurSysMailData.Identity ? SystemMailManager.CurSysMailData.Identity : "null",
            title: SystemMailManager.CurSysMailData.Title ? SystemMailManager.CurSysMailData.Title : "null",
            content: SystemMailManager.CurSysMailData.Content ? SystemMailManager.CurSysMailData.Content : "null",
            from: SystemMailManager.CurSysMailData.From ? SystemMailManager.CurSysMailData.From : "null",
            attach: (!SystemMailManager.CurSysMailData.Attachment || SystemMailManager.CurSysMailData.Attachment == "null") ? [] : JSON.parse(SystemMailManager.CurSysMailData.Attachment),
            acctchNum: 0,
            ItemInfo: [],
        }
    }


    componentWillReceiveProps(nextProps) {
        this.NetRefresh()
        console.log(SystemMailManager.CurSysMailData.Attachment, '>>>>>>>>')
        if (nextProps.formVisible) {
            console.log(SystemMailManager.CurSysMailData.Attachment, '>>>>>>>>')
            this.setState({
                ID: SystemMailManager.CurSysMailData.Identity ? SystemMailManager.CurSysMailData.Identity : "null",
                title: SystemMailManager.CurSysMailData.Title ? SystemMailManager.CurSysMailData.Title : "null",
                content: SystemMailManager.CurSysMailData.Content ? SystemMailManager.CurSysMailData.Content : "null",
                from: SystemMailManager.CurSysMailData.From ? SystemMailManager.CurSysMailData.From : "null",
                attach: (!SystemMailManager.CurSysMailData.Attachment || SystemMailManager.CurSysMailData.Attachment == "null") ? [] : JSON.parse(SystemMailManager.CurSysMailData.Attachment),
                acctchNum: 0,
                ItemInfo: [],
            })
        }

    }

    NetRefresh() {
        console.log(this.state.attach);
        if (this.state.attach && this.state.attach.length > 0) {
            for (let index = 0; index < this.state.attach.length; index++) {
                this.setAttachID(index, this.state.attach[index].itemId)
                this.setAttachNumber(index, this.state.attach[index].itemCount)
            }
        }
        CostomMaliManager.GetItemInfo(
            (data) => {
                this.setState({
                    ItemInfo: data
                })
            },
            () => {
            }
        )
    }
    //ID
    // setID(value) {
    //     this.setState({
    //         ID: value
    //     })
    // }
    //标题
    setTitle(value) {
        this.setState({
            title: value
        })
    }
    //ID邮件内容
    setcontent(value) {
        this.setState({
            content: value
        })
    }
    //发件人姓名
    setFrom(value) {
        this.setState({
            from: value
        })
    }
    //添加附件
    HandleAddAttach() {
        let data = {
            itemId: null,
            itemCount: null
        }
        this.state.acctchNum += 1;
        let attochData = [...this.state.attach];
        attochData.push(data);
        this.setState({
            attach: attochData
        })
    }
    //设置附件id
    setAttachID(index, value) {
        let attachData = [...this.state.attach];
        attachData[index].itemId = value;
        this.setState({
            attach: attachData
        })
    }
    //设置附件数量
    setAttachNumber(index, value) {
        let attachData = [...this.state.attach];
        attachData[index].itemCount = value;
        this.setState({
            attach: attachData
        })
    }
    //删除附件
    deleteAttach(index) {
        this.state.acctchNum -= 1;
        this.state.attach.splice(index, 1);
        let attochData = this.state.attach;
        this.setState({
            attach: attochData
        })
    }

    //重置按钮
    // handleCancle() {
    //     // <link to= '/home/customMailList'></link>
    //     this.setState({
    //         ID: null,
    //         title: null,
    //         content: null,
    //         from: null,
    //         attach: null,
    //         acctchNum: 0
    //     })
    // }
    //修改
    handleChangeMail() {
        SystemMailManager.updateSysMail(this.state.ID, this.state.title, this.state.content, this.state.from, this.state.attach, () => {
            message.info("修改邮件成功！");
            this.props.onCancel()
        },
            (err) => {
                message.info(err);
            })
    }

    render() {
        const formItemStle = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }
        return (
            <Modal
                okText='修改'
                cancelText='取消'
                width="60%"
                onCancel={() => { this.props.onCancel() }}
                onOk={() => { this.handleChangeMail() }}
                visible={this.props.formVisible}
            >
                <Form style={{ textAlign: "center", width: "100%" }}>
                    <h1 style={{ fontSize: "30px" }}>修改邮件</h1>
                    <Form.Item label='ID:' {...formItemStle}>
                        <Input
                            placeholder="1->无穷大"
                            type={Number}

                            prefix={<UserOutlined />}
                            value={this.state.ID}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='邮件标题:' {...formItemStle}>
                        <Input

                            placeholder="邮件标题"
                            prefix={<EyeOutlined />}
                            value={this.state.title}
                            onChange={(e) => this.setTitle(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='邮件内容:' {...formItemStle}>
                        <Input

                            placeholder="邮件内容"
                            prefix={<EyeOutlined />}
                            value={this.state.content}
                            onChange={(e) => this.setcontent(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='发件人:' {...formItemStle}>
                        <Input

                            placeholder="发件人名字"
                            prefix={<EyeOutlined />}
                            value={this.state.from}
                            onChange={(e) => this.setFrom(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    {this.state.attach.map((item, index) => {
                        return (
                            <Form.Item key={index}>
                                <Select

                                    style={{ width: '40%', marginRight: "2%" }}
                                    size={"large"}
                                    value={this.state.attach[index].itemId}
                                    onChange={(value) => this.setAttachID(index, value)}
                                >
                                    <Option key={''} value={''}>{"请选择游戏道具"}</Option>
                                    {this.state.ItemInfo.map((item) => {
                                        return <Option key={item.identity} value={item.identity}>{item.name}</Option>
                                    })}
                                    {/* <Option value="Users">Users</Option>
                                    <Option value="All">All</Option> */}
                                </Select>
                                <Input
                                    placeholder="道具数量"
                                    type={Number}
                                    style={{ width: '40%', marginRight: "2%" }}
                                    value={this.state.attach[index].itemCount}
                                    onChange={(e) => this.setAttachNumber(index, e.target.value)}
                                    size="large"
                                />
                                <Button type="primary" onClick={() => { this.deleteAttach(index) }} style={{ width: '10%' }}>
                                    删除附件
                            </Button>
                            </Form.Item>
                        )
                    })}
                    <Form.Item>
                        <Button type="primary" onClick={() => { this.HandleAddAttach() }} style={{ width: '50%' }}>
                            添加附件
                        </Button>
                    </Form.Item>
                    {/* 
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '10%', marginRight: 100 }}>
                            立即修改
                        </Button>
                        <Link to="/home/systemMailList">
                            <Button type="primary" style={{ width: '10%' }}>
                                返回
                            </Button>
                        </Link>
                    </Form.Item> */}
                </Form>
            </Modal>
        );
    }
}