import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Button, DatePicker, Select, Upload, Input } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import NetUtil from '../../Utils/NetUtil';
import StorageValue from '../../Utils/StorageValue';
import ExportExcel from '../../Utils/ExcelExport';
import CommDialog from '../../Utils/CommDilog';
import Util from '../../Utils/Util';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    begin: any
    end: any
    playerId: string
    formVisible: boolean,
    updata: any,
    file:string,
    total: Boolean,
}

/**
 * 兑换库存
 *Created by liangzai on 21/04/07.
 */
export default class ExchangeStockView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private nowTime: number = TimeUtil.GetDayEnd(new Date());
    private yesterday = TimeUtil.GetDayBegin(subDays(this.nowTime, 6));
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: this.yesterday,
            end: this.nowTime,
            playerId: '',
            formVisible: false,
            updata: null,
            total: false,
            file:"",
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
        }
        NetUtil.Post(NetUtil.BaseUrl + "log/exchangeTotal", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }


                this.setState({
                    total: true,
                    LogsList: (msg.data).sort(
                        (a: any, b: any) => {
                            if (a.type == b.type) {
                                return a.cardnum > b.cardnum ? 1 : -1
                            }

                            return a.type > b.type ? 1 : -1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }

    private NetRefreshAll() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
            playerId: this.state.playerId,
        }
        NetUtil.Post(NetUtil.BaseUrl + "log/exchangeStock", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }


                this.setState({
                    total: false,
                    LogsList: msg.data
                })
            },
            (err: any) => {
                throw (err)
            })
    }

    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshAll()
    }

    private OnTotalClick() {
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: this.yesterday,
                end: this.nowTime,
            })
            return;
        }
        let begindata = moment(date[0]).valueOf();
        let enddata = moment(date[1]).valueOf();

        this.setState({
            begin: begindata,
            end: enddata
        })
    }

    //设置会长id
    private SetPlayerId(id: string) {
        this.setState({
            playerId: id
        })
    }

    // 补发
    private OnReplenishClick() {
        let postData = {
            token: StorageValue.Token
        }
        NetUtil.Post(NetUtil.BaseUrl + "log/exchangeReplenish", postData,
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    return;
                }
                message.info(msg.data)
                CommDialog.showConfirm("补发结果", msg.data);
            },
            (err: any) => {
                throw (err)
            })
    }

    private OnPhoneBalanceClick(){
        let postData = {
            token: StorageValue.Token
        }
        NetUtil.Post(NetUtil.BaseUrl + "log/phoneBalance", postData,
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    return;
                }
                message.info(msg.data)
                CommDialog.showConfirm("话费余额", msg.data);
            },
            (err: any) => {
                throw (err)
            })
    }

    //导入excel
    private handleUpload() {
        let data = Util.ArrayBufferToBase64(this.state.updata);
        let file = this.state.file;

        NetUtil.Post(NetUtil.BaseUrl + "log/exchangeUpdate",
            {
                token: StorageValue.Token,
                data: data,
                file:file,
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    return;
                }
                message.info(msg.data)
                CommDialog.showConfirm("上传反馈", msg.data);
            },
            (err: any) => {
                return;
            })
    }

    render() {

        if (!this.state.total) {
            this.columns = [
                { align: "center", title: "使用者", dataIndex: "userId", key: "userId" },
                {
                    align: "center", title: "使用时间", dataIndex: "useedTime", key: "useedTime", render: (text, record) => {
                        return <div>{TimeUtil.TimestampToTime(text*1000, 'yyyy-MM-dd hh:mm:ss')}</div>
                    }
                },
                { align: "center", title: "类型", dataIndex: "type", key: "type" },
                { align: "center", title: "面额", dataIndex: "cardnum", key: "cardnum" },
                { align: "center", title: "卡号", dataIndex: "key", key: "key" },
                { align: "center", title: "卡密", dataIndex: "value", key: "value" },
                { align: "center", title: "邮件模板", dataIndex: "emailSysTemDataId", key: "emailSysTemDataId" },
            ]
        } else {
            this.columns = [
                { align: "center", title: "类型", dataIndex: "type", key: "type" },
                { align: "center", title: "面额", dataIndex: "cardnum", key: "cardnum" },
                { align: "center", title: "已使用", dataIndex: "use", key: "use" },
                { align: "center", title: "剩余", dataIndex: "free", key: "free" },
                { align: "center", title: "库存", dataIndex: "money", key: "money" },
                { align: "center", title: "锁定", dataIndex: "lock", key: "lock" }
            ]
        }

        return (
            <div>
                <Form layout='inline'>

                    <Form.Item label='使用时间' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 7))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='使用者：'>
                        <Input
                            placeholder="请输入玩家ID..."
                            value={this.state.playerId}
                            onChange={(e) => this.SetPlayerId(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>


                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnTotalClick() }}
                        >
                            刷新统计
                        </Button>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnReplenishClick() }}
                        >
                            一键补发
                        </Button>
                    </Form.Item>

                    <Form.Item >
                        <Upload
                            showUploadList={false}
                            multiple={false}
                            beforeUpload={(file, FileList) => {
                                let data = new FileReader();
                                data.readAsArrayBuffer(file);
                                data.onload = () => {
                                    this.setState({
                                        updata: data.result,
                                        file:file.name,
                                    })
                                }
                                return true
                            }}
                            onChange={(info) => {
                                console.log(info)
                                if (info.file.status === 'error' || info.file.status === 'done') {
                                    this.handleUpload()
                                }
                            }}
                        >
                            <Button type="primary" onClick={() => {
                                this.setState({
                                    formVisible: true
                                })
                            }} >
                                导入新卡号
                            </Button>
                        </Upload  >
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnPhoneBalanceClick() }}
                        >
                            话费余额
                        </Button>
                    </Form.Item>

                </Form>
                <Divider />
                <Table
                    pagination={false}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}