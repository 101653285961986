import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Button, DatePicker, Select } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import StorageValue from '../../Utils/StorageValue';
import NetUtil from '../../Utils/NetUtil';
import { Util } from 'bizcharts';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    begin: any
    end: any
    channel: string
    platformId: string

}

/**
 * 用户价值
 *Created by liangzai on 19/09/12.
 */
export default class ItvView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private now = new Date();
    private yesterday = subDays(this.now, 6)
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
            end: TimeUtil.Format(this.now, 'yyyy-MM-dd'),
            channel: '',
            platformId: ""

        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
            channel: this.state.channel,
            platformId: this.state.platformId
        }
        NetUtil.Post(NetUtil.BaseUrl + "analysis/ltv", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                let data: Array<any> = [];
                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index];
                    // element.date = msg.data[index].date;
                    element.rate = (element.rate * 100).toFixed(2) + "%";
                    element.ltv1 = element.ltv1 ? element.ltv1.toFixed(3) : 0;
                    element.ltv2 = element.ltv2 ? element.ltv2.toFixed(3) : 0;
                    element.ltv3 = element.ltv3 ? element.ltv3.toFixed(3) : 0;
                    element.ltv7 = element.ltv7 ? element.ltv7.toFixed(3) : 0;
                    element.ltv15 = element.ltv15 ? element.ltv15.toFixed(3) : 0;
                    element.ltv30 = element.ltv30 ? element.ltv30.toFixed(3) : 0;
                    element.ltv60 = element.ltv60 ? element.ltv60.toFixed(3) : 0;
                    element.ltv90 = element.ltv90 ? element.ltv90.toFixed(3) : 0;
                    data.push(element)
                }

                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.date > b.date ? -1 : 1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }

    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
                end: TimeUtil.Format(this.now, 'yyyy-MM-dd')
            })
            return;
        }
        let begindata = TimeUtil.MomentToTimeString(date[0]);
        let enddata = TimeUtil.MomentToTimeString(date[1]);

        this.setState({
            begin: begindata,
            end: enddata
        })
    }
    //设置搜索渠道
    private SetPlatformId(platformId: string) {
        this.setState({
            platformId: platformId
        })
    }
    //设置搜索渠道
    private SetChannel(channel: string) {
        this.setState({
            channel: channel
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "日期", dataIndex: "date", key: "date", width: "120px", fixed: 'left' },
            { align: "center", title: "注册人数", dataIndex: "create", key: "create" },
            { align: "center", title: "充值人数", dataIndex: "recharge", key: "recharge" },
            { align: "center", title: "充值占比", dataIndex: "rate", key: "rate" },
            { align: "center", title: " 1日", dataIndex: "ltv1", key: "ltv1" },
            { align: "center", title: "2日", dataIndex: "ltv2", key: "ltv2" },
            { align: "center", title: "3日", dataIndex: "ltv3", key: "ltv3" },
            { align: "center", title: "7日", dataIndex: "ltv7", key: "ltv7" },
            { align: "center", title: "15日", dataIndex: "ltv15", key: "ltv15" },
            { align: "center", title: "30日", dataIndex: "ltv30", key: "ltv30" },
            { align: "center", title: "60日", dataIndex: "ltv60", key: "ltv60" },
            { align: "center", title: "90日", dataIndex: "ltv90", key: "ltv90" },

        ]
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 6))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='来源' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetPlatformId(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            <Option key={"Android"} value={'Android'}>{"android"}</Option>
                            <Option key={"iOS"} value={'iOS'}>{"ios"}</Option>
                            <Option key={"Web"} value={'Web'}>{"web"}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label='渠道' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={''}
                            onChange={(value: string) => { this.SetChannel(value) }}
                        >
                            <Option key={""} value={''}>{"全部渠道"}</Option>
                            {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.NetRefreshPanel() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>
                <Divider type='horizontal' />

                <Table
                    pagination={false}
                    scroll={{ y: 700 }}

                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}