import React from 'react';
import { Table, message } from 'antd';
import LoaderView from '../../components/Loader/LoaderView';
import TorpedoDataManager from './TorpedoDataManager';

export default class TorpedoView extends React.Component {
    constructor() {
        super();
        this.state = {
            tableData: null,
            allGroupIdKeys: null,
            filteredInfo: null,
            sortedInfo: null,
        }
    }

    componentWillMount() {
        TorpedoDataManager.GetData(
            () => {
                this.setState({
                    tableData: TorpedoDataManager.allTorpedoData,
                    allGroupIdKeys: TorpedoDataManager.allGroupIdKeys
                })
            },
            (err) => {
                message.error(err)
            });
    }

    handleChange(pagination, filters, sorter) {
        TorpedoDataManager.groupid = null;
        if (filters.groupid && filters.groupid.length > 0) {
            TorpedoDataManager.groupid = filters.groupid;
        }

        TorpedoDataManager.GetData(
            () => {
                this.setState({
                    tableData: TorpedoDataManager.allTorpedoData,
                    filteredInfo: filters,
                    sortedInfo: sorter,
                })
            },
            (err) => {
                message.error(err)
            });
    };

    render() {
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        this.columns = [
            {
                align: "center",
                title: "渠道",
                dataIndex: "groupid",
                key: "groupid",
                filters: this.state.allGroupIdKeys,
                filteredValue: filteredInfo.groupid || null,
                onFilter: (value, record) => record.groupid == value || record.groupid == "合计",
                sorter: (a, b) => a.groupid - b.groupid,
                sortOrder: sortedInfo.columnKey === 'groupid' && sortedInfo.order,
                defaultSortOrder: 'ascend',
            },
            {
                align: "center",
                dataIndex: "playerId",
                key: "playerId",
                title: "玩家",
                sorter: (a, b) => a.playerId - b.playerId,
                sortOrder: sortedInfo.columnKey === 'playerId' && sortedInfo.order,
            },
            {
                align: "center",
                title: "当前",
                dataIndex: "now",
                key: "now",
                sorter: (a, b) => a.now - b.now,
                sortOrder: sortedInfo.columnKey === 'now' && sortedInfo.order,
            },
            {
                align: "center",
                title: "赠送",
                dataIndex: "send",
                key: "send",
                sorter: (a, b) => a.send - b.send,
                sortOrder: sortedInfo.columnKey === 'send' && sortedInfo.order,
            },
            {
                align: "center",
                title: "接收",
                dataIndex: "recv",
                key: "recv",
                sorter: (a, b) => a.recv - b.recv,
                sortOrder: sortedInfo.columnKey === 'recv' && sortedInfo.order,
            },
            {
                align: "center",
                title: "兑换",
                dataIndex: "exchange",
                key: "exchange",
                sorter: (a, b) => a.exchange - b.exchange,
                sortOrder: sortedInfo.columnKey === 'exchange' && sortedInfo.order,
            },
            {
                align: "center",
                title: "邮件",
                dataIndex: "mail",
                key: "mail",
                sorter: (a, b) => a.mail - b.mail,
                sortOrder: sortedInfo.columnKey === 'mail' && sortedInfo.order,
            },
            {
                align: "center",
                title: "游戏",
                dataIndex: "game",
                key: "game",
                sorter: (a, b) => a.game - b.game,
                sortOrder: sortedInfo.columnKey === 'game' && sortedInfo.order,
            },
            {
                align: "center",
                title: "CDK",
                dataIndex: "cdkey",
                key: "cdkey",
                sorter: (a, b) => a.cdkey - b.cdkey,
                sortOrder: sortedInfo.columnKey === 'cdkey' && sortedInfo.order,
            },
            {
                align: "center",
                title: "VIP",
                dataIndex: "vip",
                key: "vip",
                sorter: (a, b) => a.cdkey - b.cdkey,
                sortOrder: sortedInfo.columnKey === 'vip' && sortedInfo.order,
            }
        ]


        if (this.state.tableData == null) {
            return <LoaderView />
        }

        return (
            <div>
                <Table
                    pagination={true}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.tableData}
                    onChange={this.handleChange}
                    title={() => {
                        return "*【渠道鱼雷】查询结果："
                    }}
                    footer={() => {
                        return '* product by zhjd '
                    }}
                    scroll={{ x: 1000 }}
                ></Table>
            </div>
        )
    }
}