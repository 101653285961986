import React from 'react';
import md5 from "md5"
import { Form } from '@ant-design/compatible';
import { Input, Button, Descriptions, message, Divider } from 'antd';
import StorageValue from '../../../Utils/StorageValue';
import NetUtil from '../../../Utils/NetUtil';


interface IProps {

}

interface IState {
    playerId: string
    account: string
    pwd1: string
    pwd2: string

    userData: any
}

/**
 * 密码管理
 *Created by liangzai on 19/12/19.
 */
export default class PwdManagerView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {

            playerId: '',
            account: '',
            pwd1: '',
            pwd2: '',
            userData: null
        }
    }
    //设置玩家搜索ID
    private setUserId(value: string) {
        this.setState({
            playerId: value
        })
    }

    //设置玩家昵称
    private setAccount(value: string) {
        this.setState({
            account: value,
        })
    }

    //设置密码
    private setPwd(value: string) {
        this.setState({
            pwd1: value,
        })
    }

    //确认密码
    private setSurePwd(value: string) {
        this.setState({
            pwd2: value,
        })
    }


    //网络请求刷新页面
    private GetFindId() {
        let postData = {
            token: StorageValue.Token,
            playerId: this.state.playerId,
            account: this.state.account
        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/findid", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.setState({
                    userData: msg.data
                })
            },
            (err: any) => {
                throw (err)
            })
    }



    //修改玩家信息
    private changePwd() {

        if (this.state.pwd1 != this.state.pwd2) {
            message.error('两次输入密码不一致，请确认后重试！')
            return;
        }

        let postData = {
            playerId: this.state.playerId,
            token: StorageValue.Token,
            pwd: md5(this.state.pwd2 + this.state.userData.salt)

        }
        NetUtil.Post(NetUtil.BaseUrl + "manage/changepwd", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.info('密码修改成功！');
            },
            (err: any) => {
                throw (err)
            })
    }

    render() {

        const formItemLayout = {
            labelCol: {
                xs: { span: 3 },
                sm: { span: 1 },
            },
            wrapperCol: {
                xs: { span: 20 },
                sm: { span: 8 },
            }
        }


        return (
            <div >

                <Form layout='inline'>
                    <Form.Item label='玩家Id' >
                        <Input
                            placeholder="请输入玩家ID..."
                            value={this.state.playerId}
                            onChange={(e) => this.setUserId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label='玩家账号'>
                        <Input
                            placeholder="请输入玩家账号..."
                            value={this.state.account}
                            onChange={(e) => this.setAccount(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button type="primary" onClick={() => { this.GetFindId() }} >
                            查询
                             </Button>
                    </Form.Item>
                </Form>

                <Divider />

                <Descriptions column={6} size='small'>
                    <Descriptions.Item label='Id'>
                        <Input
                            value={this.state.userData == null ? '' : this.state.userData.playerId}
                        ></Input>

                    </Descriptions.Item>
                    <Descriptions.Item label='账号'>
                        <Input
                            value={this.state.userData == null ? '' : this.state.userData.account}
                        ></Input>
                    </Descriptions.Item>
                </Descriptions>

                <Form layout='horizontal'>
                    <Form.Item label='密码' {...formItemLayout}>
                        <Input
                            placeholder="请输入密码..."
                            value={this.state.pwd1}
                            onChange={(e) => this.setPwd(e.target.value)}
                        />

                    </Form.Item>
                    <Form.Item label='确认密码' {...formItemLayout}>
                        <Input
                            placeholder="请确认输入密码..."
                            value={this.state.pwd2}
                            onChange={(e) => this.setSurePwd(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button type="primary" onClick={() => { this.changePwd() }} >
                            修改
                        </Button>
                    </Form.Item>
                </Form>
            </div >
        )
    }
}