import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import NetUtil from '../../Utils/NetUtil';
import StorageValue from '../../Utils/StorageValue';
import { InfoChangeReason, EnumInfoChangeReason } from '../../UserInfo/EnumManager';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    begin: any
    end: any
    userId: string
    type: string
    reason: EnumInfoChangeReason
}

/**
 **道具变动
 *Created by liangzai on 19/09/12.
 */
export default class ItemChangeLogView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private nowTime: number = TimeUtil.GetDayBegin(new Date());
    private yesterday = TimeUtil.GetDayBegin(subDays(this.nowTime, 6));

    private itemData: any = [];

    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: this.yesterday,
            end: this.nowTime,
            userId: '',
            type: '0',
            reason: EnumInfoChangeReason.None
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
            userId: this.state.userId,
            type: this.state.type,
            reson: this.state.reason
        }
        NetUtil.Post(NetUtil.BaseUrl + "log/item", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.setState({
                    LogsList: msg.data
                })
            },
            (err: any) => {
                throw (err)
            })
    }

    //获取充值渠道数据
    private GetItemData() {
        let postData = {
            token: StorageValue.Token,
        }
        NetUtil.Post(NetUtil.BaseUrl + "log/iteminfo", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index];
                    this.itemData.push(element)
                }

                this.setState({
                    userId: ''
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.GetItemData();
        // this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: this.yesterday,
                end: this.nowTime,
            })
            return;
        }
        let begindata = moment(date[0]).valueOf();
        let enddata = moment(date[1]).valueOf();

        this.setState({
            begin: begindata,
            end: enddata
        })
    }
    //设置玩家id
    private SetPlayerId(PlayerId: string) {
        this.setState({
            userId: PlayerId
        })
    }

    //设置变动原因
    private SetReason(reason: EnumInfoChangeReason) {
        this.setState({
            reason: reason
        })
    }

    //设置财产类型
    private SetType(type: string) {
        this.setState({
            type: type
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "玩家ID", dataIndex: "userId", key: "userId" },
            { align: "center", title: "道具ID", dataIndex: "type", key: "type" },
            {
                align: "center", title: "变动时间", dataIndex: "time", key: "time", render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text, 'yyyy-MM-dd hh:mm:ss')}</div>
                }
            },
            { align: "center", title: "变动前", dataIndex: "before", key: "before" },
            { align: "center", title: "变动值", dataIndex: "change", key: "change" },
            { align: "center", title: "变动后", dataIndex: "left", key: "left" },
            {
                align: "center", title: "变动原因", dataIndex: "reson", key: "reson", render: (text, record) => {
                    let item = InfoChangeReason.find(item => item.key == text);
                    if (item) {
                        return <div>{item.value}</div>
                    }
                    else {
                        return <div>{text}</div>
                    }

                }
            },
            { align: "center", title: "原因说明", dataIndex: "param", key: "param" },
        ]
        return (
            <div>
                <Form layout='inline'>

                    <Form.Item label='玩家ID'>
                        <Input
                            placeholder="请输入玩家ID..."
                            value={this.state.userId}
                            onChange={(e) => this.SetPlayerId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label='日期选择' >
                        <RangePicker
                            showTime
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 7))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD HH:mm:ss'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='道具ID'>
                        <Select
                            style={{ width: "150px" }}
                            value={this.state.type}
                            onChange={(value: string) => { this.SetType(value) }}
                        >
                            <Option key={"0"} value={'0'}>{"所有道具"}</Option>
                            {this.itemData.map((item: any) => {
                                return (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label='移除原因'>
                        <Select
                            style={{ width: "200px" }}
                            value={this.state.reason}
                            onChange={(value: EnumInfoChangeReason) => { this.SetReason(value) }}
                        >
                            {InfoChangeReason.map((item: any) => {
                                return (
                                    <Option key={item.key} value={item.key}>{item.value}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>
                <Divider type='horizontal' />

                <Divider />
                <Table
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}