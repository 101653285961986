
import React from 'react';
import { Input, message, Button, Divider,Typography  } from 'antd';
import CareQueryManager from './CareQueryManager';
const { Title } = Typography;

export default class CareQueryView extends React.Component {
    constructor() {
        super();
        this.state = {
            searchPlayData: null,
            userID: null,
            Count: null,
        }
    }

    componentWillMount() {

    }

    setUserId(value) {
        this.setState({
            userID: value
        })
    }

    setCount(value) {
        this.setState({
            Count: value
        })
    }

    onBtnSearchClick() {
        if (this.state.userID == null) {
            message.error("请先输入玩家ID");
            return;
        }

        CareQueryManager.GetPlayerData(this.state.userID, (data) => {
            this.setState({
                searchPlayData: data
            })
        }, (err) => {
            message.error(err)
        })
    }

    onBtnSetCareClick() {

        if (this.state.searchPlayData == null) {
            message.error("请先查询玩家信息！");
            return;
        }

        if (this.state.Count == null) {
            message.error("请输入照顾数量！");
            return;
        }

        CareQueryManager.SetPlayerCare(this.state.userID, this.state.Count, (data) => {
            this.setState({
                searchPlayData: data
            })
            message.error("设置成功!");
        },
            (err) => {
                message.error(err)
            })
    }

    render() {

        if (this.state.searchPlayData == null) {
            this.SerchView = (
                < div >
                   <Title level={3}>请先查询玩家信息</Title>
                </div >
            )
        }
        else {
            this.SerchView = (
                < div style={{ marginTop: 20 }}>
                   <Title level={3}>当前玩家信息：</Title>
                    < div style={{ marginRight: 20 }}>
                        <Divider type="horizontal " />
                        玩家ID:{this.state.searchPlayData.userId}
                    </div >
                    < div style={{ marginTop: 20 }} >
                        <Divider type="horizontal " />
                        玩家渠道:{this.state.searchPlayData.groupId}
                    </div >
                    < div style={{ marginTop: 20 }}>
                        <Divider type="horizontal " />
                        当前照顾:{this.state.searchPlayData.count}
                    </div >
                    < div style={{ marginTop: 20 }}>
                        <Divider type="horizontal " />
                        个人库存:{this.state.searchPlayData.stock}
                    </div >
                    < div style={{ marginTop: 20 }}>
                        <Divider type="horizontal " />
                        照顾目标:{this.state.searchPlayData.target}
                    </div >
                    < div style={{ marginTop: 20 }} >
                        <Divider type="horizontal " />
                        历史照顾:{this.state.searchPlayData.total}
                        <Divider type="horizontal " />
                    </div >
                </div >
            )
        }


        return (
            <div>
                <Input
                    style={{ width: '55%', marginRight: 20 }}
                    placeholder="请输入玩家ID..."
                    value={this.state.userID}
                    onChange={(e) => this.setUserId(e.target.value)}
                />
                <Button type="primary" onClick={() => { this.onBtnSearchClick() }} >
                    查询
                </Button>
                <Input
                    style={{ width: '55%', marginTop: '2%', marginRight: 20 }}
                    placeholder="请输入照顾数量..."
                    value={this.state.Count}
                    onChange={(e) => this.setCount(e.target.value)}
                />
                <Button type="primary" onClick={() => { this.onBtnSetCareClick() }} >
                    设置
                </Button>

                <Divider type="horizontal " />
                {this.SerchView}
            </div >
        )
    }
}