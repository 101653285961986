import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, message, InputNumber } from 'antd';
import NetUtil from '../../../Utils/NetUtil';
import StorageValue from '../../../Utils/StorageValue';

interface IProps {
    editData: { name: string, PP: Array<number>, desc: string, }
    visible: boolean
    onCancel: Function              //取消
    onSendApplySuccess: Function    ////向服务器发送消息成功
}

interface IState {
    Name: string,
    RoleId: number,
    Desc: string,
    PP: Array<number>,
    // AP: Array<number>,
}

/**编辑角色
 *Created by liangzai on 19/09/02.
 */
export default class EditorForm extends React.Component<IProps, IState> {
    //旧的roleid
    private OldRoleId: string = "";

    constructor(props: IProps) {
        super(props);
        this.state = {
            Name: "",
            PP: [],
            // AP: [],
            RoleId: 0,
            Desc: '',
        }
    }

    componentWillReceiveProps(nextProps: any) {
        this.OldRoleId = nextProps.editData.RoleId;
        this.setState({
            Name: nextProps.editData.RoleName,
            PP: nextProps.editData.PP,
            Desc: nextProps.editData.Desc,
            RoleId: nextProps.editData.RoleId
        })
    }

    // con
    //角色名称
    private setRole(value: string) {
        this.setState({
            Name: value
        })
    }

    //角色排序
    private setRoleId(value: number) {
        this.setState({
            RoleId: value
        })
    }
    //角色描述
    private setDesc(value: string) {
        this.setState({
            Desc: value
        })
    }

    // //页面权限
    // private setPagePermission(value: any) {
    //     let newArr = Util.StrArrToNumArr(value)
    //     this.setState({
    //         PP: newArr
    //     })
    // }

    // //操作权限
    // private setOpinionPermission(value: any) {
    //     let newArr = Util.StrArrToNumArr(value)
    //     this.setState({
    //         AP: newArr
    //     })
    // }
    //编辑角色
    private editRole() {

        if (this.state.Name == '') {
            message.error('角色名称不能为空！');
            return;
        }

        if (this.state.RoleId < 0) {
            message.error('角色排序数字必须大于0！！！');
            return;
        }

        let postData = {
            token: StorageValue.Token,
            OldId: this.OldRoleId,
            RoleId: this.state.RoleId,
            RoleName: this.state.Name,
            PP: this.state.PP,
            // AP: this.state.AP,
            Desc: this.state.Desc
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/role/update", postData,
            (msg: any) => {
                let Result = msg.Result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.props.onSendApplySuccess();
                message.info("修改角色成功！");
            },
            (err: any) => {
                message.error(err);
            })
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 10 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            }
        }
        return (
            <Modal
                centered={true}
                visible={this.props.visible}
                title="编辑角色"
                okText="确定"
                cancelText='取消'
                onCancel={() => { this.props.onCancel() }}
                onOk={() => { this.editRole() }}
            >
                <Form layout='horizontal'>
                    <Form.Item label="角色名称" {...formItemLayout}>
                        <Input
                            value={this.state.Name}
                            defaultValue={this.props.editData.name}
                            onChange={(e) => { this.setRole(e.target.value) }}
                        />
                    </Form.Item>

                    <Form.Item label="角色排序" {...formItemLayout}>
                        <InputNumber
                            value={this.state.RoleId}
                            onChange={(value: any) => { this.setRoleId(value) }}
                        />
                    </Form.Item>
                    <Form.Item label="角色描述" {...formItemLayout}>
                        <Input
                            value={this.state.Desc}
                            onChange={(e) => { this.setDesc(e.target.value) }}
                        />
                    </Form.Item>
                </Form>
                {/* <div>
                    页面权限
                    <Tree
                        checkedKeys={this.state.PP}
                        treeData={PagePermissionData}
                        checkable={true}
                        onCheck={(e: any) => { this.setPagePermission(e) }}
                    ></Tree>
                </div>
                <Divider type='horizontal' />
                <div>
                    操作权限
                    <Tree
                        checkedKeys={this.state.AP}
                        treeData={OpinionPermissionData}
                        checkable={true}
                        onCheck={(e: any) => { this.setOpinionPermission(e) }}
                    ></Tree>
                </div> */}
            </Modal>
        )
    }
}