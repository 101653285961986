import React from 'react';
import { Table, Popconfirm, message, Divider, Button } from 'antd';
import { CDKClassifyItemData } from './CDKManager'
import CDKManager from './CDKManager';
import ChangeCDKeyClassifyView from './ChangeCDKeyClassifyView';
import AddCDKeyClassifyView from './AddCDKeyClassifyView';

export default class CDKClassifyListView extends React.Component {
    constructor() {
        super();
        this.columns = [
            { align: "center", title: "ID", dataIndex: "identity", key: "identity" },
            { align: "center", title: "分类名称", dataIndex: "name", key: "name" },
            { align: "center", title: "分类描述", dataIndex: "desc", key: "desc" },
            { align: "center", title: "使用状态", dataIndex: "status", key: "status" },
            {
                align: "center", title: "操作", dataIndex: "opinion", key: "opinion", render:
                    (text, record) =>
                        <div>
                            <a href="javascript:;" onClick={() => { this.OnUpdateCDKClick(record) }}>修改</a>
                            <Divider type='vertical' />
                            <Popconfirm title="确定删除?" onConfirm={() => this.deleteCategory(record.identity)}>
                                <a href="javascript:;">删除</a>
                            </Popconfirm>
                        </div>
            }
        ]
        this.state = {
            tableData: null,
            formVisible: false,
            addformVisuble: false,
        }
    }
    //修改CDK分类
    OnUpdateCDKClick(record) {
        console.log("OnUpdateCDKClick " + JSON.stringify(record))
        CDKManager.CurCDKClassifyItemData = new CDKClassifyItemData(record)
        this.setState({
            formVisible: true
        })
    }

    componentWillMount() {
        this.NetRefresh()
    }

    NetRefresh() {
        CDKManager.GetCategorylis((data) => {
            console.log("data" + JSON.stringify(data))
            this.setState({
                tableData: data
            })
        },
            () => {

            })
    }
    //删除CDK
    deleteCategory(key) {
        console.log(key);
        CDKManager.DelCategory(key, () => {
            const NewtableData = [...this.state.tableData];
            this.setState({
                tableData: NewtableData.filter(item => item.identity !== key)
            })
            message.info("删除CDK成功!");
        },
            (err) => {
                message.error(err);
            })
    }

    render() {
        return (
            <div>
                <Table
                    title={() => {
                        return (<div style={{ textAlign: "center", fontSize: 15 }}>
                            <b>CDK分类列表</b>
                            <Button
                                onClick={() => { this.setState({ addformVisuble: true }) }}
                                type='primary'
                                style={{
                                    float: "right"
                                }}>添加
                            </Button>
                        </div>)
                    }}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.tableData}
                    rowKey='identity'
                >
                </Table>

                {/* 修改cdk分类 */}
                <ChangeCDKeyClassifyView
                    onCancel={() => {
                        this.NetRefresh()
                        this.setState({ formVisible: false })
                    }}
                    onOk={() => {
                        this.NetRefresh()
                        this.setState({ formVisible: false })
                    }}
                    formVisible={this.state.formVisible}
                />

                {/* 添加cdk分类 */}
                <AddCDKeyClassifyView
                    onCancel={() => {
                        this.NetRefresh()
                        this.setState({ addformVisuble: false })
                    }}
                    onOk={() => {
                        this.NetRefresh()
                        this.setState({ addformVisuble: false })
                    }}
                    formVisible={this.state.addformVisuble}
                />
            </div>
        )
    }
}