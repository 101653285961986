import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Modal, message } from 'antd';
const { Option } = Select;


interface IProps {
    onCancel: Function
    onOk: Function
    formVisible: boolean
    editData: any
}

interface IState {
    stat: string
    data: any
    orderId: string
}


export default class ChangeSysMailData extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            stat: 'Reviewing',
            data: null,
            orderId: '',

        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.formVisible) {
            if (nextProps.editData.param) {

                this.setState({
                    data: JSON.parse(nextProps.editData.param),
                    orderId: nextProps.editData.orderId,
                    stat: nextProps.editData.state
                })

                console.log(this.state.stat)
                return;
            }
            this.setState({
                data: null,
                orderId: nextProps.editData.orderId,
                stat: nextProps.editData.state
            })
        }
    }


    SetState(value: string) {
        if (this.state.stat != "Reviewing") {
            message.error('已处理')

            this.setState({
                stat: ''
            })
            return;
        }

        this.setState({
            stat: value
        })
    }

    render() {
        const formItemStle = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }

        if (this.state.data == null) {
            return null
        }

        return (
            <Modal
                okText='确定'
                cancelText='取消'
                width="60%"
                onCancel={() => {
                    // if (this.state.stat != "Reviewing") {
                    //     this.props.onCancel('');
                    //     return;
                    // }
                    this.props.onCancel(this.state.stat);
                }}
                onOk={() => {
                    // if (this.state.stat != "Reviewing") {
                    //     this.props.onCancel('');
                    //     return;
                    // }

                    this.props.onOk(this.state.stat)
                }}
                visible={this.props.formVisible}
            >
                <Form style={{ textAlign: "center", width: "100%" }}>
                    <h1 style={{ fontSize: "30px" }}>地址信息</h1>
                    <Form.Item label='姓名:' {...formItemStle}>
                        <Input
                            placeholder="姓名"
                            prefix={<UserOutlined />}
                            value={this.state.data.name}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='手机号码:' {...formItemStle}>
                        <Input
                            placeholder="姓名"
                            prefix={<UserOutlined />}
                            value={this.state.data.Phone}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='地址:' {...formItemStle}>
                        <Input
                            placeholder="姓名"
                            prefix={<UserOutlined />}
                            value={this.state.data.Address}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='状态:' {...formItemStle}>
                        <Select
                            // defaultValue={this.state.stat}
                            value={this.state.stat}
                            onChange={(value: string) => { this.SetState(value) }}
                        >
                            <Option key={"Reviewing"} value={"Reviewing"}>{"待处理"}</Option>
                            <Option key={"Success"} value={"Success"}>{"发送"}</Option>
                            <Option key={"Reject"} value={"Reject"}>{"拒绝"}</Option>

                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}