import moment, { Moment } from "moment"
const DayMillisecond: number = 1000 * 60 * 60 * 24

export default class TimeUtil extends Date {

    //Moment 转string
    public static MomentToTimeString(time: Moment): any {
        if (time == null) {
            return null;
        }
        return time.format("YYYY-MM-DD")
    }

    public static convertDateFromString(dateString: string) {
        if (dateString) {
            var date = new Date(dateString.replace(/-/, "/"))
            return date;
        }
    }

    public static timeStringToNumber(time: string) {
        return this.MomentToTimestamp(this.TimestampToMoment(time))
    }

    //Moment 转时间戳 (number)
    public static MomentToTimestamp(moment: Moment): number {
        if (moment == null) {
            return 0
        }
        return moment.toDate().valueOf()
    }

    //时间戳(number)  或者时间（string）转Moment 
    public static TimestampToMoment(timeStamp: any): Moment {
        if (timeStamp == 0 || timeStamp == '')
            return moment(undefined)
        return moment(timeStamp)
    }

    //时间戳转时间
    public static TimestampToTime(timestamp: number, formatStr?: string) {
        if (timestamp == null || timestamp == undefined || timestamp == 0) {
            return ''
        }
        return this.Format(new Date(timestamp), formatStr)
    }

    //将时间转成相应格式  by ming.lei 2018.06.06
    public static Format(time: Date, format = 'yyyy/MM/dd hh:mm:ss') {
        var o: any = {
            "M+": time.getMonth() + 1,                      //month 
            "d+": time.getDate(),                           //day 
            "h+": time.getHours(),                          //hour 
            "m+": time.getMinutes(),                        //minute 
            "s+": time.getSeconds(),                        //second 
            "q+": Math.floor((time.getMonth() + 3) / 3),    //quarter 
            "S": time.getMilliseconds()                     //millisecond 
        }

        if (/(y+)/i.test(format)) {
            format = format.replace(RegExp.$1, (time.getFullYear() + "").substr(4 - RegExp.$1.length));
        }

        for (var k in o) {
            if (new RegExp("(" + k + ")").test(format)) {
                format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
            }
        }
        return format;
    }


    public static GetDayEnd(date: Date): number {
        return new Date(date.toLocaleDateString()).getTime() + DayMillisecond - 1;
    }

    public static GetDayBegin(date: Date): number {
        return new Date(date.toLocaleDateString()).getTime();
    }


}