export let GameTypeData = [{
    title: '所有场次',
    value: 0,
    key: 0,
    children: [
        {
            title: '无限场',
            value: "10101,10102",
            key: 101,
            children: [
                {
                    title: '初级',
                    value: "10101",
                    key: 10101,
                },
                {
                    title: '高级',
                    value: "10102",
                    key: 10102,
                },
            ]
        },
        {
            title: 'BOSS场',
            value: "10201",
            key: 102,
        },
    ]
}]