import React from 'react';
import { Table, Popconfirm, message, Divider, Button } from 'antd';
// import { Link } from 'react-router-dom';
import CostomMaliManager from '../CostomMaliManager';
import ChangeCostomMailData from './ChangeCostomMailData'
import { GetItemNameById } from '../../../UserInfo/EnumManager';
import AddCostomMailView from './AddCostomMailView';

export default class CostomMailListView extends React.Component {
    constructor() {
        super();
        this.columns = [
            {
                align: "center", title: "序号", dataIndex: "Index", key: "Index", width: 70, fixed: 'left', render: (text, record, index) => {
                    return index + 1
                }
            },
            {
                align: "center", title: "Id", dataIndex: "Identity", key: "Identity", width: 70, fixed: 'left'
            },
            {
                align: "center", title: "操作", dataIndex: "opinion", key: "opinion", fixed: 'left', width: 160, render:
                    (text, record) =>
                        <div>
                            <a href="javascript:;" onClick={() => this.updateCosMail(record)} >修改</a>
                            <Divider type="vertical" />
                            <Popconfirm title="确定删除?" onConfirm={() => this.deleteCosMail(record.Identity)}>
                                <a href="javascript:;">删除</a>
                            </Popconfirm>
                            <Divider type="vertical" />
                            <Popconfirm title="确定发送?" onConfirm={() => this.sendCosMail(record.Identity)}>
                                <a href="javascript:;">发送</a>
                            </Popconfirm>
                        </div>
            },
            {
                align: "center", title: "发送状态", dataIndex: "Status", key: "Status", width: 100, render: (text, record) => {
                    switch (record.Status) {
                        case "SUCESS":
                            return <div>成功</div>
                        case "FAIL":
                            return <div>失败</div>
                    }
                }
            },
            { align: "center", title: "发送时间", dataIndex: "SendTime", key: "SendTime", width: 180 },
            {
                align: "center", title: "类型", dataIndex: "Type", key: "Type", width: 180, render: (text, record) => {
                    switch (record.Type) {
                        case "ALL":
                            return <div>全员发送</div>
                        case "Users":
                            return <div>指定玩家发送</div>
                    }
                }
            },
            { align: "center", title: "创建时间", dataIndex: "CreateTime", key: "CreateTime", width: 200 },
            { align: "center", title: "发件人", dataIndex: "From", key: "From", width: 150 },
            { align: "center", title: "接收人ID", dataIndex: "To", key: "To", width: 200 },
            { align: "center", title: "邮件标题", dataIndex: "Title", key: "Title", width: 200 },
            { align: "center", title: "邮件内容", dataIndex: "Content", key: "Content" },
            {
                align: "center", title: "附件", dataIndex: "Attachment", key: "Attachment",
                render: (text, record) => {
                    let desc = ""
                    let attachment = JSON.parse(record.Attachment)
                    for (let index = 0; index < attachment.length; index++) {
                        const element = attachment[index];
                        let name = GetItemNameById(element.itemId)
                        desc += "[" + name + " x " + element.itemCount + "]\n"
                    }
                    return desc
                },
                ellipsis: true,
            },


            { align: "center", title: "发送反馈", dataIndex: "SendMsg", key: "SendMsg", ellipsis: true },

        ]
        this.state = {
            tableData: null,
            editingKey: '',
            editformVisible: false,
            addformVisible: false

        }
    }
    //修改表格数据
    componentWillMount() {
        this.NetRefresh()
    }

    NetRefresh() {
        CostomMaliManager.GetCostomMailList(
            (data) => {
                this.setState({
                    tableData: data
                })
            },
            () => {
            })
    }

    deleteCosMail(key) {
        CostomMaliManager.DeleteCostomMail(key, () => {
            const tableData = [...this.state.tableData];
            this.setState({
                tableData: tableData.filter(item => item.Identity !== key)
            })
            message.info("删除邮件成功");
        },
            (err) => {
                message.error(err);
            })
    }

    //修改邮件
    updateCosMail(record) {
        CostomMaliManager.CurCostomMailData = record;
        this.setState({
            editformVisible: true
        })
    }

    //发送邮件
    sendCosMail(key) {
        CostomMaliManager.SendCostomMail(key, () => {
            message.info("发送成功!");
        },
            (err) => {
                message.error(err);
            })
    }

    render() {
        return (
            <div>

                <Table
                    title={() => {
                        return (<div style={{ textAlign: "center", fontSize: 15 }}>
                            <b>自定义邮件列表</b>
                            <Button
                                onClick={() => { this.setState({ addformVisible: true }) }}
                                type='primary'
                                style={{
                                    float: "right"
                                }}>添加
                            </Button>
                        </div>)
                    }}
                    columns={this.columns}
                    dataSource={this.state.tableData}
                    bordered={true}

                    rowKey={(record, index) => { return index.toString() }}
                    scroll={{ x: 3000 }}
                />
                <ChangeCostomMailData
                    onCancel={() => {
                        this.NetRefresh()
                        this.setState({
                            editformVisible: false
                        })
                    }}
                    onOk={() => {
                        this.NetRefresh()
                        this.setState({
                            editformVisible: false
                        })
                    }}
                    formVisible={this.state.editformVisible}
                />

                <AddCostomMailView
                    onCancel={() => {
                        this.NetRefresh()
                        this.setState({
                            addformVisible: false
                        })
                    }}
                    onOk={() => {
                        this.NetRefresh()
                        this.setState({
                            addformVisible: false
                        })
                    }}
                    formVisible={this.state.addformVisible}
                />

            </div>
        )
    }
}