
import React from 'react';
import UserInfo from '../../UserInfo/UserInfo';
import PlatformInventoryManager from './PlatformInventoryManager';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Menu, Dropdown, Button, message } from 'antd';
import StorageValue from '../../Utils/StorageValue';

export default class PlatformInventoryView extends React.Component {
    constructor() {
        super();
        this.state = {
            tableData: null,
            GroupIdsDat: null,
            allGroupIdKeys: null,
            allTypeKeys: null,
            filteredInfo: null,
            sortedInfo: null,
            searchGroupid: null,
            searchType: null
        }
    }

    componentWillMount() {
        PlatformInventoryManager.InitInventoryData(() => {
            this.setState({
                tableData: PlatformInventoryManager.allInventoryData,
                allTypeKeys: PlatformInventoryManager.allTypeKeys,
                allGroupIdKeys: PlatformInventoryManager.allGroupIdKeys
            })
        }, (err) => {
            message.error(err)
        });
    }

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', filters);
        PlatformInventoryManager.groupid = null;
        PlatformInventoryManager.type = null;
        if (filters.groupid && filters.groupid.length > 0) {
            PlatformInventoryManager.groupid = filters.groupid;
        }
        if (filters.type && filters.type.length > 0) {
            PlatformInventoryManager.type = filters.type[0];
        }
        PlatformInventoryManager.InitInventoryData(() => {

            console.log(PlatformInventoryManager.allInventoryData[0])
            this.setState({
                tableData: PlatformInventoryManager.allInventoryData,
                filteredInfo: filters,
                sortedInfo: sorter,
            })
        }, (err) => {
            message.error(err)
        });

    };

    render() {

        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        this.columns = [
            {
                align: "center", title: "渠道选择",
                dataIndex: "groupid", key: "groupid", width: 120,
                filters: this.state.allGroupIdKeys,
                filteredValue: filteredInfo.groupid || null,
                onFilter: (value, record) => record.groupid == value || record.groupid == "统计",
                sorter: (a, b) => a.groupid - b.groupid,
                sortOrder: sortedInfo.columnKey === 'groupid' && sortedInfo.order,
                defaultSortOrder: 'ascend',
            },
            {
                align: "center", title: "场次选择",
                dataIndex: "type", key: "type", width: 120,
                filters: this.state.allTypeKeys,
                filteredValue: filteredInfo.type || null,
                onFilter: (value, record) => record.type == value || record.type == "所有",
                filterMultiple: false
            },
            { align: "center", title: "当前库存", dataIndex: "curInventory", key: "curInventory", width: 100 },
            { align: "center", title: "库存比", dataIndex: "stock", key: "stock", width: 100 },
            { align: "center", title: "系统抽水", dataIndex: "profit", key: "profit", width: 100 },
            { align: "center", title: "系统输赢", dataIndex: "winlose", key: "winlose", width: 100 },
            { align: "center", title: "系统收入", dataIndex: "income", key: "income", width: 100 },
            { align: "center", title: "系统支出", dataIndex: "outlay", key: "outlay", width: 100 },
            { align: "center", title: "掉落", dataIndex: "drop", key: "drop", width: 100, },
        ]

        return (
            <div>
                <Table
                    defaultPageSize={15}
                    onChange={this.handleChange}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.tableData}
                    scroll={{ x: 1000, y: 1000 }}
                    footer={() => '* product by zhjd'}>

                </Table>

            </div>
        )
    }
} 