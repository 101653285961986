import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";

export class TorpedoData {
    constructor(data?: any) {
        if (data == null) {
            return;
        }
        this.playerId = data.playerId;
        this.groupid = data.groupid.toString();
        this.mail = data.mail;
        this.game = data.game;
        this.send = data.send;
        this.recv = data.recv;
        this.exchange = data.exchange;
        this.cdkey = data.cdkey;
        this.vip = data.vip;
        this.now = data.now;
    }

    public playerId: string = ''
    public groupid: string = ''
    public mail: number = 0
    public game: number = 0
    public send: number = 0
    public recv: number = 0
    public exchange: number = 0
    public cdkey: number = 0
    public vip: number = 0
    public now: number = 0
}


export default class TorpedoDataManager {
    public static allTorpedoData: Array<TorpedoData>

    private static allGroupIdKey: Array<string>
    public static allGroupIdKeys: Array<{ text: string, value: string }>

    public static groupid: any = null;
    public static allPeo: number = 0;

    private static GetTotal(): TorpedoData {
        let target = new TorpedoData();
        target.groupid = "合计" //统计
        this.allPeo = 0

        if (this.groupid != null) {
            for (let index = 0; index < this.allTorpedoData.length; index++) {
                const element = this.allTorpedoData[index];
                if (this.groupid.indexOf(element.groupid) >= 0) {
                    target.mail += element.mail;
                    target.game += element.game;
                    target.send += element.send;
                    target.recv += element.recv;
                    target.exchange += element.exchange;
                    target.cdkey += element.cdkey;
                    target.vip += element.vip;
                    target.now += element.now;
                    this.allPeo += 1;
                }
            }
            target.playerId = this.allPeo.toString();
        }

        if (this.groupid == null) {
            for (let index = 0; index < this.allTorpedoData.length; index++) {
                const element = this.allTorpedoData[index];
                target.mail += element.mail;
                target.game += element.game;
                target.send += element.send;
                target.recv += element.recv;
                target.exchange += element.exchange;
                target.cdkey += element.cdkey;
                target.vip += element.vip;
                target.now += element.now;
                this.allPeo += 1;
            }

            target.playerId = this.allPeo.toString();
        }
        return target
    }

    public static GetData(success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "api/torpedo",
            {
                token: StorageValue.Token,
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (msg.data.length <= 0) {
                    if (fail) {
                        fail("今日暂无用户数据")
                    }
                    return;
                }

                console.log("GetMagicRetentionData msg:" + JSON.stringify(msg))

                this.allGroupIdKey = new Array<string>();
                this.allTorpedoData = new Array<TorpedoData>();
                for (let i = 0; i < msg.data.length; i++) {
                    let item = new TorpedoData(msg.data[i])
                    this.allTorpedoData.push(item);

                    if (this.allGroupIdKey.indexOf(item.groupid) < 0) {
                        this.allGroupIdKey.push(item.groupid)
                    }
                }
                this.allGroupIdKey.sort((a, b) => { return parseInt(a) - parseInt(b) })
                this.allGroupIdKeys = new Array<{ text: string, value: string }>()
                for (let index = 0; index < this.allGroupIdKey.length; index++) {
                    const element = this.allGroupIdKey[index];
                    this.allGroupIdKeys.push({ text: element.toString(), value: element.toString() })
                }

                console.log("allGroupIdKeys=" + JSON.stringify(this.allGroupIdKeys));

                let total = this.GetTotal();
                console.log(total + "total");

                this.allTorpedoData.unshift(total);
                if (success) {
                    success(this.allTorpedoData)
                }
            },
            (err: any) => {
                return;
            })
    }
}