import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select, TreeSelect } from 'antd';
import { subDays } from 'date-fns'
import moment, { now } from 'moment';
import TimeUtil from '../../../Utils/TimeUtil';
import StorageValue from '../../../Utils/StorageValue';
import NetUtil from '../../../Utils/NetUtil';
import LoaderView from '../../../components/Loader/LoaderView';
import ExportExcel from '../../../Utils/ExcelExport';
import { GameTypeData } from '../../../Utils/DefaultValue';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: any
    begin: string
    end: string
}

/**
 * 充值数据
 *Created by liangzai on 19/09/12.
 */
export default class GroupDataView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private now = new Date();
    private yesterday = subDays(this.now, 4)


    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
            end: TimeUtil.Format(this.now, 'yyyy-MM-dd'),
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        this.setState({
            LogsList: null
        })
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
        }
        NetUtil.Post(NetUtil.BaseUrl + "analysis/recharge", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                let data: Array<any> = [];
                for (let index = 0; index < msg.data.length; index++) {
                    const element = msg.data[index];
                    if(!element.r1) element.r1 = 0;
                    if(!element.r2) element.r2 = 0;
                    if(!element.r3) element.r3 = 0;
                    if(!element.r4) element.r4 = 0;
                    if(!element.r5) element.r5 = 0;
                    if(!element.r6) element.r6 = 0;
                    if(!element.r7) element.r7 = 0;
                    if(!element.r8) element.r8 = 0;
                    if(!element.r9) element.r9 = 0;

                    if(!element.r201) element.r201 = 0;
                    if(!element.r301) element.r301 = 0;
                    if(!element.r401) element.r401 = 0;
                    if(!element.r501) element.r501 = 0;
                    if(!element.r601) element.r601 = 0;
                    if(!element.r701) element.r701 = 0;
                    if(!element.r801) element.r801 = 0;
                    if(!element.r802) element.r802 = 0;
                    if(!element.r803) element.r803 = 0;
                    if(!element.r804) element.r804 = 0;
                    if(!element.r805) element.r805 = 0;

                    data.push(element)
                }


                this.setState({
                    LogsList: data
                })
            },
            (err: any) => {
                throw (err)
            })
    }

    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: TimeUtil.Format(this.yesterday, 'yyyy-MM-dd'),
                end: TimeUtil.Format(this.now, 'yyyy-MM-dd')
            })
            return;
        }
        let begindata = TimeUtil.MomentToTimeString(date[0]);
        let enddata = TimeUtil.MomentToTimeString(date[1]);

        this.setState({
            begin: begindata,
            end: enddata
        })
    }

    render() {

        this.columns = [
            { align: "center", title: "日期", dataIndex: "date", key: "date" },
            { align: "center", title: "6元", dataIndex: "r1", key: "r1" },
            { align: "center", title: "18元", dataIndex: "r2", key: "r2" },
            { align: "center", title: "30元", dataIndex: "r3", key: "r3" },
            { align: "center", title: "68元", dataIndex: "r4", key: "r4" },
            { align: "center", title: "118元", dataIndex: "r5", key: "r5" },
            { align: "center", title: "198元", dataIndex: "r6", key: "r6" },
            { align: "center", title: "328元", dataIndex: "r7", key: "r7" },
            { align: "center", title: "648元", dataIndex: "r8", key: "r8" },
            { align: "center", title: "1千", dataIndex: "r9", key: "r9" },
            { align: "center", title: "首充", dataIndex: "r201", key: "r201" },
            { align: "center", title: "星际", dataIndex: "r301", key: "r301" },
            { align: "center", title: "每日", dataIndex: "r401", key: "r401" },
            { align: "center", title: "周卡", dataIndex: "r501", key: "r501" },
            { align: "center", title: "月卡", dataIndex: "r601", key: "r601" },
            { align: "center", title: "季卡", dataIndex: "r701", key: "r701" },
            { align: "center", title: "12元", dataIndex: "r801", key: "r801" },
            { align: "center", title: "328元", dataIndex: "r802", key: "r802" },
            { align: "center", title: "648元", dataIndex: "r803", key: "r803" },
            { align: "center", title: "888元", dataIndex: "r804", key: "r804" },
            { align: "center", title: "庆典1", dataIndex: "r901", key: "r901" },
            { align: "center", title: "庆典2", dataIndex: "r902", key: "r902" },
        ]


        let tableView = <LoaderView />;

        if (this.state.LogsList != null) {
            tableView =
                <Table
                    pagination={false}
                    scroll={{ x: 1000, y: 700 }}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
        }

        return (
            <div>
                <Form layout='inline'>

                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 6))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>

                <Divider type='horizontal' />
                {tableView}

            </div>
        );
    }
}