import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Input, message } from 'antd';
import NetUtil from '../../../Utils/NetUtil';
import StorageValue from '../../../Utils/StorageValue';
const { Option } = Select;

interface IProps {
    visible: boolean
    onCancel: Function
    onSendApplySuccess: Function
    roleList: Array<string>
}

interface IState {
    account: string,
    pwd: string,
    role: string,
    desc: string,

}

/** 添加账号
 *Created by liangzai on 19/09/02.
 */
export default class AddAccountModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            account: '',
            pwd: '',
            role: '',
            desc: '',
        }
    }

    componentWillReceiveProps(nextProps: any) {
        this.setState({
            account: '',
            pwd: '',
            role: nextProps.roleList[0] ? nextProps.roleList[0] : '',
            desc: '',
        })
    }

    //账号
    private setAccount(value: string) {
        this.setState({
            account: value
        })
    }

    //密码
    private setPwd(value: string) {
        this.setState({
            pwd: value
        })
    }

    //描述
    private setDesc(value: string) {
        this.setState({
            desc: value
        })
    }

    //权限->对应角色ID
    private setRoleId(value: string) {
        this.setState({
            role: value
        })
    }
    //添加账号
    private OnBtnAddClick() {
        if (this.state.account == '') {
            message.error('账号不能为空！');
            return;
        }

        // if (!RegExpCheck.Account.test(this.state.Account)) {
        //     message.error('账号不能有中文和特殊字符！')
        //     return
        // }

        if (this.state.pwd == '') {
            message.error('密码不能为空！');
            return;
        }

        // if (!RegExpCheck.Account.test(this.state.Pwd)) {
        //     message.error('密码不能有中文和特殊字符！')
        //     return
        // }

        let postData = {
            token: StorageValue.Token,
            account: this.state.account,
            pwd: this.state.pwd,
            role: this.state.role,
            desc: this.state.desc
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/user/create", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                message.info('创建用户成功！')
                this.props.onSendApplySuccess();
            },
            (err: any) => {
                throw (err);
            })
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 10 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            }
        }

        return (
            <Modal
                centered={true}
                visible={this.props.visible}
                title="添加账号"
                okText="添加"
                cancelText='取消'
                onCancel={() => { this.props.onCancel() }}
                onOk={() => {
                    this.OnBtnAddClick()
                }}
            >
                <Form layout='horizontal'>
                    <Form.Item label="账号" {...formItemLayout}>
                        <Input
                            value={this.state.account}
                            onChange={(e: any) => { this.setAccount(e.target.value) }}
                        />
                    </Form.Item>

                    <Form.Item label="密码" {...formItemLayout}>
                        <Input
                            value={this.state.pwd}
                            onChange={(e: any) => { this.setPwd(e.target.value) }}
                        />
                    </Form.Item>

                    {/* <Form.Item label="姓名" {...formItemLayout}>
                        <Input
                            value={this.state.name}
                            onChange={(e:any) => { this.setName(e.target.value) }}
                        />
                    </Form.Item> */}
                    {/* <Form.Item label="部门" {...formItemLayout}>
                        <Input
                            value={this.state.deaprtment}
                            onChange={(e:any) => { this.setDeaprtment(e.target.value) }}
                        />
                    </Form.Item> */}
                    {/* 
                    <Form.Item label="账号状态" {...formItemLayout}>
                         <Select
                            defaultValue = {this.state.RoleId}
                            size={"large"}
                            onSelect={(key: string) => { this.setAccState(key) }}
                        >
                            <Option key="1" value={"true"}>{"启用"}</Option>
                            <Option key="2" value={"false"}>{"禁用"}</Option>
                        </Select>
                    </Form.Item> */}

                    <Form.Item label="角色" {...formItemLayout}>
                        <Select
                            value={this.state.role}
                            size={"large"}
                            onSelect={(key: string) => { this.setRoleId(key) }}
                        >
                            {this.props.roleList.map((item) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label="描述" {...formItemLayout}>
                        <Input
                            value={this.state.desc}
                            onChange={(e: any) => { this.setDesc(e.target.value) }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}