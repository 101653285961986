import React from 'react';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Select, Button, Modal } from 'antd';
import CostomMaliManager from './../CostomMaliManager'
const { Option } = Select;
export default class AddCostomMailView extends React.Component {
    constructor() {
        super();
        this.state = {
            ID: null,
            type: "Users",
            title: null,
            content: null,
            from: null,
            To: null,
            attach: [],
            acctchNum: 0,
            ItemInfo: null,
        }
    }

    componentWillMount() {
        CostomMaliManager.GetItemInfo(
            (data) => {
                this.setState({
                    ItemInfo: data
                })
            },
            () => {
            })
    }

    //ID
    setID(value) {
        this.setState({
            ID: value
        })
    }
    //类型
    setType(value) {
        console.log(value)
        this.setState({
            type: value
        })
    }
    //标题
    setTitle(value) {
        this.setState({
            title: value
        })
    }

    //接收人Id
    setTo(value) {
        if (value.indexOf('，') >= 0) {
            message.error('你输入有误，请重新输入！')
            return;
        }
        let newToArr = value.split(',');
        let NewTo = [...newToArr]
        this.setState({
            To: NewTo
        })
    }

    //ID邮件内容
    setcontent(value) {
        this.setState({
            content: value
        })
    }
    //发件人姓名
    setFrom(value) {
        this.setState({
            from: value
        })
    }

    //添加附件
    HandleAddAttach() {
        let data = {
            itemId: null,
            itemCount: null
        }
        this.state.acctchNum += 1;
        let attochData = [...this.state.attach];
        attochData.push(data);
        this.setState({
            attach: attochData
        })
    }
    //设置附件id
    setAttachID(index, value) {
        let attachData = [...this.state.attach];
        attachData[index].itemId = value;
        this.setState({
            attach: attachData
        })
    }
    //设置附件数量
    setAttachNumber(index, value) {
        let attachData = [...this.state.attach];
        attachData[index].itemCount = value;
        this.setState({
            attach: attachData
        })
    }
    //删除附件
    deleteAttach(index) {
        this.state.acctchNum -= 1;
        this.state.attach.splice(index, 1);
        let attochData = this.state.attach;
        this.setState({
            attach: attochData
        })
    }

    //重置按钮
    handleReast() {
        this.setState({
            ID: null,
            type: "User",
            title: null,
            content: null,
            from: null,
            To: null,
            attach: [],
            acctchNum: 0
        })
    }
    //添加
    handleAddMail() {
        CostomMaliManager.AddCostomMail(this.state.ID, this.state.type, this.state.title, this.state.content, this.state.from, this.state.To, this.state.attach,
            () => {
                message.info("自定义邮件添加成功！");
            },
            (err) => {
                message.info(err);
            })
    }

    render() {

        const formItemStle = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }
        return (
            <Modal
                okText='修改'
                cancelText='取消'
                width="60%"
                onCancel={() => { this.props.onCancel() }}
                onOk={() => { this.handleAddMail() }}
                visible={this.props.formVisible}
            >
                <Form style={{ textAlign: "center", width: "100%" }}>
                    <h1 style={{ fontSize: "30px" }}>添加自定义邮件</h1>
                    <Form.Item label='ID' {...formItemStle}>
                        <Input
                            placeholder="ID:1->无穷大"
                            prefix={<UserOutlined />}
                            value={this.state.ID}
                            onChange={(e) => this.setID(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='类型' {...formItemStle}>
                        <Select
                            defaultValue='Users'
                            size={"large"}

                            onChange={(key) => { this.setType(key) }}
                        >
                            <Option value="Users">Users</Option>
                            <Option value="ALL">All</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='标题' {...formItemStle}>
                        <Input

                            placeholder="邮件标题"
                            prefix={<EyeOutlined />}
                            value={this.state.title}
                            onChange={(e) => this.setTitle(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='邮件内容' {...formItemStle}>
                        <Input

                            placeholder="邮件内容"
                            prefix={<EyeOutlined />}
                            value={this.state.content}
                            onChange={(e) => this.setcontent(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='发件人名字' {...formItemStle}>
                        <Input

                            placeholder="发件人名字"
                            prefix={<EyeOutlined />}
                            value={this.state.from}
                            onChange={(e) => this.setFrom(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='接收人Id' {...formItemStle}>
                        <Input

                            placeholder="接收人Id"
                            prefix={<EyeOutlined />}
                            value={this.state.To}
                            onChange={(e) => this.setTo(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    {this.state.attach.map((item, index) => {
                        return (
                            <Form.Item key={index}>
                                <Select
                                    // defaultValue='Users'
                                    style={{ width: '40%', marginRight: "2%" }}
                                    size={"large"}
                                    value={this.state.attach[index].itemId}
                                    onChange={(value) => this.setAttachID(index, value)}
                                    defaultValue={''}
                                >
                                    <Option key={''} value={''}>{"请选择游戏道具"}</Option>
                                    {this.state.ItemInfo.map((item) => {
                                        return <Option key={item.identity} value={item.identity}>{item.name}</Option>
                                    })}
                                    {/* <Option value="Users">Users</Option>
                                    <Option value="All">All</Option> */}
                                </Select>
                                {/* <Input
                                    placeholder="道具ID"
                                    style={{ width: '18%', marginRight: "2%" }}
                                    value={this.state.attach[index].ItemId}
                                    onChange={(e) => this.setAttachID(index, e.target.value)}
                                    size="large"
                                /> */}
                                <Input
                                    placeholder="道具数量"
                                    style={{ width: '40%', marginRight: "2%" }}
                                    value={this.state.attach[index].itemCount}
                                    onChange={(e) => this.setAttachNumber(index, e.target.value)}
                                    size="large"
                                />
                                <Button type="primary" onClick={() => { this.deleteAttach(index) }}>
                                    删除附件
                            </Button>
                            </Form.Item>
                        )
                    })}
                    <Form.Item>
                        <Button type="primary" onClick={() => { this.HandleAddAttach() }}>
                            添加附件
                        </Button>
                    </Form.Item>

                    {/* <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '10%', marginRight: 100 }}>
                            立即添加
                        </Button>
                        <Button type="primary" onClick={() => { this.handleReast() }} style={{ width: '10%' }}>
                            重置
                        </Button>
                    </Form.Item> */}
                </Form>
            </Modal>
        );
    }
}