import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Select, message, Checkbox } from 'antd';

import Table, { ColumnProps } from 'antd/lib/table';
import NetUtil from '../../../Utils/NetUtil';
import { EnumPage, EnumPageOption } from '../../../UserInfo/EnumManager';
import { PagePermissionData } from '../../../UserInfo/PagePermissionData';
import StorageValue from '../../../Utils/StorageValue';
import EnumUtil from '../../../Utils/EnumUtil';
const { Option } = Select;

interface IProps {
    visible: boolean
    onCancel: Function              //取消
    onSendApplySuccess: Function    ////向服务器发送消息成功
}

interface IState {
    RoleName: string,
    Desc: string,
    Data: Array<any>,
    // AP: Array<any>,    //审批权限
    // OP: Array<any>,    //操作权限   
}

/**
 * 添加角色
 *Created by liangzai on 19/09/02.
 */
export default class AddPermissionsModal extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    constructor(props: IProps) {
        super(props);
        this.state = {
            RoleName: '',
            Desc: '',
            Data: PagePermissionData,
        }
    }

    componentWillReceiveProps(nextProps: any) {
        this.setState({
            RoleName: '',
            Desc: '',
        })
    }

    //角色名称
    private setRole(value: string) {
        this.setState({
            RoleName: value
        })
    }

    //角色描述
    private setDesc(value: string) {
        this.setState({
            Desc: value
        })
    }

    //添加角色
    private addRole() {
        if (this.state.RoleName == '') {
            message.error('角色名称不能为空！');
            return;
        }

        let pp = Object.create(null);
        this.state.Data.forEach((item: any) => {
            let value = 0
            if (item.find)
                value += 1 << EnumPageOption.find;
            if (item.updata)
                value += 1 << EnumPageOption.updata
            // if (item.Del)
            //     value += 1 << EnumPageOption.Del

            pp[item.Page.toString()] = value
        })

        let postData = {
            token: StorageValue.Token,
            name: this.state.RoleName,
            pp: JSON.parse(JSON.stringify(pp)),
            desc: this.state.Desc
        }
        NetUtil.Post(NetUtil.BaseUrl + "setting/role/create", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }
                this.props.onSendApplySuccess();
                message.info("角色添加成功！");
            },
            (err: any) => {
                throw err
                // message.error(err);
            })
    }

    //查看
    private setFind(index: EnumPage, value: boolean) {
        let tempData = this.state.Data;
        tempData.forEach(item => {
            if (item.Page == index) {
                item.find = value;
            }
        })

        this.setState({
            Data: tempData
        })
    }
    //修改
    private setUpdata(index: EnumPage, value: boolean) {
        let tempData = this.state.Data;
        tempData.forEach(item => {
            if (item.Page == index) {
                if (item.Page == index) {
                    item.updata = value;
                }
            }
        })
        this.setState({
            Data: tempData
        })
    }

    // private setDel(index: EnumPage, value: boolean) {
    //     let tempData = this.state.Data;
    //     tempData.forEach(item => {
    //         if (item.Page == index) {
    //             if (item.Page == index) {
    //                 item.Del = value;
    //             }
    //         }
    //     })
    //     this.setState({
    //         Data: tempData
    //     })
    // }

    render() {

        this.columns = [
            {
                align: "center", title: "页面名称", dataIndex: "Page", key: "Page", width: '400px', render:
                    (text: any, record: any) => {
                        return <div>{EnumUtil.GetPageType(text)}</div>
                    }
            },
            {
                align: "center", title: "查看", dataIndex: "find", key: "find", render:
                    (text: any, record: any, index: number) => {
                        return <Checkbox
                            onChange={(value: any) => { this.setFind(record.Page, value.target.checked) }}
                            checked={text}
                        />
                    }
            },
            {
                align: "center", title: "修改", dataIndex: "updata", key: "updata", render:
                    (text: any, record: any, index: number) => {
                        return <Checkbox
                            onChange={(value: any) => { this.setUpdata(record.Page, value.target.checked) }}
                            checked={text}
                        />
                    }
            },
        ]
        const formItemLayout = {
            labelCol: {
                xs: { span: 10 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            }
        }
        return (
            <Modal
                width={"60%"}
                centered={true}
                visible={this.props.visible}
                title="添加角色"
                okText="添加"
                cancelText='取消'
                onCancel={() => { this.props.onCancel() }}
                onOk={() => { this.addRole() }}
            >
                <Form layout='horizontal'>
                    <Form.Item label="角色名称" {...formItemLayout}>
                        <Input
                            value={this.state.RoleName}
                            onChange={(e) => { this.setRole(e.target.value) }}
                        />
                    </Form.Item>

                    <Form.Item label="角色描述" {...formItemLayout}>
                        <Input
                            value={this.state.Desc}
                            onChange={(e) => { this.setDesc(e.target.value) }}
                        />
                    </Form.Item>
                </Form>

                <Table
                    scroll={{ y: 400 }}
                    pagination={false}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.Data}
                    rowKey={(record: any, index: any) => { return index.toString() }} />
            </Modal>
        )
    }
}