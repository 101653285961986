import React from 'react';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Select, Button, Modal } from 'antd';
import CDKManager from './CDKManager';
const { Option } = Select;
/**
 * 修改CDK分类
*/
export default class ChangeCDKeyClassifyView extends React.Component {
    constructor() {
        super();
        this.state = {
            id: null,
            name: null,
            des: null,
            staus: 'Y'
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.formVisible) {
            this.setState({
                id: CDKManager.CurCDKClassifyItemData.identity ? CDKManager.CurCDKClassifyItemData.identity : 0,
                name: CDKManager.CurCDKClassifyItemData.name ? CDKManager.CurCDKClassifyItemData.name : '',
                des: CDKManager.CurCDKClassifyItemData.desc ? CDKManager.CurCDKClassifyItemData.desc : '',
                staus: CDKManager.CurCDKClassifyItemData.status ? CDKManager.CurCDKClassifyItemData.status : "Y"
            })
        }
    }
    //名称
    setName(value) {
        this.setState({
            name: value
        })
    }
    //描述
    setDes(value) {
        this.setState({
            des: value
        })
    }
    //状态
    handStausChange(value) {
        this.setState({
            staus: value
        })
    }
    //修改cdK分类
    handleUpdateCdkey() {
        CDKManager.updateCategory(this.state.id, this.state.name, this.state.staus, this.state.des, () => {
            message.info("CDK分类修改成功！");
            this.props.onOk()
        }, (err) => {
            message.info(err);
        })
    }

    render() {
        const formItemStle = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }
        return (
            <Modal
                okText='修改'
                cancelText='取消'
                width="60%"
                onCancel={() => { this.props.onCancel() }}
                onOk={() => { this.handleUpdateCdkey() }}
                visible={this.props.formVisible}
            >
                <Form style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "30px" }}>修改CDK分类</h1>
                    <Form.Item label='ID:' {...formItemStle}>
                        <Input
                            placeholder="请输入ID..."
                            prefix={<UserOutlined />}
                            value={this.state.id}
                            // onChange={(e) => this.setId(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='名称:' {...formItemStle}>
                        <Input
                            placeholder="请输入名称..."
                            prefix={<EyeOutlined />}
                            value={this.state.name}
                            onChange={(e) => this.setName(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                
                    <Form.Item label='描述:' {...formItemStle}>
                        <Input

                            placeholder="描述..."
                            prefix={<EyeOutlined />}
                            value={this.state.des}
                            onChange={(e) => this.setDes(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='状态:' {...formItemStle}>
                        <Select
                            value={this.state.staus}
                            size={"large"}
                            onSelect={(key) => { this.handStausChange(key) }}
                        >
                            <Option value="Y">启用</Option>
                            <Option value="N">禁用</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}