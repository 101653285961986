import React from 'react'
import LoaderStyle from './loader.module.css'
import { Spin } from 'antd';

export default class LoaderView extends React.Component {
    render() {
        return (
            <div className={LoaderStyle['loader-wrapper']}>
                <div className={LoaderStyle['loader-content']}>
                    <Spin tip="加载中..." />
                </div>
            </div>
        )
    }
}