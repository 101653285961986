import NetUtil from "../../Utils/NetUtil";
import StorageValue from "../../Utils/StorageValue";

export default class AccountManger {

    public static CreateAccount(account: string, pwd: string, roleId: string, desc: string, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "setting/user/create",
            {
                token: StorageValue.Token,
                account: account,
                pwd: pwd,
                roleId: roleId,
                desc: desc
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success()
                }
            },
            (err: any) => {
                return;
            })
    }

    public static RemoveAccount(account: string, success: Function, fail: Function) {
        console.log(account)

        NetUtil.Post(NetUtil.BaseUrl + "setting/user/remove",
            {
                token: StorageValue.Token,
                account: account,
            },
            (msg: any) => {
                let result = msg.result;
                console.log(result + "aaaaaaaaaa")
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success()
                }
            },
            (err: any) => {
                return;
            })
    }

    public static SelectAccount(success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "setting/user/list",
            {
                token: StorageValue.Token,
            },
            (msg: any) => {
                let result = msg.result;

                if (result != '0') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }

    public static ChangePass(pwd: string, oldPws: string, success: Function, fail: Function) {
        NetUtil.Post(NetUtil.BaseUrl + "setting/user/change",
            {
                token: StorageValue.Token,
                oldPwd: oldPws,
                pwd: pwd,
            },
            (msg: any) => {
                let result = msg.result;
                if (result != 'OK') {
                    if (fail) {
                        fail(result)
                    }
                    return;
                }

                if (success) {
                    success(msg.data)
                }
            },
            (err: any) => {
                return;
            })
    }
}