import React from 'react';
import { Table, Popconfirm, message, Button, Divider } from 'antd';
import SystemMailManager from '../SystemMailManager';
import ChangeSysMailData from './ChangeSysMailData';
import { GetItemNameById } from '../../../UserInfo/EnumManager';
import AddSystemMailView from './AddSystemMailView';

export default class SysMailListView extends React.Component {
    constructor() {
        super();
        this.columns = [
            {
                align: "center", title: "序号", dataIndex: "Index", key: "Index", width: 70, fixed: 'left', render: (text, record, index) => {
                    return index + 1
                }
            },
            {
                align: "center", title: "Id", dataIndex: "Identity", key: "Identity", width: 70, fixed: 'left'
            },
            {
                align: "center", title: "操作", dataIndex: "opinion", key: "opinion", width: 110, fixed: 'left', render:
                        (text, record) =>
                            <div>
                                {/* <a href="javascript:;" onClick={() => this.updateSysMail(record)} >修改</a> */}
                                <Divider type="vertical" />
                                <Popconfirm title="确定删除?" onConfirm={() => this.deleteSysMail(record.Identity)}>
                                    <a href="javascript:;">删除</a>
                                </Popconfirm>
                            </div>
            },
            { align: "center", title: "发件人", dataIndex: "From", key: "From", width: 150, },
            { align: "center", title: "邮件标题", dataIndex: "Title", key: "Title" },
            { align: "center", title: "邮件内容", dataIndex: "Content", key: "Content" },
            {
                align: "center", title: "附件", dataIndex: "Attachment", key: "Attachment",
                render: (text, record) => {
                    let desc = ""
                    let attachment = JSON.parse(record.Attachment)
                    for (let index = 0; index < attachment.length; index++) {
                        const element = attachment[index];
                        let name = GetItemNameById(element.itemId)
                        desc += "[" + name + " x " + element.itemCount + "]\n"
                    }
                    return desc
                },
            },
        ]
        this.state = {
            tableData: null,
            editingKey: '',
            formVisible: false,
            addformVisible: false
        }
    }

    NetRefresh() {
        SystemMailManager.GetSystemMailList((data) => {
            this.setState({
                tableData: data
            })
        }, () => {
        })
    }

    componentWillMount() {
        this.NetRefresh()
    }

    deleteSysMail(key) {
        SystemMailManager.DeleteSysMailList(key,
            () => {
                const tableData = [...this.state.tableData];
                this.setState({
                    tableData: tableData.filter(item => item.Identity != key)
                })
                message.info("删除邮件成功");
            },
            (err) => {
                message.error(err);
            })
    }

    //修改系统邮件
    updateSysMail(record) {
        console.log(JSON.stringify(record) + ">>>>>>>>record" + record.Identity)
        SystemMailManager.CurSysMailData = record;
        this.setState({
            formVisible: true
        })
        console.log(SystemMailManager.CurSysMailData)
    }

    render() {
        return (
            <div>
                <Table
                    title={() => {
                        return (<div style={{ textAlign: "center", fontSize: 15 }}>
                            <b>系统邮件列表</b>
                            <Button
                                onClick={() => { this.setState({ addformVisible: true }) }}
                                type='primary'
                                style={{
                                    float: "right"
                                }}>添加
                            </Button>
                        </div>)
                    }}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.tableData}
                    rowKey={(record, index) => { return index.toString() }}
                    scroll={{ x: 2000 }}
                />

                <ChangeSysMailData
                    onCancel={() => {
                        this.NetRefresh()
                        this.setState({
                            formVisible: false
                        })
                    }}
                    onOk={() => {
                        this.NetRefresh()
                        this.setState({
                            formVisible: false
                        })
                    }}
                    formVisible={this.state.formVisible}
                />

                <AddSystemMailView
                    onCancel={() => {
                        this.NetRefresh()
                        this.setState({
                            addformVisible: false
                        })
                    }}
                    onOk={() => {
                        this.NetRefresh()
                        this.setState({
                            addformVisible: false
                        })
                    }}
                    formVisible={this.state.addformVisible}
                />
            </div>
        )
    }
}