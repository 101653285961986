import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select } from 'antd';
import { subDays } from 'date-fns'
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import NetUtil from '../../Utils/NetUtil';
import StorageValue from '../../Utils/StorageValue';
import ExportExcel from '../../Utils/ExcelExport';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    begin: any
    end: any
    playerId: string
    channel: String

}

/**
 * 福利日志
 *Created by liangzai on 21/04/07.
 */
export default class HbLogView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private nowTime: number = TimeUtil.GetDayEnd(new Date());
    private yesterday = TimeUtil.GetDayBegin(subDays(this.nowTime, 6));
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: this.yesterday,
            end: this.nowTime,
            playerId: '',
            channel: 'All',

        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: this.state.begin,
            end: this.state.end,
            userId: this.state.playerId,
            type: this.state.channel,
        }
        NetUtil.Post(NetUtil.BaseUrl + "log/hb", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                this.setState({
                    LogsList: msg.data
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: this.yesterday,
                end: this.nowTime,
            })
            return;
        }
        let begindata = moment(date[0]).valueOf();
        let enddata = moment(date[1]).valueOf();

        this.setState({
            begin: begindata,
            end: enddata
        })
    }
    //设置玩家id
    private SetPlayerId(PlayerId: string) {
        this.setState({
            playerId: PlayerId
        })
    }
    //设置会长id
    private SetChannel(channel: string) {
        this.setState({
            channel: channel
        })
    }

    //导出excel
    private export() {
        if (this.state.LogsList == null || this.state.LogsList.length < 1) {
            message.error('请先查询数据或导数据为空！')
            return;
        }
        ExportExcel.ExportCsv(this.columns, this.state.LogsList, '兑换日志')

    }

    render() {

        this.columns = [
            { align: "center", title: "玩家ID", dataIndex: "playerId", key: "playerId" },
            {
                align: "center", title: "兑换时间", dataIndex: "date", key: "date", render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text, 'yyyy-MM-dd hh:mm:ss')}</div>
                }
            },
            { align: "center", title: " 订单号", dataIndex: "orderId", key: "orderId" },
            { align: "center", title: "支付宝账号", dataIndex: "account", key: "account" },

            { align: "center", title: "支付宝名字", dataIndex: "name", key: "name" },
            { align: "center", title: " 金额（元）", dataIndex: "num", key: "num" },

            { align: "center", title: "活动名", dataIndex: "coupon_name", key: "coupon_name" },
            {
                align: "center", title: "结果", dataIndex: "msg", key: "msg", render: (text, record) => {
                    return <div>{String(text).toLowerCase() == 'success' ? '成功' : '失败'}</div>
                }
            },
            { align: "center", title: "失败原因", dataIndex: "sub_msg", key: "sub_msg" },


        ]
        return (
            <div>
                <Form layout='inline'>

                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 7))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='类型'>

                        <Select
                            defaultValue={"All"}
                            onChange={(value: string) => { this.SetChannel(value) }}
                        >
                            <Option key={"All"} value={"All"}>{"所有"}</Option>
                            <Option key={"ali"} value={"ali"}>{"支付宝"}</Option>
                            <Option key={"wx"} value={"wx"}>{"微信"}</Option>
                            {/* {StorageValue.Channel.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item}</Option>
                                )
                            })} */}
                        </Select>
                    </Form.Item>

                    <Form.Item label='玩家ID'>
                        <Input
                            placeholder="请输入玩家ID..."
                            value={this.state.playerId}
                            onChange={(e) => this.SetPlayerId(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>


                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.export() }}
                        >
                            导出
                        </Button>
                    </Form.Item>


                </Form>
                <Divider />
                <Table
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
}