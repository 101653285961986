import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select } from 'antd';
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import NetUtil from '../../Utils/NetUtil';
import StorageValue from '../../Utils/StorageValue';
import ExportExcel from '../../Utils/ExcelExport';
import { InfoChangeReason, EnumInfoChangeReason } from '../../UserInfo/EnumManager';
const { RangePicker } = DatePicker;
const { Option } = Select;


interface IProps {

}

interface IState {
    LogsList: Array<any>
    begin: any
    end: any
    userId: string
    type: number
    reason: EnumInfoChangeReason
    curDate: string

}

/**
 **财产日志
 *Created by liangzai on 19/09/12.
 */
export default class PropetyLogView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private nowTime: string = TimeUtil.Format(new Date(), 'hh:mm:ss');
    private yesterday: string = TimeUtil.Format(new Date((new Date()).getTime() - 36000000), 'hh:mm:ss');
    private curDate: string = TimeUtil.Format(new Date(), 'YYYY-MM-dd');
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: this.yesterday,
            end: this.nowTime,
            userId: '',
            type: 10001,
            reason: EnumInfoChangeReason.None,
            curDate: this.curDate
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let postData = {
            token: StorageValue.Token,
            begin: TimeUtil.timeStringToNumber(this.state.curDate + ' ' + this.state.begin),
            end: TimeUtil.timeStringToNumber(this.state.curDate + ' ' + this.state.end),
            userId: this.state.userId,
            type: this.state.type,
            reson: this.state.reason
        }
        NetUtil.Post(NetUtil.BaseUrl + "log/property", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != "OK") {
                    message.error(Result);
                    return;
                }

                this.setState({
                    LogsList: msg.data
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(timestring: any) {

        if (timestring == null || timestring.length < 1) {
            this.setState({
                begin: this.yesterday,
                end: this.nowTime,
            })
            return;
        }
        let begindata = timestring[0];
        let enddata = timestring[1];

        this.setState({
            begin: begindata,
            end: enddata
        })
    }
    //设置日期
    private OnCurDateChange(date: any) {
        if (date == null || date == undefined) {
            this.setState({
                curDate: this.curDate
            })
            return
        }
        let begindata = TimeUtil.MomentToTimeString(date);
        this.setState({
            curDate: begindata,
        })
    }
    //设置玩家id
    private SetPlayerId(PlayerId: string) {
        this.setState({
            userId: PlayerId
        })
    }

    //设置变动原因
    private SetReason(reason: EnumInfoChangeReason) {
        this.setState({
            reason: reason
        })
    }

    //设置财产类型
    private SetType(type: number) {
        this.setState({
            type: type
        })
    }

    //导出excel
    private export() {
        if (this.state.LogsList == null || this.state.LogsList.length < 1) {
            message.error('请先查询数据或导数据为空！')
            return;
        }
        // ExportExcel.exportExcel([{ headers: this.columns, data: this.state.LogsList, sheetName: "财产日志" }], "财产日志")

        ExportExcel.ExportCsv(this.columns, this.state.LogsList, '兑换日志')

    }

    render() {

        this.columns = [
            { align: "center", title: "玩家ID", dataIndex: "userId", key: "userId" },
            { align: "center", title: "财产类型", dataIndex: "type", key: "type" },
            { align: "center", title: "变动前财产", dataIndex: "before", key: "before" },
            { align: "center", title: "变动后财产", dataIndex: "left", key: "left" },
            { align: "center", title: "变动金额", dataIndex: "change", key: "change" },
            {
                align: "center", title: "变动原因", dataIndex: "reson", key: "reson", render: (text, record) => {
                    let item = InfoChangeReason.find(item => item.key == text)
                    if (item) {
                        return <div>{item.value}</div>
                    }

                    return <div>{text}</div>
                }

            },

            {
                align: "center", title: "变动时间", dataIndex: "time", key: "time", render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text, 'yyyy-MM-dd hh:mm:ss')}</div>
                }
            },
        ]
        return (
            <div>
                <Form layout='inline'>

                    <Form.Item label='玩家ID'>
                        <Input
                            placeholder="请输入玩家ID..."
                            value={this.state.userId}
                            onChange={(e) => this.SetPlayerId(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label='日期选择' >
                        <DatePicker
                            defaultValue={moment(this.curDate)}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnCurDateChange(e) }}
                        />
                    </Form.Item>

                    <Form.Item label='时间选择' >
                        <RangePicker
                            picker={'time'}
                            defaultValue={[moment(this.yesterday, 'HH:mm:ss'), moment(this.nowTime, 'HH:mm:ss')]}
                            // value={null}
                            format='HH:mm:ss'
                            onChange={(values: any, formatString: any) => { this.OnTimeChange(formatString) }}
                        />
                    </Form.Item>

                    <Form.Item label='财产类型'>
                        <Select
                            value={this.state.type}
                            onChange={(value: number) => { this.SetType(value) }}
                        >
                            <Option key={10001} value={10001}>{"金币"}</Option>
                            {/* <Option key={10004} value={10004}>{'魔能'}</Option> */}
                            <Option key={10003} value={10003}>{'福豆'}</Option>


                        </Select>
                    </Form.Item>

                    <Form.Item label='移除原因'>
                        <Select
                            style={{ width: '180px' }}

                            value={this.state.reason}
                            onChange={(value: EnumInfoChangeReason) => { this.SetReason(value) }}
                        >
                            {InfoChangeReason.map((item: any) => {
                                return (
                                    <Option key={item.key} value={item.key}>{item.value}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>


                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.export() }}
                        >
                            导出
                        </Button>
                    </Form.Item>
                </Form>
                <Divider type='horizontal' />

                <Divider />
                <Table
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div >
        );
    }
}